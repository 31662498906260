import axios from "axios";
import { apiBaseUrl } from "../config";
import { getApiPrefix } from "./auth.service";

// Sample Data 
// const sortByColumns = [
//     {
//         title: 'Status',
//         key: 'status',
//         order:"asc"
//     },
//     {
//         title: 'Account Manager',
//         key: 'manager',
//         order:"desc"
//     },
//     {
//         title: 'Hours',
//         key: 'hours',
//         order:"asc"
//     }
// ]
export function fetchClients(page = 0, rowsPerPage = 20, sortByColumns = []) {
    const query = new URLSearchParams("")
    if (page) {
        query.set('page', page.toString());
    }
    if (rowsPerPage) {
        query.set('per_page', rowsPerPage.toString());
    }
    if (sortByColumns && sortByColumns.length > 0) {
        sortByColumns.forEach(element => {
            if (element.key && element.order)
                query.set(element.key, element.order);
        });
    }
    let url = [apiBaseUrl + "clients", query.toString()].join("/?")
    return axios.get(url, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function fetchClient(clientId) {
    return axios.get(apiBaseUrl + `clients/${clientId}`, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function fetchCompanyDashboard() {
    return axios.get(apiBaseUrl + `company/dashboard`, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function updateClient(clientId, values) {
    return axios.put(apiBaseUrl + `clients/${clientId}`, values, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}

export function createClient(values) {
    return axios.post(apiBaseUrl + 'clients', values, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}

export function clientAutocomplete(value) {
    return axios.get(apiBaseUrl + `clients/autocomplete/${value}`, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function companyAutocomplete(value) {
    return axios.get(apiBaseUrl + `clients/company-autocomplete/${value}`, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}

export function fetchClientHours(page = 0, rowsPerPage = 20, sortByColumns = [], role = 'super-admin', client_id = false) {
    const query = new URLSearchParams("")
    if (page) {
        query.set('page', page.toString());
    }
    if (rowsPerPage) {
        query.set('per_page', rowsPerPage.toString());
    }
    if (client_id) {
        query.set('cid', client_id.toString());
    }
    if (sortByColumns && sortByColumns.length > 0) {
        sortByColumns.forEach(element => {
            if (element.key && element.order)
                query.set(element.key, element.order);
        });
    }
    let url = [apiBaseUrl + getApiPrefix(role) + "tracked-hours", query.toString()].join("/?")
    return axios.get(url, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function fetchClientHoursDatewise(range, client_id = false, role = 'super-admin') {
    const query = new URLSearchParams("")
    if (range) {
        query.set('range', range.toString());
    }
    if (client_id) {
        query.set('client_id', client_id.toString());
    }
    let url = [apiBaseUrl + getApiPrefix(role) + "date-wise-tracked-hours", query.toString()].join("/?")
    return axios.get(url, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}

export function convertDateFormat(dateStr) {
    // Create a new Date object using the input string
    const date = new Date(dateStr);

    // Ensure the date is valid
    if (isNaN(date.getTime())) {
        return null;  // Return null or throw an error if the date is invalid
    }

    // Get the month, date, and year from the Date object
    const month = (date.getMonth() + 1).toString().padStart(2, '0');  // Months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString().substring(2);

    // Format the date string in MM/DD/YY format
    return `${month}/${day}/${year}`;
}