import React, { useEffect, useState } from 'react';
import { Select, Form, Spin } from 'antd';
import { companyAutocomplete } from '../services/client.service';

const { Option } = Select;

const CompanyPicker = ({ form, name, rules, label, initialOption }) => {
    const [options, setOptions] = useState(initialOption ? [initialOption] : []);
    const [fetching, setFetching] = useState(false);
    const [value, setValue] = useState(undefined);

    useEffect(() => {
        if (initialOption) {
            // Ensure that the initial option is set correctly
            setValue({ value: initialOption.id.toString(), label: initialOption.company_name });
            form.setFieldsValue({ [name || 'client_id']: initialOption.id.toString() });
        }
    }, [initialOption, form, name]);
    const handleSearch = (searchValue) => {
        if (searchValue.length >= 2) {
            setFetching(true);
            companyAutocomplete(searchValue).then((response) => {
                setOptions(response.data);
                setFetching(false);
            }).catch(() => {
                setFetching(false);
            });
        }
    };

    const handleChange = (newValue) => {
        setValue(newValue); // Update local state
        form.setFieldsValue({ [name || 'client_id']: newValue.value }); // Update form state with the ID
    };

    return (
        <Form.Item
            name={name || 'client_id'}
            label={label || "Company"}
            rules={rules}
        >
            <Select
                name={name || 'client_id'}
                showSearch
                placeholder={label}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                value={value}
                labelInValue
                optionLabelProp="label"
            >
                {options.map(option => (
                    <Option key={option.id} value={option.id.toString()} label={option.company_name}>
                        {option.company_name}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default CompanyPicker;
