// DefaultModal.js

import React from 'react';
import { Modal } from 'antd';
import './Modals.less';

const DefaultModal = ({ isVisible, toggleModal, title, icon, children, footerContent }) => {
  return (
    <Modal
      title={
        <div className="modal-title-container">
          <div className="modal-icon-container">
            <i className={`fi fi-rr-${icon} modal-icon`}></i>
          </div>
          {title}
        </div>
      }
      visible={isVisible}
      onCancel={toggleModal}
      footer={footerContent}
    >
      <div className="modal-body">
        {children}
      </div>
    </Modal>
  );
};

export default DefaultModal;
