import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  user: null,
  userDataLoading: false,
  token: window.localStorage.getItem('token'),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setAvatar: (state, action) => {
      state.user.avatar = action.payload;
    },
    setUserDataLoading: (state, action) => {
      state.userDataLoading = action.payload;
    }
  },
});

export const setToken = authSlice.actions.setToken;
export const setUserData = authSlice.actions.setUserData;
export const setUserDataLoading = authSlice.actions.setUserDataLoading;
export const setAvatar = authSlice.actions.setAvatar;

export default authSlice.reducer;
