// UpdateCreditCardModal.js

import React from 'react';
import { Form, Input, Checkbox, Button, message } from 'antd'; // Import message from antd
import DefaultModal from './DefaultModal';
import './Modals.less';

const UpdateCreditCardModal = ({ isVisible, toggleModal }) => {
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    console.log('Credit card details:', values);
    message.success('Your credit card has been updated successfully!'); // Use message here
    form.resetFields();
    toggleModal();
  };

  const footerContent = [
    <Button className="modal-cancel-button" key="back" onClick={toggleModal}>
      Cancel
    </Button>,
    <Button key="submit" className="modal-save-button" type="primary" onClick={() => form.submit()}>
      Update Card
    </Button>,
  ];

  return (
    <DefaultModal
      isVisible={isVisible}
      toggleModal={toggleModal}
      title="Update Credit Card"
      icon="credit-card"
      footerContent={footerContent}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit} className="main-modal-form add-credit-card-form">
        <Form.Item name="cardNumber" label="Card Number" rules={[{ required: true, message: 'Please enter the card number' }]}>
          <Input placeholder="Enter card number" />
        </Form.Item>
        <div className="form-row">
          <Form.Item name="expiry" label="Expiry" className="form-item-half" rules={[{ required: true, message: 'Please enter the expiry date' }]}>
            <Input placeholder="MM/YY" />
          </Form.Item>
          <Form.Item name="cvv" label="CVV" className="form-item-half" rules={[{ required: true, message: 'Please enter the CVV' }]}>
            <Input placeholder="Enter CVV" />
          </Form.Item>
        </div>
        <Form.Item name="cardholderName" label="Cardholder Name" rules={[{ required: true, message: 'Please enter the cardholder name' }]}>
          <Input placeholder="Enter cardholder name" />
        </Form.Item>
        <Form.Item name="makePrimary" valuePropName="checked">
          <Checkbox>Make Primary Card</Checkbox>
        </Form.Item>
      </Form>
    </DefaultModal>
  );
};

export default UpdateCreditCardModal;
