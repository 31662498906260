// AutomationStatusTags.js

import React from 'react';
import './Components.less';

const AutomationStatusTags = ({ status }) => {
  let className = 'at-status-tag';

  switch (status) {
    case 'Active':
      className += ' at-status-active';
      break;
    case 'Inactive':
      className += ' at-status-inactive';
      break;
    case 'Staging':
      className += ' at-status-staging';
      break;
    default:
      className += ' at-status-inactive'; // Default to 'Inactive' if the status is unknown
  }

  return (
    <div className={className}>
      {status || 'Inactive'}
    </div>
  );
};

export default AutomationStatusTags;
