// data/SampleClients.js

const clientsData = [
  {
    id: 1,
    clientName: 'John Doe',
    companyName: 'Tech Solutions',
    clientEmail: 'john.doe@techsolutions.com',
    phoneNumber: '555-123-4567',
    hoursPurchased: 50,
    hoursSpent: 30,
    currency: 'USD',
    clientStatus: 'Active',
    accountManager: 'Mayowa Ajakaiye',
    city: 'San Francisco',
    address: '1234 Market St',
    country: 'USA',
    notes: 'Meeting scheduled for next week.',
    website: 'techsolutions.com'
  },
  {
    id: 2,
    clientName: 'Jane Smith',
    companyName: 'Innovatech',
    clientEmail: 'jane.smith@innovatech.com',
    phoneNumber: '555-987-6543',
    hoursPurchased: 75,
    hoursSpent: 40,
    currency: 'CAD',
    clientStatus: 'Active',
    accountManager: 'Vitalii Zurov',
    city: 'Toronto',
    address: '5678 King St W',
    country: 'Canada',
    notes: '',
    website: 'innovatech.com'
  },
  {
    id: 3,
    clientName: 'Michael Johnson',
    companyName: 'NextGen IT',
    clientEmail: 'michael.johnson@nextgenit.com',
    phoneNumber: '555-654-3210',
    hoursPurchased: 60,
    hoursSpent: 90,
    currency: 'USD',
    clientStatus: 'Free Trial',
    accountManager: 'Forhad Uddin',
    city: 'New York',
    address: '9101 Broadway',
    country: 'USA',
    notes: '',
    website: 'nextgenit.com'
  },
  {
    id: 4,
    clientName: 'Emily Davis',
    companyName: 'CyberTech',
    clientEmail: 'emily.davis@cybertech.com',
    phoneNumber: '555-321-9876',
    hoursPurchased: 80,
    hoursSpent: 3,
    currency: 'CAD',
    clientStatus: 'Inactive',
    accountManager: 'Alex Rozario',
    city: 'Vancouver',
    address: '1213 Granville St',
    country: 'Canada',
    notes: 'Client is considering renewing contract.',
    website: 'cybertech.com'
  },
  {
    id: 5,
    clientName: 'Daniel Martinez',
    companyName: 'Secure Solutions',
    clientEmail: 'daniel.martinez@securesolutions.com',
    phoneNumber: '555-567-8901',
    hoursPurchased: 100,
    hoursSpent: 12,
    currency: 'USD',
    clientStatus: 'Active',
    accountManager: 'Noman Hossain',
    city: 'Los Angeles',
    address: '1415 Sunset Blvd',
    country: 'USA',
    notes: '',
    website: 'securesolutions.com'
  },
  {
    id: 6,
    clientName: 'Sophia Brown',
    companyName: 'Tech Innovators',
    clientEmail: 'sophia.brown@techinnovators.com',
    phoneNumber: '555-890-1234',
    hoursPurchased: 45,
    hoursSpent: 20,
    currency: 'CAD',
    clientStatus: 'Active',
    accountManager: 'Mayowa Ajakaiye',
    city: 'Montreal',
    address: '1617 Saint Catherine St W',
    country: 'Canada',
    notes: '',
    website: 'techinnovators.com'
  },
  {
    id: 7,
    clientName: 'David Wilson',
    companyName: 'AI Solutions',
    clientEmail: 'david.wilson@aisolutions.com',
    phoneNumber: '555-234-5678',
    hoursPurchased: 70,
    hoursSpent: 75,
    currency: 'USD',
    clientStatus: 'Inactive',
    accountManager: 'Vitalii Zurov',
    city: 'Chicago',
    address: '1819 Wacker Dr',
    country: 'USA',
    notes: 'Potential upgrade to premium service.',
    website: 'aisolutions.com'
  },
  {
    id: 8,
    clientName: 'Olivia Anderson',
    companyName: 'CloudTech',
    clientEmail: 'olivia.anderson@cloudtech.com',
    phoneNumber: '555-456-7890',
    hoursPurchased: 55,
    hoursSpent: 10,
    currency: 'CAD',
    clientStatus: 'Free Trial',
    accountManager: 'Forhad Uddin',
    city: 'Ottawa',
    address: '2021 Rideau St',
    country: 'Canada',
    notes: '',
    website: 'cloudtech.com'
  },
  {
    id: 9,
    clientName: 'James Thomas',
    companyName: 'DataDriven',
    clientEmail: 'james.thomas@datadriven.com',
    phoneNumber: '555-789-0123',
    hoursPurchased: 100,
    hoursSpent: 115,
    currency: 'USD',
    clientStatus: 'Active',
    accountManager: 'Alex Rozario',
    city: 'Austin',
    address: '2223 Congress Ave',
    country: 'USA',
    notes: '',
    website: 'datadriven.com'
  },
  {
    id: 10,
    clientName: 'Ava White',
    companyName: 'IT Solutions',
    clientEmail: 'ava.white@itsolutions.com',
    phoneNumber: '555-012-3456',
    hoursPurchased: 65,
    hoursSpent: 45,
    currency: 'CAD',
    clientStatus: 'Inactive',
    accountManager: 'Noman Hossain',
    city: 'Calgary',
    address: '2425 4th St SW',
    country: 'Canada',
    notes: '',
    website: 'itsolutions.com'
  },
  {
   id: 11,
   clientName: 'Charlotte Lee',
   companyName: 'TechWave',
   clientEmail: 'charlotte.lee@techwave.com',
   phoneNumber: '555-111-2233',
   hoursPurchased: 60,
   hoursSpent: 30,
   currency: 'USD',
   clientStatus: 'Active',
   accountManager: 'Mayowa Ajakaiye',
   city: 'Seattle',
   address: '1234 Pine St',
   country: 'USA',
   notes: 'Interested in additional services.',
   website: 'techwave.com'
 },
 {
   id: 12,
   clientName: 'Ethan Scott',
   companyName: 'AlphaTech',
   clientEmail: 'ethan.scott@alphatech.com',
   phoneNumber: '555-222-3344',
   hoursPurchased: 80,
   hoursSpent: 50,
   currency: 'CAD',
   clientStatus: 'Inactive',
   accountManager: 'Vitalii Zurov',
   city: 'Vancouver',
   address: '5678 Oak St',
   country: 'Canada',
   notes: '',
   website: 'alphatech.com'
 },
 {
   id: 13,
   clientName: 'Mia Nguyen',
   companyName: 'Innovative Solutions',
   clientEmail: 'mia.nguyen@innovativesolutions.com',
   phoneNumber: '555-333-4455',
   hoursPurchased: 75,
   hoursSpent: 35,
   currency: 'USD',
   clientStatus: 'Priority',
   accountManager: 'Forhad Uddin',
   city: 'Miami',
   address: '9101 Ocean Dr',
   country: 'USA',
   notes: 'Looking for a premium package.',
   website: 'innovativesolutions.com'
 },
 {
   id: 14,
   clientName: 'Liam Brown',
   companyName: 'DataSync',
   clientEmail: 'liam.brown@datasync.com',
   phoneNumber: '555-444-5566',
   hoursPurchased: 90,
   hoursSpent: 70,
   currency: 'CAD',
   clientStatus: 'Active',
   accountManager: 'Alex Rozario',
   city: 'Calgary',
   address: '1213 Bow Trail',
   country: 'Canada',
   notes: '',
   website: 'datasync.com'
 },
 {
   id: 15,
   clientName: 'Avery Wilson',
   companyName: 'NetSolutions',
   clientEmail: 'avery.wilson@netsolutions.com',
   phoneNumber: '555-555-6677',
   hoursPurchased: 100,
   hoursSpent: 80,
   currency: 'USD',
   clientStatus: 'Free Trial',
   accountManager: 'Noman Hossain',
   city: 'Denver',
   address: '1415 Colfax Ave',
   country: 'USA',
   notes: 'Trial ends next month.',
   website: 'netsolutions.com'
 },
 {
   id: 16,
   clientName: 'Sophie Miller',
   companyName: 'CyberWave',
   clientEmail: 'sophie.miller@cyberwave.com',
   phoneNumber: '555-666-7788',
   hoursPurchased: 55,
   hoursSpent: 25,
   currency: 'CAD',
   clientStatus: 'Inactive',
   accountManager: 'Mayowa Ajakaiye',
   city: 'Ottawa',
   address: '1617 Sussex Dr',
   country: 'Canada',
   notes: '',
   website: 'cyberwave.com'
 },
 {
   id: 17,
   clientName: 'James Martin',
   companyName: 'Quantum Tech',
   clientEmail: 'james.martin@quantumtech.com',
   phoneNumber: '555-777-8899',
   hoursPurchased: 70,
   hoursSpent: 60,
   currency: 'USD',
   clientStatus: 'Active',
   accountManager: 'Vitalii Zurov',
   city: 'San Diego',
   address: '1819 Harbor Dr',
   country: 'USA',
   notes: 'Happy with the service.',
   website: 'quantumtech.com'
 },
 {
   id: 18,
   clientName: 'Emma Johnson',
   companyName: 'CloudSync',
   clientEmail: 'emma.johnson@cloudsync.com',
   phoneNumber: '555-888-9900',
   hoursPurchased: 65,
   hoursSpent: 45,
   currency: 'CAD',
   clientStatus: 'Free Trial',
   accountManager: 'Forhad Uddin',
   city: 'Toronto',
   address: '2021 Queen St W',
   country: 'Canada',
   notes: '',
   website: 'cloudsync.com'
 },
 {
   id: 19,
   clientName: 'William Taylor',
   companyName: 'TechDynamics',
   clientEmail: 'william.taylor@techdynamics.com',
   phoneNumber: '555-999-1010',
   hoursPurchased: 85,
   hoursSpent: 50,
   currency: 'USD',
   clientStatus: 'Inactive',
   accountManager: 'Alex Rozario',
   city: 'Las Vegas',
   address: '2223 Flamingo Rd',
   country: 'USA',
   notes: 'Considering reactivation.',
   website: 'techdynamics.com'
 },
 {
   id: 20,
   clientName: 'Olivia Green',
   companyName: 'FutureTech',
   clientEmail: 'olivia.green@futuretech.com',
   phoneNumber: '555-010-1122',
   hoursPurchased: 95,
   hoursSpent: 65,
   currency: 'CAD',
   clientStatus: 'Priority',
   accountManager: 'Noman Hossain',
   city: 'Edmonton',
   address: '2425 Jasper Ave',
   country: 'Canada',
   notes: 'Needs urgent attention.',
   website: 'futuretech.com'
 }
];

export default clientsData;
