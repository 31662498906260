import { Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import RowOptions from '../../../Components/RowOptions';

const RowOptionColumn = {
  title: '',
  className: "table-option-cell",
  render: (text, record) => (
    <RowOptions>
      <Link to={'/projects/' + record.project_id}>
        <Menu.Item key="1">View Project</Menu.Item>
      </Link>
      <Menu.Item key="2">Settings</Menu.Item>
    </RowOptions>
  )
};

export default RowOptionColumn;
