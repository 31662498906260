import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { useProjectContext } from '../../../Context/ProjectContext'; // Adjust the path if necessary
import SingleAvatar2 from '../../../Components/SingleAvatar2'; // Adjust the path if necessary

const { Option } = Select;

const EditableAssigneeCell = ({
  value: initialValue,
  row,
  column
}) => {
  const [value, setValue] = useState(initialValue);
  const { project, update } = useProjectContext();
  const isSubtask = !!row.original.subtaskId

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (value) => {
    setValue(value);
    if (isSubtask) {
      update.subTask(row.original, column.id, value)
    } else {
      update.task(row.original, column.id, value)
    }
  };



  const options = project.members.map(member => (
    <Option key={member.user_id} value={member.user_id}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <SingleAvatar2 name={member.user.f_name + " " + member.user.l_name} />
        <span style={{ marginLeft: '8px' }}>{member.user.f_name + " " + member.user.l_name}</span>
      </div>
    </Option>
  ));

  return (
    <Select value={value} onChange={onChange} style={{ width: '100%' }} allowClear={true}>
      {options}
    </Select>
  );
};

const AssigneeColumn = {
  Header: 'Assignee',
  accessor: 'assignee',
  width: 100,
  Cell: EditableAssigneeCell,
  className: 'pmt-assignee-column',
};

export default AssigneeColumn;
