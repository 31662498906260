import axios from "axios";
import { apiBaseUrl } from "../config";

export function createCheckOutSession(dataToPost) {
    return axios.post(apiBaseUrl + "company/subscriptions/create-session", dataToPost, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function buySubscription(payment_intend_id){
    return axios.post(apiBaseUrl + "company/subscriptions/buy", { payment_intend_id }, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function saveCardAction(title, payment_method_id) {
    return axios.post(apiBaseUrl + "company/payment/save-card", { title, payment_method_id }, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}