
/*
conditions = [{
  column:"taskName",
  order:"asc"
}......]
*/
export const sortItemsAction = (items, conditions) => {
    if (conditions.length) {
        items.sort((a, b) => {
            for (let i = 0; i < conditions.length; i++) {
                let { column, order } = conditions[i]
                if (order === 'asc') {
                    if (a[column] > b[column]) return 1
                    if (a[column] < b[column]) return -1
                } else {
                    if (a[column] < b[column]) return 1
                    if (a[column] > b[column]) return -1
                }
            }
            return 0
        })
    }
};