import React, { useEffect, useState } from 'react';
import SingleAvatar2 from '../../Components/SingleAvatar2'; // Ensure the correct path to your avatar component
import './TeamOverview.less'; // Ensure to create and import the CSS file
import { EllipsisOutlined } from '@ant-design/icons'; // Import the Ellipsis icon from Ant Design
import TeamOverviewTable from './TeamOverviewTable'; // Import the TeamOverviewTable component
import { Pagination } from 'antd'; // Import Pagination from Ant Design
import TeamOverviewSort from './TeamOverviewSort'; // Import the TeamOverviewSort component
import { TeamMembersProvider, useTeamMembersContext } from '../../Context/TeamMembersProvider';
import SkeletonTable from '../../Components/SkeletonTable';
import { fetchTeamMembers, formatMember } from '../../services/member.service';
import TeamMemberFormModal from '../../Modals/TeamMemberFormModal';
import { useSelector } from 'react-redux';
import { hasRole } from '../../services/auth.service';
import { appSubDir } from '../../config';
import { Navigate } from 'react-router-dom';

const Loading = <div style={{ padding: '24px 0' }}>
  <div style={{ overflowX: 'auto' }}>
    <SkeletonTable rows={5} columns={5} />
  </div>
</div>
const MainComponent = () => {
  const user = useSelector((state) => state.auth.user);
  const isClient = hasRole(user, 'client')
  const {
    currentPage, setCurrentPage,
    teamMembers, setTeamMembers,
    loading, setLoading,
    totalRows, setTotalRows,
    sortByColumns,
    rowsPerPage, setRowsPerPage
  } = useTeamMembersContext()
  const [isModalVisible, setModalVisible] = useState(false);
  const [editingMember, setEditingMember] = useState(null);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
    if (isModalVisible) {
      setEditingMember(null); // Reset editing member when closing the modal
    }
  };

  const handleEditMember = (member) => {
    setEditingMember(member);
    toggleModal();
  };

  const handleSubmit = (updatedMember) => {
    if (editingMember) {
      formatMember(updatedMember)
      // Update the team member in the state
      setTeamMembers((prevMembers) =>
        prevMembers.map((member) =>
          member.id === updatedMember.id ? updatedMember : member
        )
      );
    } else {
      // Add the new member to the team members list
      setTeamMembers((prevMembers) => [...prevMembers, updatedMember]);
    }
  };

  // Page Data Loader
  function loadData() {
    setLoading(true);
    fetchTeamMembers(currentPage, rowsPerPage, sortByColumns).then((res) => {
      res.data.data.forEach(formatMember);
      setTeamMembers(res.data.data);
      setTotalRows(res.data.total);
    }).finally(() => {
      setLoading(false);
    });
  }
  // Fetch clients from the backend
  useEffect(() => {
    if (isClient) {
      return () => { }
    }
    const timer = setTimeout(() => {
      loadData()
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [currentPage, rowsPerPage, sortByColumns]);

  if (isClient) {
    return <Navigate to={appSubDir + "not-found"} />
  }


  return (
    <div className="to-main-container">
      <p className="main-page-title">
        <span className="main-page-title-icon">
          <i className="fi fi-rr-users-alt"></i>
        </span>
        Team Overview
      </p>

      {loading ? Loading : <>
        <TeamOverviewSort rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} /> {/* Add the sort component */}

        <div className="team-overview-container">
          {teamMembers.map((member) => (
            <div key={member.id} className="team-card">
              <div className="team-card-header">
                <div className="team-card-name-cell">
                  <SingleAvatar2 name={member.name} size={64} fontSize={24} />
                  <div className="team-card-info">
                    <span className="to-name">{member.name}</span>
                    <span className="to-job-title">{member.jobTitle}</span>
                  </div>
                </div>
                <div className="team-card-options-cell">
                  <EllipsisOutlined
                    className="team-card-settings"
                    onClick={() => handleEditMember(member)}
                  />
                </div>
              </div>
              <div className="team-card-data">
                <TeamOverviewTable member={member} /> {/* Render the table for each member */}
              </div>
            </div>
          ))}
        </div>
      </>}

      <Pagination
        current={currentPage}
        pageSize={rowsPerPage}
        total={totalRows}
        onChange={setCurrentPage}
        className="team-pagination"
      />
      <TeamMemberFormModal
        isVisible={isModalVisible}
        toggleModal={toggleModal}
        onSubmit={handleSubmit}
        memberData={editingMember}
      />
    </div>
  );
};

const TeamOverview = () => {
  return (
    <TeamMembersProvider>
      <MainComponent />
    </TeamMembersProvider>
  )
}
export default TeamOverview;
