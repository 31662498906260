// AutomationstableColumns/CreatedDateColumn.js

import React from 'react';

const CreatedDateColumn = {
  title: 'Date Created',
  key: 'createdDate',
  className: 'at-created-date',
  render: (text, record) => <span className="at-created-date-text">{new Date().toLocaleDateString()}</span>
};

export default CreatedDateColumn;
