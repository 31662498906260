// AutomationstableColumns/AutomationsTableSort.js

import React, { useState } from 'react';
import { Button } from 'antd';
import AutomationFormModal from '../../../Modals/AutomationFormModal'; // Import the new AutomationFormModal
import TableSorter from '../../../Components/TableSorter';
import { useAutomationsContext } from '../../../Context/AutomationsContext';
import { useSelector } from 'react-redux';
import { hasRole } from '../../../services/auth.service';


const columns = [
  {
    title: 'Status',
    key: 'status'
  },
  {
    title: 'Task Overview',
    key: 'tasks'
  },
  {
    title: 'Date',
    key: 'date'
  }
];
const options = [
  {
    value: 10,
    label: '10'
  },
  {
    value: 20,
    label: '20'
  },
  {
    value: 30,
    label: '30'
  },
  {
    value: 50,
    label: '50'
  },
  {
    value: 100,
    label: '100'
  }
];
const AutomationsTableSort = () => {
  const user = useSelector((state) => state.auth.user);
  const {
    sortByColumns, setSortByColumns,
    rowsPerPage, setRowsPerPage,
    automations, setAutomations,
  } = useAutomationsContext();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onSuccess = (newAutomation) => {
    newAutomation.steps = JSON.parse(newAutomation.steps)
    setAutomations([newAutomation, ...automations]);
  };
  const buttons = <>
    <Button className="button-1" onClick={showModal}>{hasRole(user, 'super-admin') ? "New Automation" : "Request Automation"}</Button>
    <AutomationFormModal
      isVisible={isModalVisible}
      setIsVisible={setIsModalVisible}
      onSuccess={onSuccess}
    />
  </>

  return (
    <TableSorter {...{
      columns,
      sortByColumns,
      setSortByColumns,
      options,
      rowsPerPage,
      setRowsPerPage,
      buttons
    }} />
  );
};

export default AutomationsTableSort;
