// EditCompanyDetailsModal.js

import React, { useState } from 'react';
import { Form, Input, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import DefaultModal from './DefaultModal';
import './Modals.less';
import { useCompanyProfileContext } from '../Context/CompanyProfileContext';
import { updateCompanyProfile } from '../services/company.service';

const EditCompanyDetailsModal = () => {
  const [form] = Form.useForm();
  const {
    company, setCompany,
    isModalOpen, setIsModalOpen
  } = useCompanyProfileContext();
  const [loading, setLoading] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleSubmit = (values) => {
    // console.log('Team member details:', values);
    setLoading(true); // Set loading to true when submission starts
    updateCompanyProfile(values).then((res) => {
      setCompany(res.data)
      const newFields = Object.keys(res.data).map(key => ({
        name: key,
        value: res.data[key]
      }));
      form.setFields(newFields);
      toggleModal();
      message.success('Company details saved successfully!');
    }).catch(error => {
      if (!error.response) {
        message.error('Network error or server is unreachable');
        return;
      }
      if (error.response.status === 422) {
        // Validation errors handling as before
        const validationErrors = error.response.data.errors;
        const errorFields = Object.keys(validationErrors).map(key => ({
          name: key,
          errors: validationErrors[key]
        }));
        form.setFields(errorFields);
      } else {
        // Non-validation errors
        message.error(error.response.data.message || 'An unexpected error occurred');
      }
    }).finally(() => {
      setLoading(false); // Set loading to false when submission ends
    })
  }

  const footerContent = [
    <Button className="modal-cancel-button" key="back" onClick={toggleModal} loading={loading}>
      Close
    </Button>,
    <Button key="submit" className="modal-save-button" type="primary" loading={loading} onClick={() => form.submit()}>
      Save Changes
    </Button>,
  ];

  return (
    <DefaultModal
      isVisible={isModalOpen}
      toggleModal={toggleModal}
      title="Edit Company Details"
      icon="file-circle-info"
      footerContent={footerContent}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit} className="main-modal-form edit-company-details-form">
        <Form.Item name="company_name" label="Company Name" initialValue={company.company_name}
              rules={[{ required: true, message: 'Enter Company Name' }]}>
          <Input placeholder="Enter company name" />
        </Form.Item>
        <Form.Item name="tagline" label="Tagline" initialValue={company.tagline}>
          <Input placeholder="Enter tagline" />
        </Form.Item>
        <Form.Item name="overview" label="Company Overview" initialValue={company.overview}>
          <Input.TextArea placeholder="Enter company overview" />
        </Form.Item>
        <div className="form-row">
          <Form.Item name="phone" label="Phone Number" className="form-item-half" initialValue={company.phone}>
            <Input placeholder="Enter phone number" />
          </Form.Item>
          <Form.Item name="email" label="Email" className="form-item-half" initialValue={company.email}>
            <Input placeholder="Enter email" />
          </Form.Item>
        </div>
        <Form.Item name="address" label="Address" initialValue={company.address}>
          <Input placeholder="Enter Address" />
        </Form.Item>
        <div className="form-row">
          <Form.Item name="city" label="City" initialValue={company.city} className="form-item-half">
            <Input placeholder="Enter City" />
          </Form.Item>
          <Form.Item name="country" label="Country" initialValue={company.country} className="form-item-half">
            <Input placeholder="Enter Country" />
          </Form.Item>
        </div>
        <div className="form-row">
          <Form.Item name="website" label="Website" initialValue={company.website} className="form-item-half">
            <Input placeholder="Enter Website" />
          </Form.Item>
          <Form.Item name="team_size" type="number" label="Team Size" initialValue={company.team_size} className="form-item-half">
            <Input placeholder="Enter Team Size" />
          </Form.Item>
        </div>
      </Form>
    </DefaultModal>
  );
};

export default EditCompanyDetailsModal;
