import React, { useEffect, useState } from 'react';
import { Select, Form, Spin } from 'antd';
import { clientAutocomplete } from '../services/client.service';

const { Option } = Select;


const ClientPicker = ({ value, label, onChange, error, helperText }) => {
    const [options, setOptions] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        setSelected(value || null);
        setOptions(value ? [value] : [])
    }, [value]);

    const handleSearch = (searchValue) => {
        if (searchValue.length >= 2) {
            setFetching(true);
            clientAutocomplete(searchValue).then((response) => {
                setOptions(response.data);
                setFetching(false);
            }).catch(() => {
                setFetching(false);
            });
        }
    };

    const handleChange = (newValue) => {
        const newSelected = options.find(option => option.id.toString() === newValue) || null;
        setSelected(newSelected);
        onChange(newSelected);
    };

    return (
        <Form.Item label={label} validateStatus={error ? 'error' : ''} help={helperText}>
            <Select
                showSearch
                value={selected ? selected.id.toString() : undefined}
                placeholder={label}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={handleSearch}
                onChange={handleChange}
                optionLabelProp="label"
            >
                {options.map(option => (
                    <Option key={option.id} value={option.id.toString()} label={option.company_name}>
                        {option.f_name} {option.l_name}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default ClientPicker;
