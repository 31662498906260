// File Path : src\Context\ProjectContext.js

import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { clientsData as initialClientsData } from '../Data/ProjectsSampleData';
import { addSubtask, updateSubtask, deleteSubtask, addNewSubtask, addSubTaskAction, groupTasksAction, sortTaskAction, addTaskAction, foccusTaskRow, getGroupIndexFromSubtask, updateTaskAction, glowEffect, beforeSetTasks, focusRowInput, foccusTaskInput, addSectionAction, foccusSubTaskInput } from '../Utils/taskActions';
import { addClient, removeClient, updateClient } from '../Utils/clientActions';
import { moveColumn } from '../Utils/dndActions';
import { addTask } from '../services/project-task.service';

const ProjectContext = createContext();
export const ProjectProvider = ({ children }) => {
  const [clientsData, setClientsData] = useState(initialClientsData);
  const [tasks, setTasks] = useState([]);
  const [project, setProject] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [columnOrder, setColumnOrder] = useState(['taskName', 'assignee', 'status', 'dueDate', 'timeSpent']);
  const [groupByColumns, setGroupByColumns] = useState(['section']);
  const [sortByColumns, setSortByColumns] = useState([]);
  const [taskGroups, setTaskGroups] = useState([]);
  const [dragData, setDragData] = useState({ from: -1, to: -1 }); // from & to is a taskId
  const [dragColumn, setDragColumn] = useState({ from: -1, to: -1 }); // from & to is a taskId
  useEffect(() => {
    const timer = setTimeout(() => {
      if (groupByColumns.length > 0) {
        const newTaskGroups = groupTasksAction(tasks, groupByColumns)
        newTaskGroups.forEach((item) => {
          sortTaskAction(item.tasks, sortByColumns)
        })
        setTaskGroups([...newTaskGroups])
      } else {
        setTaskGroups([])
      }
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [tasks, sortByColumns, groupByColumns])

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleMoveColumn = (sourceIndex, destinationIndex) => {
    setColumnOrder(prevOrder => moveColumn(prevOrder, sourceIndex, destinationIndex));
  };
  return (
    <ProjectContext.Provider
      value={{
        clientsData,
        tasks, setTasks,
        project, setProject,
        isModalVisible,
        groupByColumns, setGroupByColumns,
        sortByColumns, setSortByColumns,
        taskGroups, setTaskGroups,
        dragData, setDragData,
        dragColumn, setDragColumn,
        isTableLoading, setIsTableLoading,
        toggleModal,
        addtask: (groupIndex) => {
          // console.log('addtask context called', groupIndex);

          const newTask = addTaskAction(project.id, tasks);
          const group = groupIndex !== undefined ? taskGroups[groupIndex] : null
          const tableTasks = group ? group.tasks : tasks
          groupByColumns.forEach((item) => {
            newTask[item] = tableTasks[tableTasks.length - 1][item]
          })
          // console.log("newTask :", { newTask })
          addTask(project.id, {
            task_id: newTask.taskId,
            sub_task_id: newTask.subtaskId,
            status: newTask.status,
            section: newTask.section,
            due_date: newTask.dueDate,
            assignee_id: newTask.assignee === "" ? null : newTask.assignee
          })
          beforeSetTasks(tasks)
          setTasks([...tasks])
          foccusTaskRow(newTask.taskId)
        },
        deleteSubtask: (taskId, subtaskId) => setTasks(prevTasks => {
          console.log('deleteSubtask context called with:', taskId, subtaskId);
          return deleteSubtask(prevTasks, taskId, subtaskId);
        }),
        addNewSubtask: (taskId) => setTasks(prevTasks => {
          console.log('addNewSubtask context called with:', taskId);
          return addNewSubtask(prevTasks, taskId);
        }),
        addSubtaskBelow: (taskIndex, subtaskIndex) => setTasks(prevTasks => {
          console.log('addSubtaskBelow context called with:', { taskIndex, subtaskIndex });
          return addSubTaskAction(prevTasks, taskIndex, subtaskIndex);
        }),
        add: {
          task: (groupIndex, taskIndex = false) => {
            const newTask = addTaskAction(project.id, tasks, taskGroups, groupIndex, groupByColumns, taskIndex)
            sortTaskAction(tasks, sortByColumns)
            beforeSetTasks(tasks)
            setTasks([...tasks])

            foccusTaskInput(newTask.taskId)
            return newTask
          },
          subTask: (taskIndex, subTaskIndex = false) => {
            const newSubTask = addSubTaskAction(project.id, tasks, groupByColumns, taskIndex, subTaskIndex)
            sortTaskAction(tasks, sortByColumns)
            beforeSetTasks(tasks)
            setTasks([...tasks])

            foccusSubTaskInput(tasks[taskIndex].taskId, newSubTask.subTaskId)
            return newSubTask
          },
          section: (section) => {
            const newTask = addSectionAction(project.id, tasks, section)
            beforeSetTasks(tasks)
            setTasks([...tasks])
            foccusTaskInput(newTask.taskId, 50)
          }
        },
        update: {
          task: (taskData, newTaskKey, newTaskValue, foucsInput = false) => {
            const { groupIndex, groupTaskIndex, taskIndex } = taskData
            if (groupIndex !== undefined) {    // If Table is a group Table
              const group = taskGroups[groupIndex]
              const task = group.tasks[groupTaskIndex]
              if (task[newTaskKey] === newTaskValue) return
              updateTaskAction(tasks, taskIndex, { [newTaskKey]: newTaskValue }, sortByColumns);
              setTasks([...tasks])

              glowEffect(`.pmt-group-table:nth-child(${groupIndex + 2}) tr.pmt-row:nth-child(${groupTaskIndex + 1})`, foucsInput)
            } else {
              const task = tasks[taskIndex]
              if (task[newTaskKey] === newTaskValue) return
              updateTaskAction(tasks, taskIndex, { [newTaskKey]: newTaskValue }, sortByColumns);
              setTasks([...tasks])
              glowEffect(`tr.pmt-row:nth-child(${taskIndex + 1})`, foucsInput)
            }
          },
          subTask: (subTaskData, newTaskKey, newTaskValue) => {
            const { groupIndex, groupTaskIndex, groupSubTaskIndex, taskIndex, subTaskIndex } = subTaskData
            // console.log("Updating Sub Task", { groupIndex, groupTaskIndex, groupSubTaskIndex, taskIndex, subTaskIndex, newTaskKey, newTaskValue })

            const subTask = tasks[taskIndex].subtasks[subTaskIndex]
            if (subTask[newTaskKey] === newTaskValue) return
            setTasks(prevTasks => {
              return updateSubtask(prevTasks, taskIndex, subTaskIndex, { [newTaskKey]: newTaskValue });
            })
          },
          fromLocal: {
            sortByColumns: (projectId) => {
              // console.log('updateSortByColumns context called with:', newData);
              const local = window.localStorage.getItem('sort-by-columns-' + projectId)
              if (local) {
                setSortByColumns(JSON.parse(local))
              }
            },
            groupByColumns: (projectId) => {
              // console.log('updateSortByColumns context called with:', newData);
              const local = window.localStorage.getItem('group-by-columns-' + projectId)
              if (local) {
                setGroupByColumns(JSON.parse(local))
              }
            }
          },
          sortByColumns: (newData) => {
            // console.log('updateSortByColumns context called with:', newData);
            window.localStorage.setItem('sort-by-columns-' + project.id, JSON.stringify(newData))
            setSortByColumns([...newData]);
          },
          groupByColumns: (newData) => {
            // console.log('updateSortByColumns context called with:', newData);
            window.localStorage.setItem('group-by-columns-' + project.id, JSON.stringify(newData))
            setGroupByColumns([...newData]);
          }
        },
        addTeamMember: (newMember) => { },
        removeTeamMember: (memberId) => { },
        updateTeamMember: (memberId, updatedMember) => { },
        addClient: (newClient) => setClientsData(prevClients => addClient(prevClients, newClient)),
        removeClient: (clientId) => setClientsData(prevClients => removeClient(prevClients, clientId)),
        updateClient: (clientId, updatedClient) => setClientsData(prevClients => updateClient(prevClients, clientId, updatedClient)),
        columnOrder, setColumnOrder,
        moveColumn: handleMoveColumn
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
export const useProjectContext = () => {
  return useContext(ProjectContext)
}
