import React, { useEffect, useState } from 'react';
import { Col } from 'antd';
import './AuthenticationPages.less';
import { appSubDir } from '../../config';

const imagesAndQuotes = [
  {
    imgSrc: appSubDir + 'assets/images/image-10.webp',
    quote: "The average task should be 3-5 hours and the average sub-task 30 mins to 2 hours.",
  },
  {
    imgSrc: appSubDir + 'assets/images/image-11.webp',

    quote: "Doing the important things first? Or the easy things first? Anything is better than doing nothing!",
  },
  {
    imgSrc: appSubDir + 'assets/images/image-12.webp',
    quote: "Delegate effectively. Remember, it's not just about offloading work, it's about empowering your team.",
  },
  {
    imgSrc: appSubDir + 'assets/images/image-13.webp',
    quote: "A well-defined project scope saves time and reduces stress.",
  },


];

const AuthenticationSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * imagesAndQuotes.length);
    setCurrentSlide(randomIndex);
  }, []);

  return (
    <Col className="authentication-image-column" xs={0} md={9}>
      <div className="authentication-image-background">
        <div className="authentication-image-container">
          <img
            src={imagesAndQuotes[currentSlide].imgSrc}
            alt="Authentication Illustration"
            className="authentication-image"
          />
        </div>
        <div className="authentication-register-right-text">
          {imagesAndQuotes[currentSlide].quote}
        </div>
      </div>
    </Col>
  );
};

export default AuthenticationSlider;
