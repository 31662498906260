import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import EditCompanyDetailsModal from '../../Modals/EditCompanyDetailsModal';
import { useCompanyProfileContext } from '../../Context/CompanyProfileContext';
import { message, Skeleton } from 'antd';
import { getLogoUrl, uploadLogo } from '../../services/company.service';
import { appSubDir } from '../../config';

const CompanyDetails = () => {
  const user = useSelector((state) => state.auth.user);
  const isClient = user && user.roles.length > 0 && user.roles[0].name === "client"
  const isUploading = useRef(false)
  const {
    company, setCompany,
    isModalOpen, setIsModalOpen,
    loading
  } = useCompanyProfileContext();
  if (!isClient) {
    return null
  }
  const handleCompanyLogoChange = (event) => {
    if (isUploading.current) {
      return
    }
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData()
      formData.append('logo', file)
      isUploading.current = true
      uploadLogo(formData).then((res) => {
        company.logo = res.data
        setCompany({ ...company })
      }).catch(error => {
        if (!error.response) {
          message.error('Network error or server is unreachable');
          return;
        }
        if (error.response.status === 422) {
          // Validation errors handling as before
          const validationErrors = error.response.data.errors.avatar;
          validationErrors.forEach(element => {
            message.error(element)
          })
        } else {
          // Non-validation errors
          message.error(error.response.data.message || 'An unexpected error occurred');
        }
      }).finally(() => {
        isUploading.current = false
      })
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  function getData(label, accessor) {
    if (loading) {
      return <Skeleton active paragraph={false} />
    }
    if (accessor === 'address') {
      return <span>
        {label} : {company[accessor]}, {company.city}, {company.country}
      </span>
    }
    return <span>
      {label} : {company[accessor]}
    </span>
  }
  return <>
    <div className="ap-combined-details-title-row">
      <div className="ap-combined-details-title">Company Details</div>
      {loading ? null : <div className="ap-combined-details-button" onClick={toggleModal}><i class="fi fi-rr-pencil"></i> Edit</div>}
    </div>
    <EditCompanyDetailsModal />
    <div className="ap-company-details-container">
      <div className="ap-company-details">
        <div className="ap-company-content">
          <div className="ap-company-logo-container">
            <div className="ap-company-logo">
              <img src={company.logo ? getLogoUrl(company.logo) : appSubDir + "assets/images/150.webp"} alt="Company Logo" />
              <div>
                <input type="file" onChange={handleCompanyLogoChange} style={{ display: 'none' }} id="upload-logo" />
                <label htmlFor="upload-logo" className="ap-upload-logo-button">
                  <i className="fi fi-rr-upload"></i> Upload Logo
                </label>
              </div>
            </div>
          </div>
          <div className="ap-company-info">
            <div className="ap-info-column">
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-briefcase"></i>
                </div>
                {getData('Company Name', 'company_name')}
              </div>
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-bulb"></i>
                </div>
                {getData('Tagline', 'tagline')}
              </div>
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-comment-alt"></i>
                </div>
                {getData('Company Overview', 'overview')}
              </div>
            </div>
            <div className="ap-info-column">
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-phone-call"></i>
                </div>
                {getData('Phone Number', 'phone')}
              </div>
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-envelope"></i>
                </div>
                {getData('Email', 'email')}
              </div>
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-users"></i>
                </div>
                {getData('Team Size', 'team_size')}
              </div>
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-marker"></i>
                </div>
                {getData('Address', 'address')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
};

export default CompanyDetails;
