// ChartFilter.js
import React, { useState } from 'react';
import { Button } from 'antd';

const ChartFilter = ({ selectedValues, setSelectedValues }) => {
  const handleFilterChange = (value) => {
    if (value === 'all') {
      setSelectedValues(['tasks', 'activeClients', 'projects', 'hoursAllocated', 'hoursWorked']);
    } else {
      const newSelectedValues = selectedValues.includes(value)
        ? selectedValues.filter((val) => val !== value)
        : [...selectedValues, value];
      setSelectedValues(newSelectedValues);
    }
  };

  const isActive = (value) => selectedValues.includes(value);

  return (
    <div className="db-chart-filter-buttons">
      <div className="db-chart-eye-icon-container">
        <i className="fi fi-rr-eye"></i>
      </div>
      <Button
        className={selectedValues.length === 4 ? 'db-chart-filter-active-button' : 'db-chart-filter-inactive-button'}
        onClick={() => handleFilterChange('all')}
      >
        <span className="db-chart-filter-circle db-chart-filter-circle-all"></span>
        All
      </Button>
      <Button
        className={isActive('tasks') ? 'db-chart-filter-active-button' : 'db-chart-filter-inactive-button'}
        onClick={() => handleFilterChange('tasks')}
      >
        <span className="db-chart-filter-circle db-chart-filter-circle-tasks"></span>
        Tasks
      </Button>
      <Button
        className={isActive('activeClients') ? 'db-chart-filter-active-button' : 'db-chart-filter-inactive-button'}
        onClick={() => handleFilterChange('activeClients')}
      >
        <span className="db-chart-filter-circle db-chart-filter-circle-activeClients"></span>
        Active Clients
      </Button>
      <Button
        className={isActive('projects') ? 'db-chart-filter-active-button' : 'db-chart-filter-inactive-button'}
        onClick={() => handleFilterChange('projects')}
      >
        <span className="db-chart-filter-circle db-chart-filter-circle-projects"></span>
        Projects
      </Button>
      <Button
        className={isActive('hoursAllocated') ? 'db-chart-filter-active-button' : 'db-chart-filter-inactive-button'}
        onClick={() => handleFilterChange('hoursAllocated')}
      >
        <span className="db-chart-filter-circle db-chart-filter-circle-hoursAllocated"></span>
        Hours Allocated
      </Button>
      <Button
        className={isActive('hoursWorked') ? 'db-chart-filter-active-button' : 'db-chart-filter-inactive-button'}
        onClick={() => handleFilterChange('hoursWorked')}
      >
        <span className="db-chart-filter-circle db-chart-filter-circle-hoursWorked"></span>
        Hours Worked
      </Button>
    </div>
  );
};

export default ChartFilter;
