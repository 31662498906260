import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UnifiedLayout from '../Components/UnifiedLayout';

import Automations from '../Pages/AutomationsPages/Automations';
import Base from '../Pages/Base';
import Clients from '../Pages/ClientsPages/Clients';
import ClientMainPage from '../Pages/ClientsPages/ClientMainPage';
import Jobs from '../Pages/HRPages/Jobs';
import ClientChat from '../Pages/ClientsPages/ClientChat';
import Tasks from '../Pages/WorkPages/Tasks';
import IndividualProject from '../Pages/WorkPages/IndividualProject';

import Projects from '../Pages/Projects/Projects';
import Dashboard from '../Pages/DashboardPage/Dashboard';
import TeamOverview from '../Pages/TeamOverviewPage/TeamOverview';
import AccountPage from '../Pages/AccountPages/AccountPage';
import Notifications from '../Pages/NotificationsPage/Notifications';
import HoursTracker from '../Pages/HoursTrackerPage/HoursTracker';
import NotFoundPage from '../Pages/MiscPages/NotFoundPage';
import AccountHoursTopUp from '../Pages/AccountPages/AccountHoursTopUp';
import Subsctiptions from '../Pages/SubscriptionPages/Subscriptions';

const PrivateRoutes = () => {
  return (
    <Router>
      <UnifiedLayout>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/automations" element={<Automations />} />
          <Route path="/billing/subscriptions" element={<Subsctiptions />} />
          <Route path="/base" element={<Base />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/clients/:id/profile" element={<ClientMainPage />} />
          <Route path="/clients/:client_id/projects" element={<Projects />} />
          <Route path="/clients/:client_id/automations" element={<Automations />} />
          <Route path="/clients/:id/hours-tracker" element={<HoursTracker />} />
          <Route path="/work/hours-tracker" element={<HoursTracker />} />

          <Route path="/clients/clients-chat" element={<ClientChat />} />
          <Route path="/hr/jobs" element={<Jobs />} />
          <Route path="/work/tasks" element={<Tasks />} />

          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:id" element={<IndividualProject />} />
          <Route path="/projects/:id/:tabName" element={<IndividualProject />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/team-overview" element={<TeamOverview />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/account/hour-topup" element={<AccountHoursTopUp />} />



          <Route path="*" element={<NotFoundPage />} />

        </Routes>
      </UnifiedLayout>
    </Router>
  );
};

export default PrivateRoutes;
