import React from 'react';
import { Breadcrumb } from 'antd';
import { NavLink } from 'react-router-dom';
import '../custom.less'; 

const BreadcrumbComponent = ({ items }) => {
  const linkStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0 4px',
    borderRadius: '4px',
    transition: 'background-color 0.1s',
    textDecoration: 'none',
  };

  const hoverStyle = {
    backgroundColor: 'var(--purple-200)',
  };

  return (
    <Breadcrumb style={{ marginBottom: '24px' }}>
      {items.map((item, index) => (
        <Breadcrumb.Item key={index}>
          {index === 0 ? (
            <NavLink
              to={item.link}
              className="breadcrumb-link"
              style={linkStyle}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'var(--purple-200)'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
            >
              <i className={`fi ${item.icon}`} style={{ marginRight: '0px', transform: 'translateY(1px)' }}></i>
            </NavLink>
          ) : index === items.length - 1 ? (
            <span
              className="breadcrumb-link"
              style={{ ...linkStyle, cursor: 'default', color: 'var(--slate-500)', backgroundColor: 'transparent' }}
            >
              {item.title}
            </span>
          ) : (
            <NavLink
              to={item.link}
              className="breadcrumb-link"
              style={linkStyle}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'var(--purple-200)'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = ''}
            >
              {item.title}
            </NavLink>
          )}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbComponent;
