import React, { useState } from 'react';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import AutomationFormModal from '../../../Modals/AutomationFormModal';
import { useAutomationsContext } from '../../../Context/AutomationsContext';

const RowOptions = ({ row, index }) => {
  const [visible, setVisible] = useState(false);
  const [formModalVisible, setFormModalVisible] = useState(false);
  const {
    automations, setAutomations
  } = useAutomationsContext();

  const handleMenuClick = () => {
    setVisible(false);
  };
  const onSuccess = (newAutomation) => {
    newAutomation.steps = JSON.parse(newAutomation.steps)
    automations[index] = { ...automations[index], ...newAutomation }
    setAutomations([...automations]);
  };

  const showModal = () => {
    setFormModalVisible(true);
  };
  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" onClick={showModal}>Update</Menu.Item>
      <Menu.Item key="2">Settings</Menu.Item>
    </Menu>
  );

  return (
    <div className="row-options-container">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        onVisibleChange={handleVisibleChange}
        visible={visible}
      >
        <div className="row-options">
          <i className="fi fi-br-menu-dots-vertical"></i>
        </div>
      </Dropdown>
      <AutomationFormModal
        isVisible={formModalVisible}
        setIsVisible={setFormModalVisible}
        onSuccess={onSuccess}
        initialValues={automations[index]}
      />
    </div>
  );
};

export default RowOptions;
