import React, { useState } from 'react';
import { Button, Row, Col, Select, message, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import './FilterSortByOverlay.less';
import { useProjectContext } from '../../Context/ProjectContext';
import { groupByOptions } from './TableColumns/TableColumn';

const { Option } = Select;
const FilterGroupByOverlay = () => {
  const { groupByColumns, update } = useProjectContext();
  const [column, setColumn] = useState('')
  const options = groupByOptions.filter((item) => !groupByColumns.includes(item.key))
  function onAddColumn() {
    if (column) {
      groupByColumns.push(column)
      update.groupByColumns([...groupByColumns])
      setColumn('')
    } else {
      message.error('Please select a column ')
    }
  }
  function onRemoveColumn(index) {
    groupByColumns.splice(index, 1)
    update.groupByColumns([...groupByColumns])
  }
  return <div className='task-sort-dropdown-overlay'>
    {groupByColumns.map((columnKey, index) => (<Row key={index} gutter={16}>
      <Col>
        <Input className='input' readOnly value={groupByOptions.find((item) => item.key === columnKey)?.label} />
      </Col>
      <Col>
        <Button type="dashed" onClick={() => onRemoveColumn(index)} className='input remove' icon={<DeleteOutlined />}>Remove</Button>
      </Col>
    </Row>))}
    {(groupByColumns.length < groupByOptions.length) && <Row gutter={16}>
      <Col>
        <Select onChange={setColumn} value={column} className='input'>
          {options.map((item, index) => (<Option key={index} value={item.key}>{item.label}</Option>))}
        </Select>
      </Col>
      <Col>
        <Button type="primary" className='input' onClick={onAddColumn}>Add Column</Button>
      </Col>
    </Row>}
  </div>
}

export default FilterGroupByOverlay;
