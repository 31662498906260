import React from 'react';
import DashboardChart from './DashboardChart';
import DashboardWelcome from '../DashboardWelcome';
import DailyOverview from './DailyOverview';
import DashboardProjects from '../DashboardProjects';

const TeamDashboard = () => {

  return (
    <div className="db-main-container">
      <div className="db-title-container">
        <p className="main-page-title">
          <span className="main-page-title-icon">
            <i class="fi fi-rr-objects-column"></i>

          </span>
          Dashboard
        </p>
        <DashboardWelcome />
      </div>

      <div className="db-main-banner-container">
        <div className="db-chart-container">
          <DashboardChart />
        </div>

        <div className="db-daily-overview">
          <DailyOverview />
        </div>
      </div>

      <div className="db-tasks-widget">
        <DashboardProjects />
      </div>


    </div>

  );
};

export default TeamDashboard;
