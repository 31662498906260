// XAxisFormatter.js
import moment from 'moment';

const formatXAxis = (date) => moment(date).format('MMM DD');

const getTickValues = (data, range) => {
  const tickValues = [];
  let totalTicks;

  switch (range) {
    case 7:
      totalTicks = 7; // 7 ticks total
      break;
    case 30:
      totalTicks = 30; // 30 ticks total
      break;
    case 60:
      totalTicks = 8; // 8 ticks total
      break;
    case 90:
      totalTicks = 12; // 12 ticks total
      break;
    case 180:
      totalTicks = 24; // 24 ticks total
      break;
    case 365:
      totalTicks = 12; // 12 ticks total
      break;
    default:
      totalTicks = data.length; // default to all data points
      break;
  }

  const interval = Math.ceil(data.length / totalTicks);

  for (let i = 0; i < data.length; i += interval) {
    tickValues.push(data[i].date);
  }

  return tickValues;
};

export { formatXAxis, getTickValues };
