const messages = [
  {
    id: 1,
    avatar: 'M',
    name: 'Mayowa Ajakaiye (All-in-One Workforce)',
    date: 'Jun 5 (4 days)',
    content: 'Hi Josiah, I appreciate the clarification. Will 2:30 CT work for you? We are available to meet at 2:30 pm CT.',
  },
  {
    id: 2,
    avatar: 'J',
    name: 'Josiah',
    date: 'Jun 5 (4 days)',
    content: 'Yes, 2:30pm CTD',
  },
  {
    id: 3,
    avatar: 'M',
    name: 'Mayowa Ajakaiye (All-in-One Workforce)',
    date: 'Jun 5 (4 days)',
    content: 'Hello Josiah, I have sent an email invite to all parties. Kindly accept the invite to confirm you will be in the meeting. Best regards,',
  },
  {
    id: 4,
    avatar: 'J',
    name: 'Josiah',
    date: 'Jun 5 (4 days)',
    content: 'Done',
  },
  {
    id: 5,
    avatar: 'D',
    name: 'Douglas',
    date: 'Jun 6 (3 days)',
    tags: ['@mayowa', '@garrett'],
    content: 'We would like to get an internal lead scrape for our company. We are going to begin ghostwriting for clients and would like to get a list for the following criteria; Target Audience: CEOs and founders of tech companies (like AI, blockchain, crypto, fintech, etc) Preferably startups that just received investor funding. If possible, targeting CEOs and founders who don’t have Elon Musk fame, which will make thought leadership even more attractive for them. Need their email to send cold outreach. Let me know if you need more specifics.',
  },
  {
    id: 6,
    avatar: 'M',
    name: 'Mayowa Ajakaiye (All-in-One Workforce)',
    date: 'Jun 6 (3 days)',
    content: 'Hello Josiah, Please find below the revised Catalyst Business Solutions Proposal. Best regards,',
    file: 'Catalyst Business Solutions Proposal New.pdf',
  },
  {
    id: 7,
    avatar: 'M',
    name: 'Mayowa Ajakaiye (All-in-One Workforce)',
    date: 'Jun 6 (3 days)',
    content: 'Hello Josiah, We have gone through all the documents you shared with us and we still need the following information and fields to get started. Kindly help us get this information as soon as you can. For Contractors: We need the fields for contractors and properties. We need Packages (pricing plans) e.g. price information. We need your confirmation to proceed with the Free (Not featured) and Paid (Featured Business) fields. List of Services. Briefcase: We need to confirm if you want an upload field or a text field. For Investors: We need Packages e.g. price information. Fields for Properties. Additionally, find below the possible fields in the backend. They are divided into Preset and Custom fields. Best regards,',
  },
];

export default messages;
