import React, { useState, useEffect } from 'react';
import { Layout, Breadcrumb } from 'antd';
import '../custom.less'; // Ensure custom.less is imported to apply styles

const { Header, Content } = Layout;

const Base = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f0f2f5' }}>

        <Breadcrumb style={{ marginBottom: '24px' }}>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Base</Breadcrumb.Item>
        </Breadcrumb>
        <Content style={{ padding: '24px 0' }}>

        </Content>
      </Layout>
  
  );
};

export default Base;
