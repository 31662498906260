// AutomationstableColumns/StatusColumn.js

import React from 'react';
import AutomationStatusTags from '../../../Components/AutomationStatusTags';

const StatusColumn = {
  title: 'Status',
  key: 'status',
  className: 'at-status',
  render: (text, record) => <AutomationStatusTags status={record.status} />
};

export default StatusColumn;
