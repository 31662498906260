import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Input, Button, Row, Col, message, Select } from 'antd';
import ClientPicker from './ClientPicker';
import { createClient, fetchClient, updateClient } from '../services/client.service';
import { useNavigate } from 'react-router-dom';
import { appSubDir } from '../config';

const { Option } = Select;
const validationSchema = Yup.object({
    company_name: Yup.string().required('Required').max(100),
    f_name: Yup.string().required('Required').max(100),
    l_name: Yup.string().max(100),
    phone: Yup.string().max(20),
    email: Yup.string().email('Invalid email format').required('Required').max(100),
    currency: Yup.string(),
    status: Yup.string(),
    client_id: Yup.number().nullable(),
    website: Yup.string().max(255),
    address: Yup.string().max(255),
    city: Yup.string().max(100),
    country: Yup.string().max(100)
});

const ClientForm = ({ client, onSuccess }) => {
    const initialValues = {
        company_name: client ? client.company_name : '',
        f_name: client ? client.contact.f_name : '',
        l_name: client ? client.contact.l_name : '',
        phone: client ? client.contact.phone : '',
        email: client ? client.contact.email : '',
        client_id: client ? client.id : null,
        website: client ? client.website : '',
        address: client ? client.address : '',
        city: client ? client.city : '',
        country: client ? client.country : '',
        stutus: client ? client.stutus : '',
        currency: client ? client.currency : ''
    }
    const formik = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            function handleApiError(error) {
                if (error.response && error.response.data && error.response.data.errors) {
                    formikHelpers.setErrors(error.response.data.errors);
                } else {
                    message.error('An error occurred.');
                }
            }
            if (client) {
                updateClient(client.id, values)
                    .then((res) => {
                        message.success('Client updated successfully');
                        onSuccess(res.data)
                    })
                    .catch(handleApiError);
            } else {
                createClient(values)
                    .then((res) => {
                        message.success('Client created successfully');
                        onSuccess(res.data)
                    })
                    .catch(handleApiError);
            }
        },
    });

    return (
        <Form onFinish={formik.handleSubmit} layout="vertical">
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        label="Company Name"
                        validateStatus={formik.touched.company_name && formik.errors.company_name ? 'error' : ''}
                        help={formik.touched.company_name && formik.errors.company_name}
                    >
                        <Input
                            id="company_name"
                            name="company_name"
                            value={formik.values.company_name}
                            onChange={formik.handleChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="First Name"
                        validateStatus={formik.touched.f_name && formik.errors.f_name ? 'error' : ''}
                        help={formik.touched.f_name && formik.errors.f_name}
                    >
                        <Input
                            id="f_name"
                            name="f_name"
                            value={formik.values.f_name}
                            onChange={formik.handleChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Last Name"
                        validateStatus={formik.touched.l_name && formik.errors.l_name ? 'error' : ''}
                        help={formik.touched.l_name && formik.errors.l_name}
                    >
                        <Input
                            id="l_name"
                            name="l_name"
                            value={formik.values.l_name}
                            onChange={formik.handleChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Email"
                        validateStatus={formik.touched.email && formik.errors.email ? 'error' : ''}
                        help={formik.touched.email && formik.errors.email}
                    >
                        <Input
                            id="email"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Phone"
                        validateStatus={formik.touched.phone && formik.errors.phone ? 'error' : ''}
                        help={formik.touched.phone && formik.errors.phone}
                    >
                        <Input
                            id="phone"
                            name="phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Website"
                        validateStatus={formik.touched.website && formik.errors.website ? 'error' : ''}
                        help={formik.touched.website && formik.errors.website}
                    >
                        <Input
                            id="website"
                            name="website"
                            value={formik.values.website}
                            onChange={formik.handleChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Address"
                        validateStatus={formik.touched.address && formik.errors.address ? 'error' : ''}
                        help={formik.touched.address && formik.errors.address}
                    >
                        <Input
                            id="address"
                            name="address"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="City"
                        validateStatus={formik.touched.city && formik.errors.city ? 'error' : ''}
                        help={formik.touched.city && formik.errors.city}
                    >
                        <Input
                            id="city"
                            name="city"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Country"
                        validateStatus={formik.touched.country && formik.errors.country ? 'error' : ''}
                        help={formik.touched.country && formik.errors.country}
                    >
                        <Input
                            id="country"
                            name="country"
                            value={formik.values.country}
                            onChange={formik.handleChange}
                        />
                    </Form.Item>
                </Col>
                {(client === undefined) && <>
                    <Col span={12}>
                        <Form.Item label="Currency" help={formik.touched.currency && formik.errors.currency}>
                            <Select
                                id="currency"
                                name="currency"
                                value={formik.values.currency}
                                onChange={(value) => formik.setFieldValue('currency', value)}
                                status={formik.touched.currency && formik.errors.currency ? 'error' : ''}
                            >
                                <Option value="CAD">CAD</Option>
                                <Option value="USD">USD</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Status" help={formik.touched.status && formik.errors.status}>
                            <Select
                                id="status"
                                name="status"
                                value={formik.values.status}
                                onChange={(value) => formik.setFieldValue('status', value)}
                                status={formik.touched.status && formik.errors.status ? 'error' : ''}
                            >
                                <Option value="Active">Active</Option>
                                <Option value="Inactive">Inactive</Option>
                                <Option value="Free Trial">Free Trial</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </>}
                <Col span={24}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            {client ? 'Update' : 'Create'}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default ClientForm;
