import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Typography, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import './AuthenticationPages.less';
import { appSubDir } from '../../config';
// import { register } from '../../services/auth.service';
// import { setToken } from '../../stores/auth.slice';
// import { useDispatch } from 'react-redux';

const { Text } = Typography;

const RegisterPage = () => {
  // const [loading, setLoading] = useState(false);
  // const dispatch = useDispatch()
  // const navigate = useNavigate()

  const handleSubmit = (values) => {
    console.log('Form Values:', values);
    message.success('Registration was successful.');
    // setLoading(true);
    // register(values).then((res) => {
    //   const { token } = res.data
    //   message.success('Registration was successful.');
    //   window.localStorage.setItem('token', token)
    //   dispatch(setToken(token))
    //   navigate.replace('/')
    // }).catch((error) => {
    //   let errText = error.response.data.error;
    //   if (!errText) {
    //     errText = error.message;
    //   }
    //   message.error(errText)
    // }).finally(() => {
    //   setLoading(false);
    // })
  };

  return (
    <div className="authentication-register-container">
      <Row className="authentication-register-row">
        <Col className="authentication-form-column" xs={24} md={12}>
          <div className="authentication-logo-container">
            <img src={appSubDir + "assets/images/aio-logo-new.webp"} alt="AIO Solutions" className="authentication-logo" />
          </div>

          <div className="authentication-form-form">
            <div className="authentication-title">Create New Account</div>
            <Form layout="vertical" onFinish={handleSubmit} className="authentication-form">
              <Row gutter={16}>
                <Col xs={24} md={12}>
                  <Form.Item name="f_name" label="First Name" rules={[{ required: true, message: 'Please enter your first name' }]}>
                    <Input placeholder="First Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item name="l_name" label="Last Name" rules={[{ required: true, message: 'Please enter your last name' }]}>
                    <Input placeholder="Last Name" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please enter your email' }]}>
                <Input type="email" placeholder="Email" />
              </Form.Item>
              <Form.Item name="password" label="Password" rules={[{ required: true, message: 'Please enter your password' }]}>
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item name="confirmPassword" label="Confirm Password" rules={[
                { required: true, message: 'Please confirm your password' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Passwords do not match!'));
                  },
                })
              ]}>
                <Input.Password placeholder="Confirm Password" />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" className="authentication-submit-button">Sign Up</Button>
              </Form.Item>
              <Text className="authentication-login-link">
                Already have an account? <Link to="/">Sign In</Link>
              </Text>
            </Form>
          </div>
        </Col>
        <Col className="authentication-image-column" xs={0} md={12}>
          <div className="authentication-image-container">
            <img src={appSubDir + "assets/images/Team-work.webp"} alt="Register" className="authentication-image" />
          </div>
          <div className="authentication-register-right-text">Managing your business just got easier.</div>
        </Col>
      </Row>
    </div>
  );
};

export default RegisterPage;
