// EditPersonalDetailsModal.js

import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import DefaultModal from './DefaultModal';
import './Modals.less';
import { useSelector, useDispatch } from 'react-redux';
import { updateProfile } from '../services/auth.service';
import { setUserData } from '../stores/auth.slice';

const EditPersonalDetailsModal = () => {
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const toggleModal = () => {
    setIsVisible(!isVisible);
  };

  const handleSubmit = (values) => {
    // console.log('Team member details:', values);
    setLoading(true); // Set loading to true when submission starts
    updateProfile(values).then((res) => {
      dispatch(setUserData(res.data))
      const newFields = Object.keys(res.data).map(key => ({
        name: key,
        value: res.data[key]
      }));
      form.setFields(newFields);
      toggleModal();
      message.success('Profile details saved successfully!');
    }).catch(error => {
      if (!error.response) {
        message.error('Network error or server is unreachable');
        return;
      }
      if (error.response.status === 422) {
        // Validation errors handling as before
        const validationErrors = error.response.data.errors;
        const errorFields = Object.keys(validationErrors).map(key => ({
          name: key,
          errors: validationErrors[key]
        }));
        form.setFields(errorFields);
      } else {
        // Non-validation errors
        message.error(error.response.data.message || 'An unexpected error occurred');
      }
    }).finally(() => {
      setLoading(false); // Set loading to false when submission ends
    })
  }


  const footerContent = [
    <Button className="modal-cancel-button" key="back" onClick={toggleModal} loading={loading}>
      Close
    </Button>,
    <Button key="submit" className="modal-save-button" type="primary" onClick={() => form.submit()} loading={loading}>
      Save Changes
    </Button>,
  ];

  return (
    <>
      <div className="ap-combined-details-button" onClick={toggleModal}><i class="fi fi-rr-pencil"></i> Edit Profile</div>
      <DefaultModal
        isVisible={isVisible}
        toggleModal={toggleModal}
        title="Edit Personal Details"
        icon="info"
        footerContent={footerContent}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit} className="main-modal-form edit-personal-details-form">

          <div className="form-row">
            <Form.Item name="f_name" label="First Name" className="form-item-half" initialValue={user.f_name}
              rules={[{ required: true, message: 'First Name Required' }]}>
              <Input placeholder="Enter First Name" />
            </Form.Item>
            <Form.Item name="l_name" label="Last Name" className="form-item-half" initialValue={user.l_name}>
              <Input placeholder="Enter Last Name" />
            </Form.Item>
          </div>
          <div className="form-row">
            <Form.Item name="phone" label="Phone Number" className="form-item-half" initialValue={user.phone}>
              <Input placeholder="Enter phone number" />
            </Form.Item>
            <Form.Item name="email" label="Email" className="form-item-half" initialValue={user.email}>
              <Input placeholder="Enter email" />
            </Form.Item>
          </div>
        </Form>
      </DefaultModal>
    </>
  );
};

export default EditPersonalDetailsModal;
