import React, { useEffect } from 'react';
import NotificationsTable from './NotificationsTable';
import './Notifications.less';
import NotificationsSort from './NotificationsSort';
import { NotificationsProvider, useNotificationsContext } from '../../Context/NotificationsContext';
import SkeletonTable from '../../Components/SkeletonTable';
import { fetchNotifications } from '../../services/notification.service';

const Loading = <div style={{ padding: '24px 0' }}>
  <div style={{ overflowX: 'auto' }}>
    <SkeletonTable rows={3} columns={5} />
  </div>
</div>

const MainComponent = () => {
  const { currentPage, setItems, sortByColumns, setTotalRows, rowsPerPage, loading, setLoading, } = useNotificationsContext();


  // Page Data Loader
  function loadData() {
    setLoading(true);
    fetchNotifications(currentPage, rowsPerPage, sortByColumns).then((res) => {
      setItems(res.data.data);
      setTotalRows(res.data.total);
    }).finally(() => {
      setLoading(false);
    });
  }
  // Fetch clients from the backend
  useEffect(() => {
    const timer = setTimeout(loadData, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [currentPage, rowsPerPage, sortByColumns]);
  return (
    <div className="nf-notifications-container">
      <p className="main-page-title">
        <span className="main-page-title-icon">
          <i className="fi fi-rr-bell"></i>
        </span>
        Notifications
      </p>

      {loading ? Loading : <>
        <NotificationsSort /> {/* Pass props here */}
        <div className="nf-notifications-table-container">

          <NotificationsTable /> {/* Pass rowsPerPage to table */}
        </div>

      </>}
    </div>
  );
};

const Notifications = () => {
  return (
    <NotificationsProvider>
      <MainComponent />
    </NotificationsProvider>
  )
}
export default Notifications;
