import React, { useState } from 'react';
import { Button, Input, Form, Radio } from 'antd';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import AccountBillingInvoices from './AccountBillingInvoices';
import AddCreditCardModal from '../../Modals/AddCreditCardModal';
import UpdateCreditCardModal from '../../Modals/UpdateCreditCardModal';
import './AccountPages.less';

const AccountBilling = () => {
  const [cards, setCards] = useState([
    { number: 'XXXX XXXX XXXX 1289', name: 'John Doe', expiry: '12/24', cvc: '', focused: '', primary: true },
  ]);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [newCard, setNewCard] = useState({ number: '', name: '', expiry: '', cvc: '', focused: '', primary: false });
  const [updateCardIndex, setUpdateCardIndex] = useState(null);

  const handleInputFocus = (e) => {
    setNewCard({ ...newCard, focused: e.target.name });
  };

  const handleInputChange = (e) => {
    setNewCard({ ...newCard, [e.target.name]: e.target.value });
  };

  const showUpdateModal = (index) => {
    setUpdateCardIndex(index);
    setNewCard(cards[index]);
    setIsUpdateModalVisible(true);
  };

  const handleUpdateOk = (values) => {
    const updatedCards = [...cards];
    updatedCards[updateCardIndex] = values;
    setCards(updatedCards);
    setIsUpdateModalVisible(false);
  };

  const handleUpdateCancel = () => {
    setIsUpdateModalVisible(false);
  };

  const showAddModal = () => {
    setNewCard({ number: '', name: '', expiry: '', cvc: '', focused: '', primary: false });
    setIsAddModalVisible(true);
  };

  const handleAddOk = (values) => {
    if (cards.length < 3) {
      setCards([...cards, values]);
    }
    setIsAddModalVisible(false);
  };

  const handleAddCancel = () => {
    setIsAddModalVisible(false);
  };

  const handlePrimaryChange = (index) => {
    const updatedCards = cards.map((card, i) => ({
      ...card,
      primary: i === index,
    }));
    setCards(updatedCards);
  };

  return (
    <div className="ap-billing-main-container">
<h2>Billing Information</h2>
      <div className="ap-billing-container">

        <div className="ap-billing-card-container">
          {cards.map((card, index) => (
            <div key={index} className="ap-card">
              <Radio checked={card.primary} onChange={() => handlePrimaryChange(index)}>
                Primary
              </Radio>
              <Cards number={card.number} name={card.name} expiry={card.expiry} cvc={card.cvc} focused={card.focused} />
              <div className="ap-update-card-button-container">
                <Button className="ap-update-card-button" onClick={() => showUpdateModal(index)}><i class="fi fi-rr-refresh"></i>Update Card</Button>
              </div>
            </div>
          ))}
          {cards.length < 3 && (
            <div className="ap-add-card" onClick={showAddModal}>
              <div className="ap-add-card-content">+ Add New Card</div>
            </div>
          )}
        </div>

        {/* Update Card Modal */}
        <UpdateCreditCardModal
          isVisible={isUpdateModalVisible}
          toggleModal={handleUpdateCancel}
          onSubmit={handleUpdateOk}
        />

        {/* Add Card Modal */}
        <AddCreditCardModal
          isVisible={isAddModalVisible}
          toggleModal={handleAddCancel}
          onSubmit={handleAddOk}
        />
      </div>
      <AccountBillingInvoices />
    </div>
  );
};

export default AccountBilling;
