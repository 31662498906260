// NotificationsSort.js


import React, { useState } from 'react';
import { useNotificationsContext } from '../../Context/NotificationsContext';
import TableSorter from '../../Components/TableSorter';
import NotificationSettingsModal from '../../Modals/NotificationSettingsModal'; // Import the new modal
import { Button } from 'antd';

const SettingsButton = () => {
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility


  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button className="nf-notification-settings" onClick={showModal}>
        <i className="nf-notification-settings-icon fi fi-rr-settings"></i>
        Notification Settings
      </Button>
      <NotificationSettingsModal
        isVisible={isModalVisible}
        toggleModal={handleCancel}
      />
    </>
  );
};

const columns = [
  {
    title: 'Date',
    key: 'date'
  },
  {
    title: 'Type',
    key: 'type'
  }
];
const options = [
  {
    value: 10,
    label: '10'
  },
  {
    value: 20,
    label: '20'
  },
  {
    value: 30,
    label: '30'
  },
  {
    value: 50,
    label: '50'
  },
  {
    value: 100,
    label: '100'
  }
];
const NotificationsSort = () => {
  const {
    sortByColumns, setSortByColumns,
    rowsPerPage, setRowsPerPage
  } = useNotificationsContext();
  const buttons = (
    <SettingsButton />
  )
  return (
    <TableSorter {...{
      columns,
      sortByColumns,
      setSortByColumns,
      options,
      rowsPerPage,
      setRowsPerPage,
      buttons
    }} />
  );
};

export default NotificationsSort;

