import React from 'react';


const AccountHoursTopUp = () => {
  return (
    <div className="ap-plan-container">
      <div className="ap-plan-title-container">
        <h2>Hours Top Up</h2>
        <div className="ap-plan-text">Quickly and easily add more hours to your account or service package with our Hours Topup feature. Whether you need additional support, extended access, or more time to complete your tasks, topping up your hours ensures you have what you need to stay on track.</div>
      </div>


      <div className="ap-plan-addons-container">

        <div className="ap-addon-card">
          <div className="ap-addon-icon-container">
            <div className="ap-addon-icon-circle">
              <i class="fi fi-rr-box-open"></i>
            </div>
          </div>
          <div className="ap-addon-content">
            <h3>40 Hours Package</h3>
            <p>This is our minimum Hours Package to Topup.</p>
          </div>
          <div className="ap-addon-cta-container">
            <div className="ap-addon-cta">
              <p className="ap-addon-price">$400</p>
              <button className="ap-addon-button">Purchase Today</button>
            </div>
          </div>
        </div>

        <div className="ap-addon-card">
          <div className="ap-addon-icon-container">
            <div className="ap-addon-icon-circle">
              <i class="fi fi-rr-box-open"></i>
            </div>
          </div>
          <div className="ap-addon-content">
            <h3>60 Hours Package</h3>
            <p>Has a big load of work ! You can have this</p>
          </div>
          <div className="ap-addon-cta-container">
            <div className="ap-addon-cta">
              <p className="ap-addon-price">$600</p>
              <button className="ap-addon-button">Purchase Today</button>
            </div>
          </div>
        </div>
      </div>







    </div>
  );
};

export default AccountHoursTopUp;
