import React from 'react';
import { Button, Dropdown, Menu } from 'antd';


const orders = [
  {
    key: 'any',
    title: 'Any'
  },
  {
    key: 'asc',
    title: 'A - Z'
  },
  {
    key: 'desc',
    title: 'Z - A'
  }
];
// const columns = [
//   {
//     title: 'Status',
//     key: 'status'
//   },
//   {
//     title: 'Task Overview',
//     key: 'tasks'
//   },
//   {
//     title: 'Date',
//     key: 'date'
//   }
// ];
// const options = [
//   {
//     value: 10,
//     label: '10'
//   },
//   {
//     value: 20,
//     label: '20'
//   },
//   {
//     value: 30,
//     label: '30'
//   },
//   {
//     value: 50,
//     label: '50'
//   },
//   {
//     value: 100,
//     label: '100'
//   }
// ];
const TableRowsOptions = ({ options, rowsPerPage, setRowsPerPage }) => {
  const menu = (
    <Menu onClick={(e) => setRowsPerPage(Number(e.key))}>
      {options.map((option) => <Menu.Item key={option.value}>{option.label}</Menu.Item>)}
    </Menu>
  );

  return (
    <Dropdown dropdownRender={() => menu} trigger={['click']}>
      <Button className="ct-rows-button">
        {rowsPerPage} <i className="fi fi-rr-angle-small-down"></i>
      </Button>
    </Dropdown>
  );
};

const SortingMenuItem = ({ column, sortByColumns, setSortByColumns }) => {
  const handleMenuClick = (e) => {
    if (e.key === 'any') {
      const index = sortByColumns.findIndex((item) => (item.key === column.key))
      sortByColumns.splice(index, 1)
      setSortByColumns([...sortByColumns])
    } else {
      const sortByColumn = sortByColumns.find((item) => (item.key === column.key))
      if (sortByColumn) {
        sortByColumn.order = e.key
      } else {
        sortByColumns.push({
          ...column, order: e.key
        })
      }
      setSortByColumns([...sortByColumns])
    }
  };
  return (
    <Menu onClick={handleMenuClick}>
      {orders.map((item) => <Menu.Item key={item.key}>{item.title}</Menu.Item>)}
    </Menu>
  );
}
const SortingMenu = ({ column, sortByColumns, setSortByColumns }) => {
  const sortByColumn = sortByColumns.find((item) => (item.key === column.key))
  const order = sortByColumn ? orders.find((item) => (item.key === sortByColumn.order)) : null
  return <Dropdown
    dropdownRender={() => <SortingMenuItem {...{ column, sortByColumns, setSortByColumns }} />}
    trigger={['click']}
  >
    <Button>
      {column.title + (order ? " : " + order.title : "")} <i className="fi fi-rr-angle-small-down"></i>
    </Button>
  </Dropdown>
};

const TableSorter = ({
  columns,
  sortByColumns,
  setSortByColumns,
  options,
  rowsPerPage,
  setRowsPerPage,
  buttons
}) => {

  return (
    <div className="pt-sort-container">
      <div className="pt-sort-options-row">
        <div className="pt-sort-buttons">
          {columns ? <>
            <span className="pt-sort-text">Sort: </span>
            {columns.map((column, index) => (
              <SortingMenu key={index} {...{ column, sortByColumns, setSortByColumns }} />
            ))}
          </> : null}

          <div className="pt-add-project-row">
            <div className="pt-rows-text">Rows: </div>
            <TableRowsOptions {...{ options, rowsPerPage, setRowsPerPage }} />
          </div>
        </div>
        {buttons ? <div className="pt-rows-options">
          {buttons}
        </div> : null}
      </div>
    </div>
  );
};

export default TableSorter;
