import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin, Typography } from 'antd';
import { getProfile } from '../services/auth.service';
import { setUserData, setToken, setUserDataLoading } from '../stores/auth.slice';
import PrivateRoutes from '../Routes/PrivateRoutes';
import PublicRoutes from '../Routes/PublicRoutes';


const AuthGuard = () => {
    const token = useSelector((state) => state.auth.token);
    const user = useSelector((state) => state.auth.user);
    const userDataLoading = useSelector((state) => state.auth.userDataLoading);
    const initialized = useRef(false)
    const dispatch = useDispatch();

    useEffect(() => {
        if (user === null && token && !userDataLoading) {
            dispatch(setUserDataLoading(true));
            getProfile(token).then((res) => {
                dispatch(setUserData(res.data));
            }).catch((err) => {
                if (err.response?.status === 401) {
                    window.localStorage.removeItem('token')
                    dispatch(setUserData(null))
                    dispatch(setToken(null));
                } else {
                    console.error("Get User Details Error", err)
                }
            }).finally(() => {
                dispatch(setUserDataLoading(false))
            })
        }
    }, [token, user]);

    if (userDataLoading) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: "column",
                height: '100vh',
                width: '100vw',
                background: 'rgba(0, 0, 0, 0.1)'
            }}>
                <Spin size="large" />
                <Typography.Title level={5} style={{ marginTop: '16px' }}>
                    Verifying Session...
                </Typography.Title>
            </div>
        );
    }

    if (!token) {
        return <PublicRoutes />
    }

    return <PrivateRoutes />
};

export default AuthGuard;
