import React, { useMemo, } from 'react';
import { useTable } from 'react-table';
import { useProjectContext } from '../../Context/ProjectContext';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import columns, { groupByOptions } from './TableColumns/TableColumn';  // Import columns
import './WorkPages.less';
import PGGroupTitleField from './PGGroupTitleField';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const TableSingle = ({ groupIndex }) => {
  const { tasks, taskGroups, groupByColumns, sortByColumns, columnOrder, project, add } = useProjectContext();
  const group = groupIndex !== undefined ? taskGroups[groupIndex] : null
  const orderedColumns = useMemo(() => columnOrder.map((columnId) =>
    columns.find((col) => col.accessor === columnId)
  ), [columnOrder]);


  const handleAddTask = () => {
    add.task(groupIndex)
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: orderedColumns,
      data: group ? group.tasks : tasks,
      groupIndex
    }
  );
  function getSectionLabel(key) {
    if (key === "assignee") {
      const assignee_id = group.attributes[key]
      const assignee = project.members.find((item) => item.user_id === assignee_id)
      if (assignee) {
        return assignee.user.f_name + " " + assignee.user.l_name
      }
    }
    return group.attributes[key]

  }
  function getTable() {

    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <TableHeader key={headerGroup.id} headerGroup={headerGroup} />
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => (
            <TableRow
              key={row.original.taskId}
              row={row}
              index={index}
              prepareRow={prepareRow}
            />
          ))}
          <tr className="pmt-add-task-row">
            <td colSpan={orderedColumns.length} className="pmt-add-task-cell">
              <Button icon={<PlusOutlined />} onClick={handleAddTask} className="pmt-add-task-button">
                Add Task
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
  if (group) {
    return <div className='pmt-group-table'>
      <div className='header'>{Object.keys(group.attributes).map((key, index) => <span key={index}>
        <b>{groupByOptions.find((item) => item.key === key)?.label} :</b>{key === "section" ? <PGGroupTitleField groupIndex={groupIndex} /> : getSectionLabel(key)}

      </span>)}</div>
      {getTable()}
    </div>
  } else {
    return <>{getTable()}</>
  }

};

export default TableSingle;
