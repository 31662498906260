import { Dropdown, Menu } from 'antd';
import React, { useState } from 'react';
import './RowOptions.less'

const RowOptions = ({ children }) => {
  const [visible, setVisible] = useState(false);

  const handleMenuClick = () => {
    setVisible(false);
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  return (
    <div className="container">
      <Dropdown
        dropdownRender={() => (
          <Menu onClick={handleMenuClick}>
            {children}
          </Menu>
        )}
        trigger={['click']}
        onOpenChange={handleVisibleChange}
        open={visible}
      >
        <div className='button'>
          <i className="fi fi-br-menu-dots-vertical"></i>
        </div>
      </Dropdown>
    </div>
  );
};

export default RowOptions;
