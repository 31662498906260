import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import DefaultModal from './DefaultModal';
import './Modals.less';
import { useSelector, useDispatch } from 'react-redux';
import { changePassword } from '../services/auth.service';
import { setUserData } from '../stores/auth.slice';

const EditPasswordModal = () => {
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const toggleModal = () => {
    setIsVisible(!isVisible);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    changePassword(values).then((res) => {
      toggleModal();
      message.success('Password changed successfully!');
      form.resetFields();
    }).catch(error => {
      if (!error.response) {
        message.error('Network error or server is unreachable');
        return;
      }
      if (error.response.status === 422) {
        const validationErrors = error.response.data.errors;
        const errorFields = Object.keys(validationErrors).map(key => ({
          name: key,
          errors: validationErrors[key]
        }));
        form.setFields(errorFields);
      } else {
        message.error(error.response.data.message || 'An unexpected error occurred');
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  const footerContent = [
    <Button className="modal-cancel-button" key="back" onClick={toggleModal} loading={loading}>
      Close
    </Button>,
    <Button key="submit" className="modal-save-button" type="primary" onClick={() => form.submit()} loading={loading}>
      Save Changes
    </Button>,
  ];

  return (
    <>
      <div className="ap-combined-details-button" onClick={toggleModal}><i className="fi fi-rr-pencil"></i> Change Password</div>
      <DefaultModal
        isVisible={isVisible}
        toggleModal={toggleModal}
        title="Change Your Password"
        icon="info"
        footerContent={footerContent}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit} className="main-modal-form edit-password-form">
          <Form.Item
            name="current_password"
            label="Current Password"
            rules={[{ required: true, message: 'Current Password is required' }]}
          >
            <Input.Password placeholder="Enter Current Password" />
          </Form.Item>
          <Form.Item
            name="new_password"
            label="New Password"
            rules={[{ required: true, message: 'New Password is required', min: 8 }]}
          >
            <Input.Password placeholder="Enter New Password" />
          </Form.Item>
          <Form.Item
            name="new_password_confirmation"
            label="Confirm New Password"
            rules={[{ required: true, message: 'Please confirm your new password', min: 8 }]}
          >
            <Input.Password placeholder="Confirm New Password" />
          </Form.Item>
        </Form>
      </DefaultModal>
    </>
  );
};

export default EditPasswordModal;
