import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Typography, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import AuthenticationSlider from './AuthenticationSlider';
import './AuthenticationPages.less';
import { appSubDir } from '../../config';
import { forgotPassword, resetPassword } from '../../services/auth.service';

const { Title, Text } = Typography;

const ForgotPasswordPage = () => {
  const [token, setToken] = useState("")
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const handleResetPassword = (values) => {
    // console.log('Form Values:', values);
    setLoading(true)
    resetPassword({ ...values, token }).then((res) => {
      message.success(res?.data?.message || "Success")
      navigate(appSubDir)
    }).catch((err) => {
      message.error(err?.response?.data?.message || "Something Went Wrong")
    }).finally(() => {
      setLoading(false)
    })
  };
  const handleForgotPassword = (values) => {
    // console.log('Form Values:', values);
    setLoading(true)
    forgotPassword(values).then((res) => {
      setToken(res.data)
    }).catch((err) => {
      message.error(err.response.data?.message || "Something Went Wrong")
    }).finally(() => {
      setLoading(false)
    })
  };

  return (
    <div className="authentication-register-container">
      <Row className="authentication-register-row">
        <Col className="authentication-form-column" xs={24} md={15}>
          <div className="authentication-logo-container">
            <img src={appSubDir + "assets/images/aio-logo-new.webp"} alt="AIO Solutions" className="authentication-logo" />
          </div>

          <div className="authentication-form-form">
            <div className="authentication-title">Reset Your Password</div>
            {token ? <Form layout="vertical" onFinish={handleResetPassword} className="authentication-form">
              <Form.Item name="otp" label="OTP" rules={[{ required: true, message: 'Please enter OTP' }]}>
                <Input readOnly={loading} placeholder="Enter OTP which was sent to your Inbox" />
              </Form.Item>
              <Form.Item name="password" label="Password" rules={[{ required: true, message: 'Please enter your password' }]}>
                <Input.Password readOnly={loading} placeholder="Password" />
              </Form.Item>
              <Form.Item name="confirmPassword" label="Confirm Password" rules={[
                { required: true, message: 'Please confirm your password' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Passwords do not match!'));
                  },
                })
              ]}>
                <Input.Password readOnly={loading} placeholder="Confirm Password" />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" loading={loading} className="authentication-submit-button">Reset Password</Button>
              </Form.Item>
            </Form> : <Form layout="vertical" onFinish={handleForgotPassword} className="authentication-form">
              <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please enter your email' }]}>
                <Input readOnly={loading} type="email" placeholder="Enter Your Email" />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" loading={loading} className="authentication-submit-button">Request OTP</Button>
              </Form.Item>
            </Form>}
            <Text className="authentication-login-link">
              Remembered your password? <Link to="/">Sign In</Link>
            </Text>
          </div>
        </Col>
        <AuthenticationSlider />
      </Row>
    </div>
  );
};

export default ForgotPasswordPage;
