import React from 'react';
import SingleUserAvatar from '../../../Components/SingleUserAvatar';
import { Col, Row } from 'antd';

const AssigneeColumn = {
  title: 'Assignee',
  className: 'assignee-avatar',
  render: (text, record) => (
    <Row>
      <Col>
        <SingleUserAvatar user={record.assignee} />
      </Col>
      {record.project.client.manager ? <Col><SingleUserAvatar label="Account Manager : " user={record.project.client.manager} /> </Col> : null}
    </Row>
  ),
  responsive: ['lg'],
};

export default AssigneeColumn;
