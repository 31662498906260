// ClientsTableColumns/NotesColumn.js

import React, { useState } from 'react';
import { updateClient } from '../../../services/client.service';

const NotesCell = ({ record }) => {
  const [notes, setNotes] = useState(record.notes || '');

  const handleChange = (e) => {
    setNotes(e.target.value);
  };
  const onBlur = (e) => {

    updateClient(record.id, { notes })
      .catch((err) => {
        console.log("update clinet notes Failed", err)
      })
  };

  return (
    <textarea
      value={notes}
      onChange={handleChange}
      onBlur={onBlur}
      className="ct-notes-textarea"
      rows="3"
    />
  );
};

const NotesColumn = {
  title: 'Notes',
  key: 'notes',
  className: 'ct-notes',
  render: (text, record) => <NotesCell record={record} />,
};

export default NotesColumn;
