import React, { useState, useEffect } from 'react';


const TasksColumn = {
  title: 'Task',
  render: (text, record) => (
    <>
      <span>{record.task}</span>
      {record.sub_task_count > 0 ? <div className='lite-text'><b>Sub Tasks : </b>{record.sub_task_count}</div> : null}
    </>
  ),
  responsive: ['lg'],
};

export default TasksColumn;
