// HoursTracker.js

import React, { useEffect, useState } from 'react';
import { Pagination, Menu } from 'antd';
import './HoursTracker.less';
import HoursTrackerTable from './HoursTrackerTable'; // This will contain the table rendering code
import HoursTrackerTableSort from './HoursTrackerTableSort'; // Import the new sorting component
import HoursTrackerChart from './HoursTrackerChart'; // Placeholder for the chart component
import { ClientHoursProvider, useClientHoursContext } from '../../Context/ClientHoursContext';
import { convertDateFormat, fetchClientHours } from '../../services/client.service';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { getTimeSpentStr } from '../../services/project-task.service';
import { getRoleName, hasRole } from '../../services/auth.service';
import { appSubDir } from '../../config';
import moment from 'moment';

const MainComponent = () => {
  const { id } = useParams()
  const user = useSelector((state) => state.auth.user);
  const {
    currentPage, setCurrentPage,
    setItems,
    setLoading,
    totalRows, setTotalRows,
    sortByColumns,
    rowsPerPage
  } = useClientHoursContext();
  const [selectedMenu, setSelectedMenu] = useState('table');

  // Page Data Loader
  function loadData() {
    setLoading(true);
    fetchClientHours(currentPage, rowsPerPage, sortByColumns, getRoleName(user), id).then((res) => {
      res.data.data.forEach((element) => {
        element.d = moment(element.t_date).format('DD MMM YY')
        element.t = element.description
        element.a = element.assignee ? element.assignee.f_name + " " + element.assignee.l_name : ""
        element.tP = element.hours_purchased ? getTimeSpentStr(element.hours_purchased) : ''
        element.tS = element.hours_spent ? getTimeSpentStr(element.hours_spent) : ''
        element.tL = element.closing_hours ? getTimeSpentStr(element.closing_hours) : ""
      });
      setItems(res.data.data);
      setTotalRows(res.data.total);
    }).finally(() => {
      setLoading(false);
    });
  }
  // Fetch clients from the backend
  useEffect(() => {
    const timer = setTimeout(() => {
      loadData()
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [currentPage, rowsPerPage, sortByColumns]);

  const handleMenuClick = (e) => {
    setSelectedMenu(e.key);
  };
  return (
    <div className="ht-hours-tracker-container">
      <div className="ct-title-container">
        <div className="ct-page-title">
          <div className="ct-page-title-icon-container">
            <span className="ct-page-title-icon">
              <i className="fi fi-rr-clock"></i>
            </span>
            Hours Tracker
          </div>
          <div>
            <Menu
              onClick={handleMenuClick}
              selectedKeys={[selectedMenu]}
              mode="horizontal"
              className="ht-menu"
            >
              <Menu.Item key="table" className="ht-menu-item ht-table-menu-item">
                <div className="ht-menu-icon-container">  <i class="fi fi-rr-table-list"> </i></div>
                <div className="ht-menu-text-container">Table</div>
              </Menu.Item>
              <Menu.Item key="chart" className="ht-menu-item ht-chart-menu-item">
                <div className="ht-menu-icon-container">   <i class="fi fi-rr-chart-line-up"></i></div>
                <div className="ht-menu-text-container">  Chart</div>
              </Menu.Item>
            </Menu>
          </div>
        </div>
      </div>
      {selectedMenu === 'table' && (
        <>
          <HoursTrackerTableSort /> {/* Add the sorting component */}
          <div className="ht-hours-tracker-table-container">
            <HoursTrackerTable />
            <Pagination
              current={currentPage}
              pageSize={rowsPerPage}
              total={totalRows}
              onChange={setCurrentPage}
              className="ht-pagination"
            />
          </div>
        </>
      )}
      {selectedMenu === 'chart' && (
        <div className="">
          <HoursTrackerChart /> {/* Placeholder for the chart component */}
        </div>
      )}
    </div>
  );
};

const HoursTracker = () => {
  return (
    <ClientHoursProvider>
      <MainComponent />
    </ClientHoursProvider>
  )
}
export default HoursTracker;
