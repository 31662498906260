import React from 'react';
import './Components.less';

const ClientStatusTags = ({ status }) => {
  let className = 'ct-status-tag';

  switch (status) {
    case 'Inactive':
      className += ' ct-status-inactive';
      break;
    case 'Free Trial':
      className += ' ct-status-free-trial';
      break;
    case 'Active':
      className += ' ct-status-active';
      break;
    case 'Priority':
      className += ' ct-status-priority';
      break;
    default:
      className += ' ct-status-active'; // Default to 'Active' if the status is unknown
  }

  return (
    <div className={className}>
      {status || 'Active'}
    </div>
  );
};

export default ClientStatusTags;
