import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { useProjectsContext } from '../../Context/ProjectsContext';

const TableRowsOptions = () => {
  const { rowsPerPage, setRowsPerPage } = useProjectsContext();
  const menu = (
    <Menu onClick={(e) => setRowsPerPage(Number(e.key))}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
      <Menu.Item key="50">50</Menu.Item>
      <Menu.Item key="100">100</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button className="ct-rows-button">
        {rowsPerPage} <i className="fi fi-rr-angle-small-down"></i>
      </Button>
    </Dropdown>
  );
};

export default TableRowsOptions;
