// StatusTags.js
import React from 'react';
import { Tag } from 'antd';
import './Components.less';

const getStatusTag = (status, additionalClass = '') => {
  let colorClass, iconClass;
  switch (status) {
    case 'Not Started':
      colorClass = 'status-tag-slate-100';
      iconClass = 'status-tag-icon-slate-500';
      break;
    case 'In Progress':
      colorClass = 'status-tag-blue-100';
      iconClass = 'status-tag-icon-blue-500';
      break;
    case 'On Hold':
      colorClass = 'status-tag-yellow-100';
      iconClass = 'status-tag-icon-yellow-500';
      break;
    case 'Completed':
      colorClass = 'status-tag-green-100';
      iconClass = 'status-tag-icon-green-500';
      break;
      case 'In Review':
        colorClass = 'status-tag-pink-100';
        iconClass = 'status-tag-icon-pink-500';
        break;
    default:
      colorClass = 'status-tag-slate-100';
      iconClass = 'status-tag-icon-slate-500';
  }

  return (
    <Tag
      icon={<i className={`fi fi-rr-${statusIconMap[status]} status-tag-icon ${iconClass}`}></i>}
      className={`status-tag ${colorClass} ${additionalClass}`}
    >
      {status}
    </Tag>
  );
};

const statusIconMap = {
  'Not Started': 'minus-circle',
  'In Progress': 'clock-three',
  'On Hold': 'pause',
  'Completed': 'check-circle',
  'In Review': 'comment-alt-dots',
  'default': 'minus-circle'
};

export default getStatusTag;
