// HoursTrackerMenu.js

import React from 'react';
import { Select, Button } from 'antd';
import './HoursTracker.less';

const { Option } = Select;

const HoursTrackerMenu = ({ selectedRange, handleRangeChange, selectedValues, handleFilterChange }) => {
  const isActive = (value) => selectedValues.includes(value);

  return (
    <div className="ht-chart-topbar">
      <div className="ht-chart-filter-buttons">
        <div className="ht-chart-eye-icon-container">
          <i className="fi fi-rr-eye"></i>
        </div>
        <Button className={isActive('timeSpent') ? 'ht-chart-filter-active-button' : 'ht-chart-filter-inactive-button'} onClick={() => handleFilterChange('timeSpent')}>
        <div className="ht-time-spent-circle"> </div>   Time Spent
        </Button>
        <Button className={isActive('timeLeft') ? 'ht-chart-filter-active-button' : 'ht-chart-filter-inactive-button'} onClick={() => handleFilterChange('timeLeft')}>
        <div className="ht-time-left-circle"> </div>  Time Left
        </Button>
      </div>
      <div className="ht-date-range-selector">
        <div className="ht-chart-calendar-icon-container">
          <i className="fi fi-rr-calendar"></i>
        </div>
        <Select value={selectedRange} onChange={handleRangeChange} style={{ width: 106 }}>
          <Option value="7">7 Days</Option>
          <Option value="30">1 Month</Option>
          <Option value="60">2 Months</Option>
          <Option value="90">3 Months</Option>
          <Option value="180">6 Months</Option>
          <Option value="365">1 Year</Option>
        </Select>
      </div>
    </div>
  );
};

export default HoursTrackerMenu;
