import React from 'react';
import { Select } from 'antd';
import { updateSubscription } from '../../../services/subscription.service';


const StatusTags = ({ status }) => {
  let className = 'ct-status-tag';

  switch (status) {
    case 'Paused':
      className += ' ct-status-free-trial';
      break;
    case 'Running':
      className += ' ct-status-active';
      break;
    default:
      className += ' ct-status-inactive'; // Default to 'Active' if the status is unknown
  }

  return (
    <div className={className}>
      {status || 'Active'}
    </div>
  );
};

const { Option } = Select;

const statuses = ['Paused', 'Running', 'Cancelled'];

const StatusColumn = {
  title: 'Status',
  className: 'ct-client-status-column',
  render: (text, record) => {
    const handleStatusChange = (status) => {
      // Implement the logic to update the status in your data source
      // console.log(`Status changed to: ${value}`);
      updateSubscription(record.id, {...record, status })
        .catch((err) => {
          console.log("update clinet notes Failed", err)
        })
    };

    return (
      <Select
        defaultValue={record.status || 'Active'}
        onChange={handleStatusChange}
        className="ct-status-select"
        dropdownClassName="ct-status-select-dropdown"
      >
        {statuses.map((status) => (
          <Option key={status} value={status}>
            <StatusTags status={status} className="ct-status-tag ct-status-tag-dropdown" />
          </Option>
        ))}
      </Select>
    );
  },
};

export default StatusColumn;
