// Automations.js

import React, { useEffect } from 'react';
import { Pagination } from 'antd';
import './AutomationsPages.less';
import NameColumn from './AutomationsTableColumns/NameColumn';
import ExecutionsColumn from './AutomationsTableColumns/ExecutionsColumn';
import CreatedDateColumn from './AutomationsTableColumns/CreatedDateColumn';
import StatusColumn from './AutomationsTableColumns/StatusColumn';
import AutomationsTableSort from './AutomationsTableColumns/AutomationsTableSort';
import StepsRow from './AutomationsTableColumns/StepsRow';
import RowOptions from './AutomationsTableColumns/RowOptions'; // Import the new RowOptions
import { AutomationsProvider, useAutomationsContext } from '../../Context/AutomationsContext';
import { fetchAutomations, fetchCompanyAutomations } from '../../services/automation.service';
import SkeletonTable from '../../Components/SkeletonTable';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { hasRole } from '../../services/auth.service';

const columns = [
  { Header: 'Name', accessor: 'name', render: NameColumn.render },
  { Header: 'Executions', accessor: 'executions', render: ExecutionsColumn.render },
  { Header: 'Date Created', accessor: 'createdDate', render: CreatedDateColumn.render },
  { Header: 'Status', accessor: 'status', render: StatusColumn.render },
];
const Loading = <div style={{ padding: '24px 0' }}>
  <div style={{ overflowX: 'auto' }}>
    <SkeletonTable rows={columns.length} columns={5} />
  </div>
</div>
const TableRow = ({ row, index }) => (
  <>
    <tr className="at-table-row">
      {columns.map((column, colIndex) => (
        <td key={column.accessor} className="at-cell">
          {column.render ? column.render(null, row, index) : row[column.accessor]}
          {colIndex === columns.length - 1 && <div className="row-options-container"><RowOptions row={row} index={index} /></div>} {/* Add RowOptions at the end of the last column */}
        </td>
      ))}
    </tr>
    {row.isExpanded && (
      <tr className="at-expanded-row">
        <td colSpan={columns.length}>
          <StepsRow steps={row.steps} />
        </td>
      </tr>
    )}
  </>
);

const MainComponent = () => {
  const user = useSelector((state) => state.auth.user);
  const isCLient = hasRole(user, 'client')
  const { client_id } = useParams()
  const {
    currentPage, setCurrentPage,
    automations, setAutomations,
    loading, setLoading,
    totalRows, setTotalRows,
    sortByColumns,
    rowsPerPage
  } = useAutomationsContext();


  // Page Data Loader
  function loadData() {
    setLoading(true);
    const apiCall = isCLient ?
      fetchCompanyAutomations(currentPage, rowsPerPage, sortByColumns) :
      fetchAutomations(currentPage, rowsPerPage, client_id ? [...sortByColumns, ...[{ key: 'cid', order: client_id }]] : sortByColumns)

    apiCall.then((res) => {
      res.data.data.forEach(element => {
        element.steps = JSON.parse(element.steps) || []
      });
      setAutomations(res.data.data);
      setTotalRows(res.data.total);
    }).finally(() => {
      setLoading(false);
    });
  }
  // Fetch clients from the backend
  useEffect(() => {
    const timer = setTimeout(() => {
      loadData()
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [client_id, currentPage, rowsPerPage, sortByColumns]);


  return (
    <div className="at-automations-container">
      <p className="main-page-title">
        <span className="main-page-title-icon">
          <i className="fi fi-rr-user-robot"></i>
        </span>
        All Automations
      </p>
      {loading ? Loading : <>
        <AutomationsTableSort />
        <div className="main-table-main-container">
          <div className="main-table-container">
            <table className="main-table">
              <thead className="main-table-head">
                <tr>
                  {columns.map((column) => (
                    <th key={column.accessor} className="main-header-cell">
                      {column.Header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {automations.map((row, index) => (
                  <TableRow key={row.id} row={row} index={index} />
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            current={currentPage}
            pageSize={rowsPerPage}
            total={totalRows}
            onChange={setCurrentPage}
            className="at-pagination"
          />
        </div>
      </>}
    </div>
  );
};

const Automations = () => {
  return (
    <AutomationsProvider>
      <MainComponent />
    </AutomationsProvider>
  )
}
export default Automations;
