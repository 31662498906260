import React, { useState } from 'react';
import { Select, message } from 'antd';
import getStatusTag from '../../../Components/StatusTags'; // Ensure the correct path to your status tag component
import { canEditProject, updateProject } from '../../../services/project.service';
import { useProjectsContext } from '../../../Context/ProjectsContext';
import { useSelector } from 'react-redux';

const { Option } = Select;

const StatusColumn = ({ project, index }) => {
  const user = useSelector((state) => state.auth.user);
  const {
    projects, setProjects
  } = useProjectsContext()

  if (!canEditProject(project,user)) {
    return <>
      {getStatusTag(project.status)}
    </>
  }

  const handleChange = (status) => {
    updateProject(project.id, { status }).then(() => {
      projects[index].status = status
      setProjects([...projects])

    }).catch((err) => {
      console.log("update project status Failed", err)
      message.error("Update project status Failed", 2000)
    })
  };

  return (
    <Select
      value={project.status}
      onChange={handleChange}
      className="status-dropdown"
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      <Option value="Not Started">{getStatusTag('Not Started')}</Option>
      <Option value="In Progress">{getStatusTag('In Progress')}</Option>
      <Option value="On Hold">{getStatusTag('On Hold')}</Option>
      <Option value="Completed">{getStatusTag('Completed')}</Option>
      <Option value="In Review">{getStatusTag('In Review')}</Option>
    </Select>
  );
};

export default StatusColumn;
