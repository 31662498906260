export const appSubDir = '/'
// export const baseUrl = "https://api-aio-solutions.evaluhire.com/"
export const baseUrl = "/"
// export const baseUrl = "http://127.0.0.1:8000/"
export const apiBaseUrl = baseUrl + "api/"
export const storageBaseUrl = baseUrl + "storage/"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyDHNW_UoqWyIIB-zC0q3nTZDhLZNgzwI_Q",
  authDomain: "bfm-aio-solutions.firebaseapp.com",
  projectId: "bfm-aio-solutions",
  storageBucket: "bfm-aio-solutions.appspot.com",
  messagingSenderId: "219837570729",
  appId: "1:219837570729:web:94914b87f919852a8fd3b8",
  measurementId: "G-CM4XP8HX8Y"
};

export const stripeKey = 'pk_test_51PpdshP8Bl6Q422GJGjOwzIXJqb6OJfEbMV2ZIO4fPBIW7MTeLoU2bacsxG39u7bMpI5BtaLrAkGacYjniRPqAxw00Aovs7L7d'