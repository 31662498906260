import React from 'react';
import columns from './TableColumns/TableColumn'; // Adjust the path if necessary

const SubtaskRow = ({ subtask, taskIndex, subtaskIndex, taskId }) => {
  const row = { original: subtask, index: subtaskIndex };
  const projectId = 1; // Set the project ID here

  return (
    <tr id={`task-${taskId}-sub-task-${subtask.subtaskId}`} className="pmt-subtask-row">
      {columns.map((column, index) => {
        const CellComponent = column.Cell;
        return (
          <td key={index} className={`pmt-cell ${column.className}`}>
            <CellComponent
              value={subtask[column.accessor]}
              row={row}
              column={{ id: column.accessor }}
              projectId={projectId} // Pass projectId for the assignee column
              // onKeyPress={onKeyPress} // Attach the onKeyPress handler
              taskId={taskId}
              taskIndex={taskIndex}
              subtaskIndex={subtaskIndex}
            />
          </td>
        );
      })}
    </tr>
  );
};

export default SubtaskRow;
