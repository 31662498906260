const teamMembers = [
  { id: 1, name: 'Noman Hossain', jobTitle: 'CEO', email: 'noman@example.com', assignedTasks: 3, inProgressTasks: 6, completedTasks: 3, overdueTasks: 2 },
  { id: 2, name: 'Sazzad Mahmud', jobTitle: 'Web Developer', email: 'sazzad@example.com', assignedTasks: 7, inProgressTasks: 3, completedTasks: 4, overdueTasks: 1 },
  { id: 3, name: 'Muhammad Afaq', jobTitle: 'Web Developer', email: 'afaq@example.com', assignedTasks: 8, inProgressTasks: 4, completedTasks: 18, overdueTasks: 0 },
  { id: 4, name: 'Shanto H', jobTitle: 'WordPress Developer', email: 'kobe@example.com', assignedTasks: 18, inProgressTasks: 2, completedTasks: 0, overdueTasks: 3 },
  { id: 5, name: 'Forhad Uddin', jobTitle: 'Account Manager', email: 'michael@example.com', assignedTasks: 5, inProgressTasks: 2, completedTasks: 10, overdueTasks: 1 },
  { id: 6, name: 'Alex Rozario', jobTitle: 'Web Developer', email: 'alex@example.com', assignedTasks: 9, inProgressTasks: 5, completedTasks: 22, overdueTasks: 2 },
  { id: 7, name: 'Mayowa A.', jobTitle: 'Marketing Specialist', email: 'mayowa@example.com', assignedTasks: 4, inProgressTasks: 2, completedTasks: 8, overdueTasks: 0 },
  { id: 8, name: 'Vitalii Zurov', jobTitle: 'Web Developer', email: 'rafi@example.com', assignedTasks: 12, inProgressTasks: 7, completedTasks: 25, overdueTasks: 1 },
  { id: 9, name: 'John Doe', jobTitle: 'Web Developer', email: 'john@example.com', assignedTasks: 6, inProgressTasks: 3, completedTasks: 14, overdueTasks: 0 },
  { id: 10, name: 'Mizan Hossain', jobTitle: 'Lead Designer', email: 'mizanh@example.com', assignedTasks: 7, inProgressTasks: 4, completedTasks: 16, overdueTasks: 2 },
  { id: 11, name: 'Fahim Hossain', jobTitle: 'Graphic Designer', email: 'fahim@example.com', assignedTasks: 5, inProgressTasks: 2, completedTasks: 10, overdueTasks: 1 },
  { id: 12, name: 'Al Mamun', jobTitle: 'UI/UX Designer', email: 'mamun@example.com', assignedTasks: 8, inProgressTasks: 5, completedTasks: 17, overdueTasks: 0 },
  { id: 13, name: 'Mashfique Ahmed', jobTitle: 'Full Stack Developer', email: 'mashfique@example.com', assignedTasks: 10, inProgressTasks: 6, completedTasks: 23, overdueTasks: 3 },
  { id: 14, name: 'Deep Rahman', jobTitle: 'Content Writer', email: 'deep@example.com', assignedTasks: 4, inProgressTasks: 2, completedTasks: 9, overdueTasks: 1 },
];

export default teamMembers;
