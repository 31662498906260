import React from 'react';
import { Table, Skeleton, Card } from 'antd';

const SkeletonTable = ({ rows, columns }) => {
    const dataSource = Array.from({ length: rows }, (_, rowIndex) => {
        const row = { key: rowIndex };
        for (let colIndex = 0; colIndex < columns; colIndex++) {
            row[`col${colIndex}`] = <Skeleton active paragraph={false} />;
        }
        return row;
    });

    const columnsDefinition = Array.from({ length: columns }, (_, colIndex) => ({
        title: <Skeleton active paragraph={false} />,
        dataIndex: `col${colIndex}`,
        key: `col${colIndex}`,
    }));

    return (
        <Card>
            <Table
                dataSource={dataSource}
                columns={columnsDefinition}
                pagination={false}
                bordered
            />
        </Card>
    );
};

export default SkeletonTable;
