import React, { useState, useEffect } from 'react';
import { Table, Menu, Dropdown, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import SingleAvatar2 from '../../Components/SingleAvatar2';
import SkeletonTable from '../../Components/SkeletonTable';
import { fetchAdmins } from '../../services/member.service';
import { useSelector } from 'react-redux';
import AdminFormModal from '../../Modals/AdminFormModal';
import { getAvatarUrl } from '../../services/auth.service';

const Loading = <div style={{ padding: '24px 0' }}>
  <div style={{ overflowX: 'auto' }}>
    <SkeletonTable rows={5} columns={5} />
  </div>
</div>
const SuperAdmins = () => {
  const user = useSelector((state) => state.auth.user);
  const [items, setItems] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  // Page Data Loader
  function loadData() {
    setLoading(true);
    fetchAdmins(currentPage, rowsPerPage).then((res) => {
      setItems(res.data.data);
      setTotalRows(res.data.total);
    }).finally(() => {
      setLoading(false);
    });
  }
  // Fetch clients from the backend
  useEffect(() => {
    const timer = setTimeout(() => {
      loadData()
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [currentPage, rowsPerPage]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setRowsPerPage(pageSize);
  };

  const columns = [
    {
      title: 'Name',
      key: 'name',
      render: (record) => (
        <div className="ap-team-name">
          {record.avatar ?<img className='profile-pic' src={getAvatarUrl(record.avatar)} alt={record?.f_name + " " + record?.l_name} /> : <SingleAvatar2 name={record.f_name + " " + record.l_name} />}
          <span>{record.f_name} {record.l_name}</span>
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '',
      key: 'options',
      render: (record) => (
        <>
          {user.id !== record.id ? <div className="row-options-container">
            <Dropdown
              overlay={(
                <Menu>
                  <Link to='/team/member-details'>
                    <Menu.Item key="1">Edit</Menu.Item>
                  </Link>
                  <Menu.Item key="2">Delete Permanently</Menu.Item>
                </Menu>
              )}
              trigger={['click']}
            >
              <div className="row-options">
                <i className="fi fi-br-menu-dots-vertical"></i>
              </div>
            </Dropdown>
          </div> : null}
        </>
      ),
    },
  ];


  return (
    <div className="ap-team-container">
      <div className="ap-team-title-row">
        <h2>Super Admins</h2>
        <button className="ap-team-add-member-button" onClick={() => setIsAddModalVisible(true)}> Add </button>
      </div>
      {loading ? Loading : <Table columns={columns} dataSource={items} pagination={false} />}
      <div className="ap-team-pagination-container">
        <Pagination
          current={currentPage}
          pageSize={rowsPerPage}
          total={items.length}
          onChange={handlePageChange}
        />
      </div>
      <AdminFormModal
        isVisible={isAddModalVisible}
        toggleModal={() => setIsAddModalVisible(false)}
      />
    </div>
  );
};

export default SuperAdmins;
