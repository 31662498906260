import React, { useState } from 'react';
import { Drawer, Input, Upload, message } from 'antd';
import AddTaskDescription from './AddTaskDescription';
import TaskAttachments from './TaskAttachments';
import TaskSummary from './TaskSummary';
import ModalSubtasks from './ModalSubtasks';
import TaskComments from './TaskComments';
import '../Modals.less';
import '../../custom.less';
import { useProjectContext } from '../../Context/ProjectContext';

const TaskDetailsModal = ({ taskIndex, visible, setVisible }) => {
  const [attachments, setAttachments] = useState([]);
  const { tasks, project, update } = useProjectContext()
  const task = tasks[taskIndex]
  function onTaskUpdate(key, value, subtaskIndex = -1) {
    if (subtaskIndex === -1) {
      // console.log("TaskDetailsModal",task.taskIndex)
      update.task(task, key, value)
    } else {
      update.subTask(task.subtasks[subtaskIndex], key, value)
    }
  }

  const handleUpload = ({ file }) => {
    if (file.status === 'done') {
      message.success(`${file.name} file uploaded successfully`);
      setAttachments([...attachments, file]);
    } else if (file.status === 'error') {
      message.error(`${file.name} file upload failed.`);
    }
  };

  function onClose() {
    setVisible(false);
  }

  function handleTaskNameChange(e) {
    onTaskUpdate('taskName', e.target.value);
  };

  return (
    <Drawer
      placement="right"
      width={740}
      onClose={onClose}
      open={visible}
      className="task-details-modal"
    >
      <div className="task-details-topbar">
        <div className="task-details-left">
          <span className="task-icon modal-icon-container">
            <i className="fi fi-rr-memo-pad modal-icon"></i>
          </span>
          <span className="task-text">Task Details</span>
        </div>
        <div className="task-details-right">
          <span className="small-button"><i class="fi fi-rr-clone"></i></span>
          <Upload
            multiple={false}
            customRequest={({ file, onSuccess }) => setTimeout(() => onSuccess("ok"), 0)}
            onChange={handleUpload}
            showUploadList={false}
          >
            <span className="small-button"><i class="fi fi-rr-paperclip-vertical"></i></span>
          </Upload>
          <span className="small-button"><i className="fi fi-rr-menu-dots"></i></span>
          <span className="tdm-close-icon" onClick={onClose}>
            <span className="small-button"><i className="fi fi-rr-exit"></i></span>
          </span>
        </div>
      </div>

      <div className="tdm-modal-body">
        <Input
          className="tdm-task-cell-content border-1"
          defaultValue={task.taskName}
          onBlur={handleTaskNameChange}
        />

        <TaskSummary {...{ task, onTaskUpdate, project }} />
        <AddTaskDescription />
        <ModalSubtasks {...{ task, onTaskUpdate, project }} />

        <div className="task-attachments-section">
          <TaskAttachments attachments={attachments} />
        </div>

      </div>
      <TaskComments />
    </Drawer>
  );
};

export default TaskDetailsModal;
