// file path : src\services\subscription.service.js

import axios from "axios";
import { apiBaseUrl } from "../config";

export function fetchList(page = 0, rowsPerPage = 20, sortByColumns = []) {
    const query = new URLSearchParams("")
    if (page) {
        query.set('page', page.toString());
    }
    if (rowsPerPage) {
        query.set('per_page', rowsPerPage.toString());
    }
    if (sortByColumns && sortByColumns.length > 0) {
        sortByColumns.forEach(element => {
            if (element.key && element.order)
                query.set(element.key, element.order);
        });
    }
    let url = [apiBaseUrl + "subscriptions", query.toString()].join("/?")
    return axios.get(url, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function activateStaterSubscription() {
    return axios.get(apiBaseUrl + "company/activate-starter-subscription", { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function fetchCompanySubscriptions(page = 0, rowsPerPage = 20, sortByColumns = []) {
    const query = new URLSearchParams("")
    if (page) {
        query.set('page', page.toString());
    }
    if (rowsPerPage) {
        query.set('per_page', rowsPerPage.toString());
    }
    if (sortByColumns && sortByColumns.length > 0) {
        sortByColumns.forEach(element => {
            if (element.key && element.order)
                query.set(element.key, element.order);
        });
    }
    let url = [apiBaseUrl + "company/subscriptions", query.toString()].join("/?")
    return axios.get(url, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function fetchSubscription(subscriptionId) {
    return axios.get(apiBaseUrl + `subscriptions/${subscriptionId}`, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function fetchCompanySubscription() {
    return axios.get(apiBaseUrl + `company/subscription`, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function updateSubscription(subscriptionId, values) {
    return axios.put(apiBaseUrl + `subscriptions/${subscriptionId}`, values, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function removeMember(subscriptionId, user_id) {
    return axios.delete(apiBaseUrl + `subscriptions/${subscriptionId}/members/${user_id}`, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function addMember(subscriptionId, user_id) {
    return axios.post(apiBaseUrl + `subscriptions/${subscriptionId}/member`, { user_id }, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function addSubscription(name, client_id) {
    return axios.post(apiBaseUrl + `subscriptions`, { name, client_id }, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function requestSubscription(dataToPost) {
    return axios.post(apiBaseUrl + `company/subscriptions`, dataToPost, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}

export function createSubscription(values) {
    return axios.post(apiBaseUrl + 'subscriptions', values, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}

export function subscriptionAutocomplete(value) {
    return axios.get(apiBaseUrl + `subscriptions/autocomplete/${value}`, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
