import React from 'react';
import RowOptions from './RowOptions'; // Import RowOptions
import './Notifications.less';
import { Pagination } from 'antd';
import { useNotificationsContext } from '../../Context/NotificationsContext';
import moment from 'moment';

const getIconClass = (type) => {
  switch (type) {
    case 'Task Created':
      return 'fi fi-rr-note';
    case 'Task Completed':
      return 'fi fi-rr-note';
    case 'Project Created':
      return 'fi fi-rr-briefcase';
    case 'Project Completed':
      return 'fi fi-rr-briefcase';
    case 'Payment Made':
      return 'fi fi-rr-credit-card';
    case 'Hours Low':
      return 'fi fi-rr-alarm-clock';
    case 'Hours Finished':
      return 'fi fi-rr-hourglass-end';
    default:
      return 'fi fi-rr-bell';
  }
};

const getIconContainerClass = (type) => {
  switch (type) {
    case 'Task Created':
      return 'nf-task-created-icon-container';
    case 'Task Completed':
      return 'nf-task-completed-icon-container';
    case 'Project Created':
      return 'nf-project-created-icon-container';
    case 'Project Completed':
      return 'nf-project-completed-icon-container';
    case 'Payment Made':
      return 'nf-payment-made-icon-container';
    case 'Hours Low':
      return 'nf-hours-low-icon-container';
    case 'Hours Finished':
      return 'nf-hours-finished-icon-container';
    default:
      return 'nf-default-icon-container';
  }
};

const columns = [
  {
    Header: 'Notification',
    accessor: 'type',
    render: (type, record) => (
      <div className="nf-notification-cell">
        <div className={`nf-icon-container ${getIconContainerClass(type)}`}>
          <i className={`nf-icon ${getIconClass(type)}`}></i>
        </div>
        <div className="nf-text-container">
          <div className="nf-type">{type}</div>
          <div className="nf-name">{record.message}</div>
        </div>
      </div>
    ),
    className: 'nf-notification-column'
  },
  {
    Header: 'Time',
    accessor: 'created_at',
    render: (text) => (
      <div className="nf-time-cell">
        {moment(text).fromNow()}
        <div className="row-options-container">
          <RowOptions />
        </div>
      </div>
    ),
    className: 'nf-time-column'
  },
];

const TableRow = ({ row, columns }) => (
  <tr className="nf-table-row">
    {columns.map((column, colIndex) => (
      <td key={column.accessor} className="nf-table-cell">
        {column.render ? column.render(row[column.accessor], row) : row[column.accessor]}
      </td>
    ))}
  </tr>
);

const NotificationsTable = () => {
  const { currentPage, totalRows, items, rowsPerPage, setCurrentPage } = useNotificationsContext();

  return (
    <div className="nf-notifications-container">
      <div className="main-table-container">
        <table className="main-table">
          <thead className="main-table-head">
            <tr>
              {columns.map((column) => (
                <th key={column.accessor} className="main-header-cell">
                  {column.Header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {items.map((row, index) => (
              <TableRow key={index} row={row} columns={columns} />
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        current={currentPage}
        pageSize={rowsPerPage}
        total={totalRows}
        onChange={setCurrentPage}
        className="main-pagination"
      />
    </div>
  );
};

export default NotificationsTable;
