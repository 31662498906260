import React, { useState, useEffect } from 'react';
import { Table, Layout, Space } from 'antd';
import AddSingleCard from './ProjectOverviewComponents/AddSingleCard'; // Import the AddCard component
import ManageTeamModal from '../../Modals/ManageTeamModal'; // Import ManageTeamModal
import { projects, teamMembers as allTeamMembers, clientsData } from '../../Data/ProjectsSampleData'; // Import data
import '../../custom.less'; // Ensure custom.less is imported to apply styles
import { useProjectContext } from '../../Context/ProjectContext';
import TeamMembersModal from './TeamMembersModal';
import CustomAvatarGroup from '../../Components/UserAvatars2';
import UserAvatarsGrid from './UserAvatarsGrid';
import ProjectOverviewTasksBar from './ProjectOverviewComponents/ProjectOverviewTasksBar';

const { Content } = Layout;

const ProjectOverview = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const { project } = useProjectContext(); // State for selected project
  const [isModalVisible, setIsModalVisible] = useState(false);


  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: 'Total Tasks',
      dataIndex: 'total_tasks',
      key: 'total_tasks',
    },
    {
      title: 'Not Started',
      dataIndex: 'tasks_not_started',
      key: 'tasks_not_started',
    },
    {
      title: 'In Progress',
      dataIndex: 'tasks_in_progress',
      key: 'tasks_in_progress',
    },
    {
      title: 'Complete',
      dataIndex: 'tasks_completed',
      key: 'tasks_completed',
    },
  ];

  return (
    <div className="pmt-overview-main-container">
      <p className="subheader"> Project Overview </p>
      <Content className="project-container">
        {/* Column 1: Project Overview and Cards */}
        <div style={{ flex: 5, paddingRight: '24px' }}>
          <div className="pmt-overview-column-box">

            <AddSingleCard />

            <div className="pmt-overview-header">
              <div className="pmt-overview-header-created-last-updated-container">
                <div className="pmt-overview-header-info-item">
                  <div className="pmt-overview-header-icon-circle">
                    <i className="fi fi-rr-calendar"></i>
                  </div>
                  <div className="pmt-overview-header-info-text">
                    <div className="pmt-overview-header-label">Created:</div>
                    <div className="pmt-overview-header-datetime">Jul 17, 2024 | 2:30pm</div>
                  </div>
                </div>
                <div className="pmt-overview-header-info-item">
                  <div className="pmt-overview-header-icon-circle">
                    <i className="fi fi-rr-clock"></i>
                  </div>
                  <div className="pmt-overview-header-info-text">
                    <div className="pmt-overview-header-label">Last Update:</div>
                    <div className="pmt-overview-header-datetime">Jul 17, 2024 | 2:30pm</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Column 2: Team Members and Task Summary */}
        <div style={{ flex: 2 }}>

          <div className="pmt-team-title">Team</div>
          <div className="pmt-team-box">
            <UserAvatarsGrid />
          </div>
          <div className="pmt-task-title">Tasks</div>

          <div className="pmt-overview-tasks-container">
            <ProjectOverviewTasksBar />
          </div>
        </div>
      </Content>

      <ManageTeamModal
        isVisible={isModalVisible}
        project={project}
        toggleModal={handleCancel}
        teamMembers={allTeamMembers}
        clientsData={clientsData}
      />
    </div>
  );
};

export default ProjectOverview;
