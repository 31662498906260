// AutomationstableColumns/NameColumn.js

import React from 'react';
import { createAvatar } from '@dicebear/core';
import * as collection from '@dicebear/collection';
import { useAutomationsContext } from '../../../Context/AutomationsContext';

const Component = ({ record }) => {
  const {
    automations, setAutomations
  } = useAutomationsContext();
  // Generate the avatar URL using Dicebear
  const avatar = createAvatar(collection.bottts, {
    seed: record.title, // Use the automation title as the seed for the avatar
  });

  const avatarUrl = avatar.toDataUriSync();
  function toggleExpand() {
    record.isExpanded = !record.isExpanded
    setAutomations([...automations])
  }
  return (
    <div className="at-name-column">
      <i
        className={`fi ${record.isExpanded ? 'fi-rr-angle-small-down' : 'fi-rr-angle-small-right'}`}
        onClick={toggleExpand}
        style={{ cursor: 'pointer', marginRight: '8px' }}
      ></i>
      <img src={avatarUrl} alt={record.title} className="at-avatar" />
      <span>{record.title}</span>
    </div>
  );
}

const NameColumn = {
  title: 'Name',
  key: 'name',
  className: 'at-name',
  render: (text, record) => <Component record={record} />
};

export default NameColumn;
