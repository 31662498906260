// src/Utils/taskActions.js

import { enhanceElementAppearance } from "../services/common.functions";
import { addTask, updateTask } from "../services/project-task.service";
import { sortItemsAction } from "./SortingActions";

/*
conditions = [{
  column:"taskName",
  order:"asc"
}......]
*/
export function sortTaskAction(tasks, conditions) {
  if (conditions.length) {
    sortItemsAction(tasks, conditions)
  } else {
    sortItemsAction(tasks, [{
      column: "position",
      order: "asc"
    }])
  }
};
// Example Column Keys:
// const keys = ['assignee', 'status'];
export function groupTasksAction(tasks, keys) {
  if (keys.length < 1) {
    return []
  }
  // Helper function to generate unique key for grouping
  function generateKey(task) {
    return keys.map(key => `${key}:${task[key]}`).join('|');
  }

  // Dictionary to store unique groupings
  const groups = {};

  // Process each task
  tasks.forEach(task => {
    const groupKey = generateKey(task);

    if (!groups[groupKey]) {
      // Initialize the group if it doesn't exist
      groups[groupKey] = {
        attributes: {},
        tasks: []
      };

      // Set attributes
      keys.forEach(key => {
        groups[groupKey].attributes[key] = task[key];
      });
    }

    // Add the task to the correct group
    groups[groupKey].tasks.push(task);
  });

  // Convert dictionary to array format as requested
  const newGroups = Object.values(groups)
  if (newGroups.length > 0) {
    newGroups.forEach((group, groupIndex) => {
      group.tasks.forEach((task, taskIndex) => {
        task.groupIndex = groupIndex;
        task.groupTaskIndex = taskIndex;
        task.subtasks.forEach((subTask, subTaskIndex) => {
          subTask.groupIndex = groupIndex;
          subTask.groupTaskIndex = taskIndex;
          subTask.groupSubTaskIndex = subTaskIndex;
        })
      })
    })
  }
  return newGroups
}
export function beforeSetTasks(tasks) {
  tasks.forEach((task, taskIndex) => {
    task.taskIndex = taskIndex;
    task.subtasks.forEach((subTask, subTaskIndex) => {
      subTask.taskIndex = taskIndex;
      subTask.subTaskIndex = subTaskIndex;
    })
  })
};
export function getGroupIndex(taskGroups, taskId) {
  return taskGroups.findIndex((item) => (item.tasks.map((item) => (item.taskId)).includes(taskId)))
};
export function getGroupIndexFromSubtask(taskGroups, taskIndex, subtaskIndex, subtaskId) {
  return taskGroups.findIndex((item) => {
    const task = item.tasks[taskIndex]
    if (!task) {
      return false
    }
    const subTask = task.subtasks[subtaskIndex]
    if (!subTask || subTask.subtaskId !== subtaskId) {
      return false
    }
    return true
  })
};
export function updateTaskAction(tasks, taskIndex, updatedTask, conditions) {
  const task = tasks[taskIndex]
  tasks[taskIndex] = { ...task, ...updatedTask }
  const newTask = tasks[taskIndex]
  if (newTask.notInserted) {
    addTask(
      newTask.project_id,
      newTask.task_id,
      0,
      newTask.taskName,
      newTask.assignee,
      newTask.dueDate,
      newTask.timeSpent,
      newTask.status,
      newTask.section
    )
    delete newTask.notInserted
  } else {
    updateTask(
      newTask.project_id,
      newTask.task_id,
      0,
      newTask.taskName,
      newTask.assignee,
      newTask.dueDate,
      newTask.timeSpent,
      newTask.status,
      newTask.section
    )
  }
  sortTaskAction(tasks, conditions)
};

export const updateSubtask = (tasks, taskIndex, subtaskIndex, updatedSubtask) => {
  const subtask = tasks[taskIndex].subtasks[subtaskIndex]
  tasks[taskIndex].subtasks[subtaskIndex] = { ...subtask, ...updatedSubtask }
  const tsk = tasks[taskIndex]
  const newSubTask = tasks[taskIndex].subtasks[subtaskIndex]
  updateTask(
    tsk.project_id,
    tsk.task_id,
    newSubTask.subtaskId,
    newSubTask.taskName,
    newSubTask.assignee,
    newSubTask.dueDate,
    newSubTask.timeSpent,
    newSubTask.status
  )
  return [...tasks]
};

export const deleteSubtask = (tasks, taskId, subtaskId) => {
  return tasks.map(task =>
    task.taskId === taskId ? {
      ...task,
      subtasks: task.subtasks.filter(subtask => subtask.subtaskId !== subtaskId)
    } : task
  );
};

export const addNewSubtask = (tasks, taskId) => {
  const newSubtask = {
    subtaskId: new Date().getTime(), // Unique ID for the new subtask
    taskName: '',
    assignee: '',
    status: 'Not Started',
    dueDate: null,
    timeSpent: '0h'
  };

  return tasks.map(task =>
    task.taskId === taskId ? { ...task, subtasks: [...task.subtasks, newSubtask] } : task
  );
};
export const glowEffect = (selector, focusInput = false, startAfter = 10, stopAfter = 2000) => {
  setTimeout(() => {
    const element = document.querySelector(selector);
    // console.log("New Input", element)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      element.classList.add('glow-effect');
      focusInput && element.querySelector(`input`)?.focus()

      setTimeout(() => {
        element.classList.remove('glow-effect');
      }, stopAfter)
    }
  }, startAfter)
};

export const focusRowInput = (selector, startAfter = 10) => {
  setTimeout(() => {
    const element = document.querySelector(selector);
    // console.log("New Input", element)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      element.querySelector(`input`)?.focus()
    }
  }, startAfter)
};
export const foccusTaskRow = (taskId, startAfter = 10) => {
  enhanceElementAppearance(`#task-${taskId}`, { focusInput: false, glow: true, startAfter, stopAfter: 2000 })
};
export const foccusTaskInput = (taskId, startAfter = 10) => {
  enhanceElementAppearance(`#task-${taskId}`, { focusInput: true, startAfter })
};
export const foccusSubTaskInput = (taskId, subTaskId) => {
  enhanceElementAppearance(`#task-${taskId}-sub-task-${subTaskId}`, { focusInput: true })
};

export const addTaskAction = (projectId, tasks, taskGroups, groupIndex, groupByColumns, taskIndex) => {
  const taskId = tasks.length > 0 ? Math.max(...(tasks.map((i) => (i.taskId)))) + 1 : 1
  const subtaskId = 0
  const newTask = {
    taskId,
    project_id: projectId,
    subtaskId,
    task_id: taskId,
    position: tasks.length,
    taskName: '',
    subtasks: [],
    assignee: '',
    status: 'Not Started',
    dueDate: null,
    timeSpent: '0h',
    notInserted: true // Not inserted by api
  };
  const group = groupIndex >= 0 ? taskGroups[groupIndex] : null
  const tableTasks = group ? group.tasks : tasks
  groupByColumns.forEach((item) => {
    newTask[item] = tableTasks.length > 0 ? tableTasks[tableTasks.length - 1][item] : ""
  })
  if (taskIndex >= 0) {
    tasks.splice(taskIndex + 1, 0, newTask);
    tasks.map((item, index) => {
      item.position = index + 1
      return item
    })
  } else {
    tasks.push(newTask)
  }
  return newTask
  // sortTaskAction(tasks, sortingConditions)
};
export const addSectionAction = (projectId, tasks, section) => {
  const taskId = tasks.length > 0 ? Math.max(...(tasks.map((i) => (i.taskId)))) + 1 : 1
  const subtaskId = 0
  const newTask = {
    taskId,
    project_id: projectId,
    subtaskId,
    task_id: taskId,
    position: tasks.length,
    taskName: '',
    subtasks: [],
    assignee: '',
    status: 'Not Started',
    dueDate: null,
    timeSpent: '0h',
    notInserted: true // Not inserted by api
  };
  newTask.section = section
  tasks.push(newTask)
  return newTask
  // sortTaskAction(tasks, sortingConditions)
};

export const addSubTaskAction = (projectId, tasks, taskIndex, subtaskIndex) => {
  const task = tasks[taskIndex]
  const subTasks = task.subtasks
  const subTaskId = subTasks.length > 0 ? Math.max(...(subTasks.map((i) => (i.subTaskId)))) + 1 : 1
  const subtaskId = 0
  const newSubTask = {
    subTaskId,
    project_id: projectId,
    subtaskId,
    task_id: task.id,
    sub_task_id: subTaskId,
    position: subTasks.length,
    taskName: '',
    subtasks: [],
    assignee: '',
    status: 'Not Started',
    dueDate: null,
    timeSpent: '0h',
    notInserted: true // Not inserted by api
  };
  if (subtaskIndex >= 0) {
    subTasks.splice(subtaskIndex + 1, 0, newSubTask);
    subTasks.map((item, index) => {
      item.position = subtaskIndex + 1
      return item
    })
  } else {
    subTasks.push(newSubTask)
  }
  return newSubTask
  // sortTaskAction(tasks, sortingConditions)
};
