import React, { useState, useEffect } from 'react';
import { Layout, Breadcrumb, List, Avatar, Typography, Card, Upload, Button, Tooltip, Dropdown, Menu, Space } from 'antd';
import { UploadOutlined, FileTextOutlined, EyeOutlined, PaperClipOutlined, EllipsisOutlined, UserAddOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

import messages from '../../Data/SampleClientMessages';



const { Header, Content } = Layout;
const { Text } = Typography;

const teamMembers = [
  { name: 'Alice Smith', color: '#f56a00' },
  { name: 'Bob Johnson', color: '#7265e6' },
  { name: 'Cathy Brown', color: '#ffbf00' },
];

const ClientChat = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [collapsed, setCollapsed] = useState({});
  const [isAllCollapsed, setIsAllCollapsed] = useState(false);

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      // Logic to send the message goes here
      console.log('New message sent:', newMessage);
      setNewMessage('');
    }
  };

  const handleCollapseToggle = (id) => {
    setCollapsed((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleCollapseExpandAll = () => {
    if (isAllCollapsed) {
      setCollapsed({});
    } else {
      const allCollapsed = messages.reduce((acc, msg) => ({ ...acc, [msg.id]: true }), {});
      setCollapsed(allCollapsed);
    }
    setIsAllCollapsed(!isAllCollapsed);
  };

  const menu = (
    <Menu>
      <Menu.Item key="move">Move Messages</Menu.Item>
      <Menu.Item key="delete">Delete Conversation</Menu.Item>
    </Menu>
  );

  const showModal = () => {
    // Logic to show modal for adding user
    console.log('Show modal for adding user');
  };

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f0f2f5' }}>

        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Clients</Breadcrumb.Item>
          <Breadcrumb.Item>Catalyst Marketing Group</Breadcrumb.Item>
          <Breadcrumb.Item>Chat</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 24px', backgroundColor: '#fff', boxShadow: '0 1px 4px rgba(0,21,41,.12)' }}>
          <Space size="small">
            {teamMembers.map((member, index) => (
              <Avatar
                key={index}
                style={{
                  backgroundColor: member.color,
                  verticalAlign: 'middle',
                  marginLeft: index === 0 ? 0 : -15, // More overlapping effect
                }}
              >
                {member.name.split(' ').map(part => part.charAt(0)).join('')}
              </Avatar>
            ))}
            <Button type="primary" icon={<UserAddOutlined />} onClick={showModal} />
          </Space>
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <Button onClick={handleCollapseExpandAll}>
              {isAllCollapsed ? <DownOutlined /> : <UpOutlined />}
              {isAllCollapsed ? 'Expand All' : 'Collapse All'}
            </Button>
            <Dropdown overlay={menu} trigger={['click']}>
              <EllipsisOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
            </Dropdown>
          </div>
        </div>
        <Content style={{ padding: '24px', backgroundColor: '#fff', boxShadow: '0 1px 4px rgba(0,21,41,.12)' }}>
          <List
            itemLayout="vertical"
            dataSource={messages}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <Card>
                  <List.Item.Meta
                    avatar={<Avatar>{item.avatar}</Avatar>}
                    title={
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <Text strong>{item.name}</Text>
                          <Text type="secondary" style={{ display: 'block' }}>{item.date}</Text>
                        </div>
                        <Button
                          type="text"
                          icon={collapsed[item.id] ? <DownOutlined /> : <UpOutlined />}
                          onClick={() => handleCollapseToggle(item.id)}
                        />
                      </div>
                    }
                    description={
                      <div>
                        {collapsed[item.id]
                          ? `${item.content.substring(0, 110)}...`
                          : item.content}
                        {item.file && !collapsed[item.id] && (
                          <div style={{ marginTop: 16 }}>
                            <Upload>
                              <Button icon={<FileTextOutlined />}>{item.file}</Button>
                            </Upload>
                          </div>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16, gap: '12px' }}>
                          <Tooltip title={`Viewed by: ${item.viewers || 'No views'}`}>
                            <EyeOutlined /> <Text>{item.viewers ? item.viewers.length : 0}</Text>
                          </Tooltip>
                          <Tooltip title={`Attachments: ${item.file ? item.file : 'No attachments'}`}>
                            <PaperClipOutlined /> <Text>{item.file ? 1 : 0}</Text>
                          </Tooltip>
                          <Dropdown overlay={menu} trigger={['click']}>
                            <EllipsisOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
                          </Dropdown>
                        </div>
                      </div>
                    }
                  />
                </Card>
              </List.Item>
            )}
          />
          <Upload style={{ marginTop: 24 }}>
            <Button icon={<UploadOutlined />}>Upload File</Button>
          </Upload>
          <div style={{ marginTop: '24px', paddingBottom: '50px', backgroundColor: '#fff', padding: '12px', boxShadow: '0 1px 4px rgba(0,21,41,.12)', borderRadius: '4px' }}>
            
            <Button
              type="primary"
              icon={<UploadOutlined />}
              onClick={handleSendMessage}
              style={{ display: 'block', marginTop: '10px' }}
            >
              Send
            </Button>
          </div>
        </Content>
      </Layout>

  );
};

export default ClientChat;
