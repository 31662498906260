// ClientsTableColumns/ContactInfoColumn.js

import { Dropdown, message } from 'antd';
import React, { useState } from 'react';

const RenderContactInfo = ({ record }) => {
  const [visible, setVisible] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    message.success({
      content: 'Copied!',
    });
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const renderContactInfo = (record) => (
    <div className="ct-more-dropdown-content">
      <div className="ct-more-dropdown-item">
        <i className="fi fi-rr-marker"></i>
        {record.address}, {record.city}, {record.country}
        <div className="clone-icon-container" onClick={() => copyToClipboard(`${record.address}, ${record.city}, ${record.country}`)}>
          <i className="fi fi-rr-clone"></i>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="ct-client-email">
        <i className="fi fi-rr-at"></i>
        {record.contact.email}
        <i className="fi fi-rr-clone" onClick={() => copyToClipboard(record.contact.email)}></i>
      </div>
      <div className="ct-client-phone">
        <i className="fi fi-rr-phone-flip"></i>
        {record.contact.phone}
        <i className="fi fi-rr-clone" onClick={() => copyToClipboard(record.contact.phone)}></i>
      </div>
      <div className="ct-client-phone">
        <i className="fi fi-rr-cursor"></i>
        {record.website}
        <i className="fi fi-rr-clone" onClick={() => copyToClipboard(record.website)}></i>
      </div>
      <Dropdown
        overlay={renderContactInfo(record)}
        trigger={['click']}
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <a className="ct-contact-info-more-button">More</a>
      </Dropdown>
    </div>
  );
};

const ContactInfoColumn = {
  title: 'Contact Info',
  key: 'contactInfo',
  className: 'ct-contact-info',
  render: (text, record) => <RenderContactInfo record={record} />,
};

export default ContactInfoColumn;
