import React from 'react';

const TeamOverviewTable = ({ member }) => {
  return (
    <div className="to-table-container">
      <span className="to-table-tasks-title"> Tasks: {member.assignedTasks}</span>
      <div className="to-grid-container">
        <div className="to-grid-item not-started">
          <span className="to-task-title">NOT STARTED</span>
          <span className="to-task-count">{member.tasks_not_started}</span>
        </div>
        <div className="to-grid-item in-progress">
          <span className="to-task-title">IN PROGRESS</span>
          <span className="to-task-count">{member.inProgressTasks}</span>
        </div>
        <div className="to-grid-item completed">
          <span className="to-task-title">COMPLETED</span>
          <span className="to-task-count">{member.completedTasks}</span>
        </div>
        <div className="to-grid-item overdue">
          <span className="to-task-title">OVERDUE</span>
          <span className="to-task-count">{member.overdueTasks}</span>
        </div>
      </div>
    </div>
  );
};

export default TeamOverviewTable;
