import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { appSubDir } from '../../../config';
import { hoursToString } from '../../../services/common.functions';
import { useSelector } from 'react-redux';
import { hasRole } from '../../../services/auth.service';

const menu = (clientId) => {
  return (
    <Menu>
      <Link to={appSubDir + "clients/" + clientId + "/hours-tracker"}>
        <Menu.Item>View</Menu.Item>
      </Link>
      <Menu.Item key="2">Settings</Menu.Item>

    </Menu>
  );
}

const ClientMainFolders = ({ company }) => {
  const user = useSelector((state) => state.auth.user);
  const isCLient = hasRole(user, 'client')
  return (
    <div className="ct-folders-container">
      <div className="ct-folder-card">
        <div className="ct-folder-content">





          <div className="ct-folder-header">
            <Link to={appSubDir + (isCLient ? 'projects' : `clients/${company.id}/projects`)} className="ct-folder-title-cell">
              <div className="ct-folder-title">Projects</div>
              <i className="fi fi-br-angle-small-right"></i>
            </Link>
            <div className="ct-folder-options-cell">
              <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                <Button className="ct-options-button" icon={<EllipsisOutlined />} />
              </Dropdown>
            </div>
          </div>
          <div className="ct-folder-image-container">
            <img src={appSubDir + "assets/images/Illustration-1.webp"} alt="Projects" className="ct-folder-image" />
          </div>

          <div className="ct-folder-details">
            <table className="ct-details-table">
              <tbody>
                <tr>
                  <td>Not Started:</td>
                  <td>In Progress:</td>
                  <td>Completed:</td>
                </tr>
                <tr>
                  <td className="ct-folder-project-value">{company.stats.projects.not_started}</td>
                  <td className="ct-folder-project-value">{company.stats.projects.in_progress}</td>
                  <td className="ct-folder-project-value">{company.stats.projects.completed}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>

      <div className="ct-folder-card">
        <div className="ct-folder-content">
          <div className="ct-folder-header">
            <Link to={appSubDir + (isCLient ? 'work/hours-tracker' : `clients/${company.id}/hours-tracker`)} className="ct-folder-title-cell">
              <div className="ct-folder-title">Hours Tracker</div>
              <i className="fi fi-br-angle-small-right"></i>
            </Link>
            <div className="ct-folder-options-cell">
              <Dropdown dropdownRender={() => menu(company.id)} trigger={['click']} placement="bottomRight">
                <Button className="ct-options-button" icon={<EllipsisOutlined />} />
              </Dropdown>
            </div>
          </div>
          <div className="ct-folder-image-container">
            <img src={appSubDir+"assets/images/Illustration-2.webp"} alt="Hours Tracker" className="ct-folder-image" />
          </div>


          <div className="ct-folder-details">
            <table className="ct-details-table">
              <tbody>
                <tr>
                  <td>Total hrs:</td>
                  <td>Used hrs:</td>
                  <td>Left hrs:</td>
                </tr>
                <tr>
                  <td className="ct-folder-project-value">{hoursToString(company.stats.hours_tracker.total_hours)}</td>
                  <td className="ct-folder-project-value">{hoursToString(company.stats.hours_tracker.used_hours)}</td>
                  <td className="ct-folder-project-value">{hoursToString(company.stats.hours_tracker.left_hours)}</td>
                </tr>
              </tbody>
            </table>
          </div>



        </div>

      </div>




      <div className="ct-folder-card">
        <div className="ct-folder-content">
          <div className="ct-folder-header">
            <Link to={appSubDir + (isCLient ? 'automations' : `clients/${company.id}/automations`)} className="ct-folder-title-cell">
              <div className="ct-folder-title">Automations</div>
              <i className="fi fi-br-angle-small-right"></i>
            </Link>
            <div className="ct-folder-options-cell">
              <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                <Button className="ct-options-button" icon={<EllipsisOutlined />} />
              </Dropdown>
            </div>
          </div>
          <div className="ct-folder-image-container">
            <img src={appSubDir+"assets/images/Illustration-3.webp"} alt="Automations" className="ct-folder-image" />
          </div>



          <div className="ct-folder-details">
            <table className="ct-details-table">
              <tbody>
                <tr>
                  <td>Active:</td>
                  <td>Inactive:</td>

                </tr>
                <tr>
                  <td className="ct-folder-project-value">{company.stats.automations.active}</td>
                  <td className="ct-folder-project-value">{company.stats.automations.inactive}</td>

                </tr>
              </tbody>
            </table>
          </div>




        </div>

      </div>
    </div>




  );
};

export default ClientMainFolders;
