import React, { useEffect,useRef } from 'react';
import CompanyDetails from './CompanyDetails'; // Import the new CompanyDetails component
import PersonalDetails from './PersonalDetails'; // Import the new PersonalDetails component
import './AccountPages.less';
import { useSelector } from 'react-redux';
import { appSubDir } from '../../config';
import { CompanyProfileProvider, useCompanyProfileContext } from '../../Context/CompanyProfileContext';
import { fetchCompanyProfile, getBannerUrl, getLogoUrl, uploadBanner, uploadLogo } from '../../services/company.service';

const MainComponent = () => {
  const user = useSelector((state) => state.auth.user);
  const isClient = user && user.roles.length > 0 && user.roles[0].name === "client"
  const isUploading = useRef(false)
  const fileRef = useRef(null)
  const {
    company, setCompany,
    isModalOpen, setIsModalOpen,
    loading, setLoading
  } = useCompanyProfileContext();
  // Page Data Loader
  function loadData() {
    if (!isClient) {
      return null
    }
    setLoading(true);
    fetchCompanyProfile().then((res) => {
      setCompany(res.data);
    }).finally(() => {
      setLoading(false);
    });
  }
  // Fetch clients from the backend
  useEffect(() => {
    const timer = setTimeout(() => {
      loadData()
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, []);


  function onFIleSelect(event) {
    if (isUploading.current) {
      return
    }
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData()
      formData.append('banner', file)
      isUploading.current = true
      uploadBanner(formData).then((res) => {
        company.banner = res.data
        setCompany({ ...company })
      }).finally(() => {
        isUploading.current = false
      })
    }
  };
  return (
    <div className="ap-profile-container">
      <div className="ap-profile-banner" style={{
        backgroundImage: isClient && company.banner ? `url('${getBannerUrl(company.banner)}')` : `url('${appSubDir}assets/images/profile-banner.webp')`
      }}>
        {isClient ?<>
          <input ref={fileRef} type="file" onChange={onFIleSelect} style={{ display: 'none' }}/>
          <button onClick={()=>fileRef.current?.click()} className="ap-update-banner-button"> <i class="fi fi-rr-pencil"></i> Edit Banner</button>
        </>:null}
      </div>

      <div className="ap-combined-details-container">

        <CompanyDetails />
        <PersonalDetails />
      </div>
    </div>
  );
};
const AccountProfile = () => {
  return (
    <CompanyProfileProvider>
      <MainComponent />
    </CompanyProfileProvider>
  )
}

export default AccountProfile;
