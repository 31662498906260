import React, { useState } from 'react';
import { Modal, Tabs, List, Button, Input, Form, message, Select, Spin, Row, Col } from 'antd';
import SingleAvatar2 from '../../../Components/SingleAvatar2';
import '../../../Components/UserAvatars.less';
import AddMemberButton from '../../../Components/AddMemberButton';
import { addMember, removeMember } from '../../../services/project.service';
import { useProjectsContext } from '../../../Context/ProjectsContext';
import { memberAutocomplete } from '../../../services/member.service';
import { Link } from 'react-router-dom';
import { appSubDir } from '../../../config';
import { useSelector } from 'react-redux';
import { hasRole } from '../../../services/auth.service';

const { Option } = Select;

const MemberPicker = ({ placeholder, onChange }) => {
  const [options, setOptions] = useState([]);
  const [fetching, setFetching] = useState(false);


  const handleSearch = (searchValue) => {
    if (searchValue.length >= 2) {
      setFetching(true);
      memberAutocomplete(searchValue)
        .then((response) => {
          setOptions(response.data);
          setFetching(false);
        })
        .catch(() => {
          setFetching(false);
        });
    }
  };

  const handleChange = (newValue) => {
    const newSelected = options.find(option => option.id.toString() === newValue) || null;
    setOptions([])
    onChange(newSelected)
  };

  return (
    <Select
      style={{ width: "100%" }}
      showSearch
      placeholder={placeholder}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      optionLabelProp="label"
    >
      {options.map(option => (
        <Option key={option.id} value={option.id.toString()} label={option.f_name + " " + option.l_name}>
          <Row gutter={5} align="middle">
            <Col span={2}><SingleAvatar2 name={option.f_name + " " + option.l_name} /></Col>
            <Col span={5}>{option.f_name + " " + option.l_name}</Col>
          </Row>

        </Option>
      ))}
    </Select>
  );
};

const { TabPane } = Tabs;
const MemberComponent = ({ project, member }) => {
  const user = useSelector((state) => state.auth.user);
  const isSuperAdmin = hasRole(user, 'super-admin')
  const {
    projects, setProjects
  } = useProjectsContext();
  const [disabled, setDisabled] = useState(false)
  function onRemove() {
    setDisabled(true)
    removeMember(project.id, member.user_id).then(() => {
      const projectIndex = projects.findIndex((item) => item.id === project.id)
      const memberIndex = projects[projectIndex].members.findIndex((item) => item.user_id === member.user_id)
      projects[projectIndex].members.splice(memberIndex, 1)
      setProjects([...projects])
      message.success("Team Member Removal Successful")
    }).catch((err) => {
      console.log("toggleAction failed", err)
      message.error("Team Member Removal Failed")
    }).finally(() => {
      setDisabled(false)
    })
  }
  return (
    <List.Item actions={isSuperAdmin ? [<Button disabled={disabled} onClick={onRemove} className="remove-button" type="link" danger key="remove">Remove</Button>] : undefined}>
      <List.Item.Meta
        avatar={<SingleAvatar2 name={member.user.f_name + " " + member.user.l_name} />}
        title={member.user.f_name + " " + member.user.l_name}
        description={member.member.job_title}
      />
    </List.Item>
  )
}
const MemberAddComponent = ({ project }) => {
  const {
    projects, setProjects
  } = useProjectsContext();
  function onTeamMemberAdd(newSelectedMember) {
    addMember(project.id, newSelectedMember.id).then((res) => {
      const projectIndex = projects.findIndex((item) => item.id === project.id)
      projects[projectIndex] = { ...res.data }
      setProjects([...projects])
      message.success("Team Member Added")
    }).catch((err) => {
      console.log("onTeamMemberAdd failed", err)
      message.error("Team Member Add Failed")
    })
  }
  return (
    <MemberPicker placeholder={"Search Team Member To Add"} onChange={onTeamMemberAdd} />
  )
}

const TeamMembersModal = ({ project }) => {
  const user = useSelector((state) => state.auth.user);
  const isSuperAdmin = hasRole(user, 'super-admin')
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [form] = Form.useForm();


  const toggleModal = () => {
    setIsVisible(!isVisible);
  };
  const handleAddUser = () => {
    setAddUserVisible(!addUserVisible);
  };

  const handleFormSubmit = (values) => {
    // console.log('Form values:', values);
    // Logic to send invite
    form.resetFields();
    setAddUserVisible(false);
  };

  return (
    <>
      <AddMemberButton project={project} handleAddMember={toggleModal} />
      <Modal
        title={
          <div className="modal-title-container">
            <div className="modal-icon-container">
              <i className="fi fi-rr-team-check modal-icon"></i>
            </div>
            {`Project : ${project ? project.name : ''}`}
          </div>
        }
        visible={isVisible}
        onCancel={toggleModal}
        footer={false}
      // footer={[
      //   <Button type="secondary" key="back" onClick={toggleModal}>
      //     Cancel
      //   </Button>,
      //   <Button key="submit" type="primary" onClick={toggleModal}>
      //     Save
      //   </Button>,
      // ]}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Team" key="1">
            {isSuperAdmin && <>
              <div className="add-user-bar">
                <div className="add-user-search-bar">
                  <MemberAddComponent project={project} />
                </div>
                <Button type="link" onClick={handleAddUser}>
                  <i className="fi fi-rr-user-add" style={{ marginRight: '5px' }}></i>
                  Add people
                  {addUserVisible ? (
                    <i className="fi fi-rr-angle-small-up"></i>
                  ) : (
                    <i className="fi fi-rr-angle-small-down" ></i>
                  )}
                </Button>
              </div>
              {addUserVisible && (
                <Form form={form} layout="vertical" onFinish={handleFormSubmit} className="invite-form">
                  <div className="form-row">
                    <Form.Item name="name" rules={[{ required: true, message: 'Please enter the name' }]} className="form-item">
                      <Input placeholder="Name" />
                    </Form.Item>
                    <Form.Item name="email" rules={[{ required: true, message: 'Please enter the email' }]} className="form-item">
                      <Input placeholder="Email" />
                    </Form.Item>
                  </div>
                  <Form.Item className="form-item-submit">
                    <Button type="primary" htmlType="submit">Send Invite</Button>
                  </Form.Item>
                </Form>
              )}
            </>}
            <List
              itemLayout="horizontal"
              dataSource={project.members}
              renderItem={(member, index) => (<MemberComponent project={project} member={member} />)}
            />
          </TabPane>
          <TabPane tab="Client" key="2">
            {/* <div className="add-user-bar">
            <Button type="link" onClick={handleAddUser} style={{ marginLeft: '10px' }}>
              <i className="fi fi-rr-user-add" style={{ marginRight: '5px' }}></i>
              Add people
              {addUserVisible ? (
                <i className="fi fi-rr-angle-small-up" style={{ marginLeft: '5px' }}></i>
              ) : (
                <i className="fi fi-rr-angle-small-down" style={{ marginLeft: '5px' }}></i>
              )}
            </Button>
          </div> */}
            {addUserVisible && (
              <Form form={form} layout="vertical" onFinish={handleFormSubmit} className="invite-form">
                <div className="form-row">
                  <Form.Item name="name" rules={[{ required: true, message: 'Please enter the name' }]} className="form-item">
                    <Input placeholder="Name" />
                  </Form.Item>
                  <Form.Item name="email" rules={[{ required: true, message: 'Please enter the email' }]} className="form-item">
                    <Input placeholder="Email" />
                  </Form.Item>
                </div>
                <Form.Item className="form-item-submit">
                  <Button type="primary" htmlType="submit">Send Invite</Button>
                </Form.Item>
              </Form>
            )}
            <List
              itemLayout="horizontal"
              dataSource={[project.client]}
              renderItem={client => (
                <List.Item actions={isSuperAdmin ? [<Link to={appSubDir + `clients/${client.id}/profile`} className="remove-button">View Profile</Link>] : undefined}>
                  <List.Item.Meta
                    avatar={<SingleAvatar2 name={client.contact.f_name + " " + client.contact.l_name} />}
                    title={client.contact.f_name + " " + client.contact.l_name}
                    description={client.company_name}
                  />
                </List.Item>
              )}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default TeamMembersModal;
