const sampleNotifications = [
  {
    type: 'Task Created',
    notificationName: 'Mayowa Created a new task for project: Apple Website Redesign',
    time: '23 mins ago'
  },
  {
    type: 'Task Completed',
    notificationName: 'John Completed a task for project: Coca Cola Website Redesign',
    time: '3 hours ago'
  },
  {
    type: 'Project Created',
    notificationName: 'Anna Created a new project: Microsoft Cloud Migration',
    time: '5 hours ago'
  },
  {
    type: 'Project Completed',
    notificationName: 'Emily Completed the project: Tesla App Development',
    time: '7 hours ago'
  },
  {
    type: 'Task Created',
    notificationName: 'Chris Created a new task for project: Facebook Ad Campaign',
    time: '8 hours ago'
  },
  {
    type: 'Payment Made',
    notificationName: 'Alex made a payment for project: Amazon Logistics Optimization',
    time: '10 hours ago'
  },
  {
    type: 'Hours Low',
    notificationName: 'Prestige Digital has less than 5 hours left in their hours bundle',
    time: '12 hours ago'
  },
  {
    type: 'Task Completed',
    notificationName: 'Sarah Completed a task for project: Google Analytics Integration',
    time: '14 hours ago'
  },
  {
    type: 'Project Created',
    notificationName: 'David Created a new project: Netflix User Experience Improvement',
    time: '16 hours ago'
  },
  {
    type: 'Hours Finished',
    notificationName: 'Prestige Digital has ran out of hours',
    time: '18 hours ago'
  },
  {
    type: 'Project Completed',
    notificationName: 'Nina Completed the project: Spotify Playlist Optimization',
    time: '20 hours ago'
  },
  {
    type: 'Task Created',
    notificationName: 'Mayowa Created a new task for project: Apple Website Redesign',
    time: '22 hours ago'
  },
  {
    type: 'Hours Low',
    notificationName: 'Prestige Digital has less than 5 hours left in their hours bundle',
    time: '1 day ago'
  },
  {
    type: 'Task Completed',
    notificationName: 'John Completed a task for project: Coca Cola Website Redesign',
    time: '1 day ago'
  },
  {
    type: 'Project Created',
    notificationName: 'Anna Created a new project: Microsoft Cloud Migration',
    time: '1 day ago'
  },
  {
    type: 'Project Completed',
    notificationName: 'Emily Completed the project: Tesla App Development',
    time: '1 day ago'
  },
  {
    type: 'Task Created',
    notificationName: 'Chris Created a new task for project: Facebook Ad Campaign',
    time: '1 day ago'
  },
  {
    type: 'Payment Made',
    notificationName: 'Alex made a payment for project: Amazon Logistics Optimization',
    time: '2 days ago'
  },
  {
    type: 'Task Completed',
    notificationName: 'Sarah Completed a task for project: Google Analytics Integration',
    time: '2 days ago'
  },
  {
    type: 'Project Created',
    notificationName: 'David Created a new project: Netflix User Experience Improvement',
    time: '2 days ago'
  }
];

export default sampleNotifications;
