import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, message, Space, Row, Col } from 'antd';
import CompanyPicker from '../../../Components/CompanyPicker';
import { createSubscription, updateSubscription } from '../../../services/subscription.service';
import DefaultModal from '../../../Modals/DefaultModal';

const { TextArea } = Input;
const { Option } = Select;

const FormModal = ({ isVisible, setIsVisible, onSuccess, initialValues }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const handleSubmit = (values) => {
    setLoading(true);
    const api = initialValues
      ? (data) => updateSubscription(initialValues.id, data)
      : (data) => createSubscription(data)

    api(values)
      .then((res) => {
        message.success(initialValues ? 'Your Subscription has been updated!' : 'Your Subscription has been created!');
        form.resetFields();
        handleCancel();
        onSuccess && onSuccess(res.data);
      })
      .catch((error) => {
        if (!error.response) {
          message.error('Network error or server is unreachable');
          return;
        }
        if (error.response.status === 422) {
          const validationErrors = error.response.data.errors;
          const errorFields = Object.keys(validationErrors).map((key) => ({
            name: key,
            errors: validationErrors[key],
          }));
          form.setFields(errorFields);
        } else {
          message.error(error.response.data.message || 'An unexpected error occurred');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const footerContent = [
    <Button className="modal-cancel-button" key="back" onClick={handleCancel}>
      Cancel
    </Button>,
    <Button key="submit" loading={loading} className="modal-save-button" type="primary" onClick={() => form.submit()}>
      {initialValues ? 'Update' : 'Create'}
    </Button>,
  ];

  return (
    <DefaultModal
      isVisible={isVisible}
      toggleModal={handleCancel}
      title={initialValues ? 'Edit Subscription' : 'Create New Subscription'}
      icon="code-pull-request"
      footerContent={footerContent}
      className="request-automation-modal"
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit} className="main-modal-form request-automation-form">
        <CompanyPicker form={form} initialOption={initialValues ? initialValues.client : undefined} rules={[{ required: true, message: 'Please enter a company name' }]} />
        <Form.Item name="title" label="Subscription Title" rules={[{ required: true, message: 'Please enter the automation title' }]}>
          <Input placeholder="Enter automation title" />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="description" label="Subscription Description" rules={[{ required: true, message: 'Please enter the automation description' }]}>
              <TextArea placeholder="Enter automation description" rows={4} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="notes" label="Additional Notes">
              <TextArea placeholder="Enter any additional notes" rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="priority_level" label="Priority Level" rules={[{ required: true, message: 'Please select the priority level' }]}>
              <Select placeholder="Select priority level">
                <Option value="low">Low</Option>
                <Option value="medium">Medium</Option>
                <Option value="high">High</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DefaultModal>
  );
};

export default FormModal;
