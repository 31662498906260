import React from 'react';
import { Select } from 'antd';
import SingleAvatar2 from '../../../Components/SingleAvatar2';
import { updateClient } from '../../../services/client.service';
import { useClientsContext } from '../../../Context/ClientContext';

const { Option } = Select;
const Compoenent = ({ record }) => {
  const { teamMembers } = useClientsContext()
  const handleManagerChange = (value) => {
    // Implement the logic to update the account manager in your data source
    updateClient(record.id,{manager_id:value})
    .catch((err) => {
      console.log("handleManagerChange Failed", err)
    })
  };

  const renderManagerOption = (manager) => (
    <div className="ct-account-manager-option">
      <SingleAvatar2 name={manager} />
      <span className="ct-account-manager-name">{manager}</span>
    </div>
  );

  return (
    <Select
      placeholder="Select Manager"
      style={{ minWidth: 150 }}
      defaultValue={record.manager_id}
      onChange={handleManagerChange}
      className="ct-account-manager-select"
      dropdownClassName="ct-account-manager-select-dropdown"
      optionLabelProp="label"
      allowClear
    >
      {teamMembers.map((manager, index) => (
        <Option key={index} value={manager.id} label={renderManagerOption(manager.name)}>
          {renderManagerOption(manager.name)}
        </Option>
      ))}
    </Select>
  );
}
const AccountManagerColumn = {
  title: 'Manager',
  key: 'accountManager',
  className: 'ct-account-manager',
  render: (text, record) => {
    return <Compoenent record={record} />
  },
};

export default AccountManagerColumn;
