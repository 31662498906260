// AvatarColors.js

export const lightColors = [
  '#fef2f2', '#fee2e2', '#fecaca', '#fca5a5', '#fde7f3',
  '#ffedd5', '#ffedd5', '#fef3c7', '#fef9c3', '#fef9c3',
  '#ecfccb', '#dcfce7', '#d1fae5', '#cffafe', '#e0f2fe',
  '#e0f2fe', '#cfe8fe', '#a5f3fc', '#e0e7ff', '#dbeafe',
  '#e5e7eb', '#f5f3ff', '#eef2ff', '#e0f2fe', '#e0e7ff',
  '#f3f4f6', '#dbeafe', '#e0e7ff', '#f3f4f6', '#e5e7eb',
  '#f5f3ff', '#f8fafc', '#f3f4f6', '#f5f3ff', '#eef2ff',
  '#e0f2fe', '#e0f2fe', '#cfe8fe', '#a5f3fc', '#e0e7ff',
  '#f87171', '#fecaca', '#fee2e2', '#fef2f2', '#fdba74',
'#fed7aa', '#fee8d8', '#fff7ed', '#facc15', '#fde047',
'#fef08a', '#fef9c3', '#86efac', '#bbf7d0', '#dcfce7',
'#ecfdf5', '#4ade80', '#22c55e', '#10b981', '#d9f99d',
'#bef264', '#a3e635', '#f97316', '#fb923c', '#f97316',
'#c084fc', '#d8b4fe', '#e9d5ff', '#ede9fe', '#f3e8ff',
'#fdf2ff', '#fce7f3', '#fbcfe8', '#fae8ff', '#fae8ff',
'#fdf2ff', '#fae8ff', '#f8b4fa', '#f0abfc', '#f5d0fe',
'#fae8ff', '#fae8ff', '#e0f2fe', '#bae6fd', '#7dd3fc'
];

export const darkColors = [
  '#f87171', '#ef4444', '#dc2626', '#b91c1c', '#991b1b',
  '#f97316', '#ea580c', '#c2410c', '#9a3412', '#7c2d12',
  '#eab308', '#ca8a04', '#a16207', '#854d0e', '#713f12',
  '#84cc16', '#65a30d', '#4d7c0f', '#3f6212', '#365314',
  '#22c55e', '#16a34a', '#15803d', '#166534', '#14532d',
  '#0d9488', '#0f766e', '#115e59', '#134e4a', '#164e63',
  '#1e40af', '#1d4ed8', '#1e40af', '#1e40af', '#1e3a8a',
  '#1e40af', '#1d4ed8', '#1e3a8a', '#1d4ed8', '#1e40af',
  '#dc2626', '#ef4444', '#f87171', '#fca5a5', '#991b1b',
 '#b91c1c', '#be185d', '#db2777', '#c026d3', '#a21caf',
 '#86198f', '#701a75', '#713f12', '#854d0e', '#92400e',
 '#f59e0b', '#eab308', '#ca8a04', '#d97706', '#b45309',
 '#facc15', '#eab308', '#fbbf24', '#eab308', '#f59e0b',

];

// Hash function to map names to color indices
export const hashStringToIndex = (str, arrayLength) => {
  let hash = 0;
  for (let i = 0; str && i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash; // Convert to 32bit integer
  }
  return Math.abs(hash) % arrayLength; // Ensure the index is within the array length
};

// Function to get the color for a given name
export const getAvatarColor = (name) => {
  const lightIndex = hashStringToIndex(name, lightColors.length);
  const darkIndex = hashStringToIndex(name, darkColors.length);
  const useDarkColor = lightIndex % 2 === 0; // Alternate between light and dark colors
  return useDarkColor ? darkColors[darkIndex] : lightColors[lightIndex];
};
