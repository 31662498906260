import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripeKey } from '../config';

const stripePromise = loadStripe(stripeKey);

function StripeProvider({ children }) {

    return (
        <Elements stripe={stripePromise}>
            { children }
        </Elements>
    );
}

export default StripeProvider;
