import React, { useState, useEffect } from 'react';
import { Input } from 'antd';

const formatTimeSpent = (value) => {
  if (!value) return '0h 0m';

  const parts = value.split(/[:hHmM]+/);
  let hours = parts.length > 1 ? parseInt(parts[0], 10) : 0;
  let minutes = parts.length > 1 ? parseInt(parts[1], 10) : parseInt(parts[0], 10);

  if (isNaN(hours)) hours = 0;
  if (isNaN(minutes)) minutes = 0;

  // Convert minutes over 60 to hours
  hours += Math.floor(minutes / 60);
  minutes = minutes % 60;

  return `${hours}h ${minutes}m`;
};

const parseInput = (value) => {
  let numbers = value.replace(/\D/g, ''); // Remove non-digit characters
  if (numbers.length > 4) numbers = numbers.slice(-4); // Keep only the last 4 digits

  let minutes = numbers.slice(-2);
  let hours = numbers.slice(0, -2) || '0';

  return `${hours}:${minutes.padStart(2, '0')}`;
};

const EditableTimeSpentCell = ({ value: initialValue, row, column, updateData }) => {
  const [rawValue, setRawValue] = useState('');
  const [displayValue, setDisplayValue] = useState('00:00');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setRawValue(initialValue.replace(/\D/g, ''));
    setDisplayValue(formatTimeSpent(initialValue));
  }, [initialValue]);

  const onChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    setRawValue(value);
    setDisplayValue(parseInput(value));
  };

  const onFocus = () => {
    setIsEditing(true);
    setDisplayValue(parseInput(rawValue));
  };

  const onBlur = () => {
    setIsEditing(false);
    updateData && updateData(row.index, column.id, formatTimeSpent(displayValue));
    setDisplayValue(formatTimeSpent(displayValue));
  };

  return (
    <Input
      value={isEditing ? displayValue : formatTimeSpent(displayValue)}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      placeholder="00:00"
    />
  );
};

export default EditableTimeSpentCell;
