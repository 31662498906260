// RangeSelector.js

import React from 'react';
import { Select } from 'antd';
const { Option } = Select;

const RangeSelector = ({ selectedRange, setSelectedRange }) => {

  const handleRangeChange = (range) => {
    setSelectedRange(range);
  };

  return (
    <div className="db-date-range-selector">
    <div className="db-chart-calendar-icon-container">
    <i class="fi fi-rr-calendar"></i>
    </div>
      <Select value={selectedRange} onChange={handleRangeChange} style={{ width: 106 }}>
        <Option value={7}>7 Days</Option>
        <Option value={30}>1 Month</Option>
        <Option value={60}>2 Months</Option>
        <Option value={90}>3 Months</Option>
        <Option value={180}>6 Months</Option>
        <Option value={365}>1 Year</Option>
      </Select>
    </div>
  );
};

export default RangeSelector;
