import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Spin } from 'antd';
import RangeSelector from './RangeSelector';
import ChartFilter from './ChartFilter';
import { formatXAxis, getTickValues } from './XAxisFormatter';
import { fetchChartData } from '../../services/dashboard.service';
import moment from 'moment';
import { parseFloatFromStr } from '../../services/common.functions';

const DashboardChart = () => {
  const [selectedRange, setSelectedRange] = useState(7);
  const [data, setData] = useState([]);
  const [selectedValues, setSelectedValues] = useState(['tasks', 'activeClients', 'hoursWorked', 'hoursBilled']);
  const [isLoading, setIsLoading] = useState(false);
  const ticks = getTickValues(data, selectedRange)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(true); // Activate the spinner
      fetchChartData(selectedRange).then((response) => {
        const formatedItems = response.data.map(element => {
          element.hoursBilled = parseFloatFromStr(element.hoursBilled)
          element.hoursWorked = parseFloatFromStr(element.hoursWorked)
          return element
        }).sort((a, b) => a.date.localeCompare(b.date))
        setData(formatedItems);
      }).catch(error => {
        console.error("Fetch Dashboad Data Failed", error);
      }).finally(() => {
        setIsLoading(false);
      })
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [selectedRange]);

  return (
    <div>
      <Spin spinning={isLoading} size="large">
        <div className="db-chart-topbar">

          <div className="db-chart-topbar-title">
            Performance Chart

          </div>

          <RangeSelector
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
          />
        </div>
        <div className="db-chart">
          <ResponsiveContainer width="100%" height={360}>

            <LineChart data={data}>
              <XAxis dataKey="date" tickFormatter={formatXAxis} ticks={ticks} />
              <YAxis />
              <CartesianGrid stroke="#ddd" strokeOpacity={0.5} strokeDasharray="7 3" />
              <Tooltip labelFormatter={(date) => moment(date).format('DD MMM YY')}/>

              {selectedValues.includes('tasks') && (
                <Line
                  name='Tasks'
                  type="monotone"
                  dataKey="tasks"
                  stroke="#F3B743"
                  strokeWidth={1.25}
                  dot={false}
                />
              )}
              {selectedValues.includes('activeClients') && (
                <Line
                  name='Clients'
                  type="monotone"
                  dataKey="clients"
                  stroke="#4DA957"
                  strokeWidth={1.25}
                  dot={false}
                />
              )}
              {selectedValues.includes('hoursWorked') && (
                <Line
                  name='Hours Worked'
                  type="monotone"
                  dataKey="hoursWorked"
                  stroke="#3A4B90"
                  strokeWidth={1.25}
                  dot={false}
                />
              )}
              {selectedValues.includes('hoursBilled') && (
                <Line
                  name="Hours Billed"
                  type="monotone"
                  dataKey="hoursBilled"
                  stroke="#42ABDF"
                  strokeWidth={1.25}
                  dot={false}
                />
              )}
            </LineChart>

          </ResponsiveContainer>

          <div className="db-chart-filters-container">
            <ChartFilter selectedValues={selectedValues} setSelectedValues={setSelectedValues} />
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default DashboardChart;
