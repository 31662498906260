import React, { createContext, useState, useContext } from 'react';
const TeamMembersContext = createContext();

export const TeamMembersProvider = ({ children }) => {
  const [sortByColumns, setSortByColumns] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);


  return (
    <TeamMembersContext.Provider
      value={{
        currentPage, setCurrentPage,
        selectedTeam, setSelectedTeam,
        teamMembers, setTeamMembers,
        loading, setLoading,
        totalRows, setTotalRows,
        sortByColumns, setSortByColumns,
        rowsPerPage, setRowsPerPage
      }}
    >
      {children}
    </TeamMembersContext.Provider>
  );
};

export const useTeamMembersContext = () => {
  return useContext(TeamMembersContext)
}
