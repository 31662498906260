import React, { useState } from 'react';
import { Menu, Layout, Button, Avatar, Dropdown, Input, Badge, Popover } from 'antd';
import { SearchOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons';
import { NavLink, useLocation } from 'react-router-dom';
import '../custom.less'; // Ensure custom.less is imported
import Searchbar from './Searchbar';
import Sidebar from './Sidebar';

const { Header, Sider, Content } = Layout;
const { Search } = Input;

const UnifiedLayout = ({ children }) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [visible, setVisible] = useState({ notification: false, profile: false });
  const location = useLocation();

  const handleToggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const handleVisibleChange = (name, isVisible) => {
    setVisible((prevVisible) => ({ ...prevVisible, [name]: isVisible }));
  };

  const profileMenu = (
    <Menu className="profile-menu">
      <div className="profile-avatar">
        <Avatar size={64} className="avatar-profile-initials">
          NH
        </Avatar>
        <div className="profile-info">
          <div className="profile-name">Noman Hossain</div>
          <div className="profile-company">BlueFlower Media</div>
        </div>
      </div>
      <Menu.Divider />
      <Menu.Item key="profile">
        <span>Profile</span>
      </Menu.Item>
      <Menu.Item key="settings">
        <span>Settings</span>
      </Menu.Item>
      <Menu.Item key="invite">
        <span>Invite Team Member</span>
      </Menu.Item>
      <Menu.Item key="help">
        <span>Help</span>
      </Menu.Item>
      <Menu.Item key="logout">
        <span>Log Out</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar isSidebarVisible={isSidebarVisible} />
      <Layout style={{ marginLeft: isSidebarVisible ? 232 : 0, transition: 'margin-left 0.3s' }}>
        <Header style={{ background: '#fff', padding: 0, display: 'flex', alignItems: 'center' }}>
          <Searchbar handleToggleSidebar={handleToggleSidebar} />
        </Header>
        <Content >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default UnifiedLayout;
