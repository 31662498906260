import React, { useState, useEffect } from 'react';
import { Layout, Card, Statistic, Row, Col, Button, Pagination, Menu, Dropdown, Breadcrumb, Avatar } from 'antd';
import { DownOutlined, EllipsisOutlined } from '@ant-design/icons';
import EmployerJobsData from '../../Data/EmployerJobsData';

const { Header, Content } = Layout;

const Jobs = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = EmployerJobsData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const filterMenu = (
    <Menu>
      <Menu.Item key="1">Interviews</Menu.Item>
      <Menu.Item key="2">Shortlisted</Menu.Item>
      <Menu.Item key="3">Hired</Menu.Item>
    </Menu>
  );

  const jobMenu = (
    <Menu>
      <Menu.Item key="1">View Job</Menu.Item>
      <Menu.Item key="2">Edit Job</Menu.Item>
      <Menu.Item key="3">Settings</Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f0f2f5' }}>

        <Content style={{ padding: '24px 0' }}>
          <Breadcrumb style={{ marginBottom: '16px' }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>HR</Breadcrumb.Item>
            <Breadcrumb.Item>Jobs</Breadcrumb.Item>
          </Breadcrumb>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginBottom: '20px', marginRight: 'auto', display: 'flex', alignItems: 'center' }}>
              <Dropdown overlay={filterMenu} trigger={['click']}>
                <Button>
                  Filter <DownOutlined />
                </Button>
              </Dropdown>
              <Button type="primary" style={{ marginLeft: '16px' }}>Add Job</Button>
            </div>
          </div>
          <Row gutter={[16, 16]}>
            {paginatedData.map((job, index) => (
              <Col xs={24} sm={12} lg={8} key={index}>
                <Card
                  title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar style={{ backgroundColor: '#EED9FC', marginRight: 8 }}>
                        <i className="fi fi-rr-briefcase" style={{ color: '#000000' }}></i>
                      </Avatar>
                      {job.title}
                    </div>
                  }
                  extra={
                    <Dropdown overlay={jobMenu} trigger={['click']}>
                      <EllipsisOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
                    </Dropdown>
                  }
                  style={{ height: '100%' }}
                >
                  <p>{job.date}</p>
                  <p>{job.description}</p>
                  <Button type="default" style={{ marginBottom: '16px' }} icon={<i className="fi fi-rr-test"></i>}>
                    Sample Test
                  </Button>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Statistic title={<><i className="fi fi-rr-queue-alt" style={{ marginRight: '8px' }}></i>Candidates</>} value={job.total_candidates} />
                    </Col>
                    <Col span={12}>
                      <Statistic title={<><i className="fi fi-rr-meeting" style={{ marginRight: '8px' }}></i>Interviews</>} value={job.candidates_interviewed} />
                    </Col>
                    <Col span={12}>
                      <Statistic title={<><i className="fi fi-rr-review" style={{ marginRight: '8px' }}></i>Shortlisted</>} value={job.candidates_shortlisted} />
                    </Col>
                    <Col span={12}>
                      <Statistic title={<><i className="fi fi-rr-handshake-angle" style={{ marginRight: '8px' }}></i>Hired</>} value={job.candidates_hired} />
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={EmployerJobsData.length}
            onChange={handlePageChange}
            style={{ marginTop: '24px', textAlign: 'center' }}
          />
        </Content>
      </Layout>

  );
};

export default Jobs;
