import React from 'react';
import { Avatar, Tooltip } from 'antd';
import { lightColors, darkColors, hashStringToIndex } from './AvatarColors';
import './UserAvatars.less';

// Function to get two random colors for a given name based on hash
const getAvatarColors = (name) => {
  const isDark = hashStringToIndex(name, 2) % 2 === 0; // Alternate between light and dark colors
  const colors = isDark ? darkColors : lightColors;

  const color1Index = hashStringToIndex(name + '1', colors.length);
  const color2Index = hashStringToIndex(name + '2', colors.length);

  const color1 = colors[color1Index];
  const color2 = colors[color2Index];

  const isLightColor = lightColors.includes(color1) && lightColors.includes(color2);

  return { color1, color2, isLightColor };
};

// UserAvatar component
const UserAvatar = ({ name, size = '34', fontSize = '14' }) => {
  if (!name) return null;

  const { color1, color2, isLightColor } = getAvatarColors(name);
  const initials = name.split(' ').map(n => n[0]).join('').slice(0, 2).toUpperCase();

  const gradientStyle = {
    background: `linear-gradient(135deg, ${color1}, ${color2})`,
    color: isLightColor ? '#000' : '#FFF',
    width: `${size}px`,
    height: `${size}px`,
    fontSize: `${fontSize}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  return (
    <Tooltip title={name}>
      <Avatar style={gradientStyle}>
        {initials}
      </Avatar>
    </Tooltip>
  );
};

// Custom AvatarGroup component
const CustomAvatarGroup = ({ members, maxCount = 5, size = '34', fontSize = '14' }) => {
  const displayedMembers = members.filter(member => member && member.name).slice(0, maxCount - 1);
  const hiddenMembers = members.filter(member => member && member.name).slice(maxCount - 1);
  const hiddenCount = hiddenMembers.length;

  return (
    <div className="avatar-group">
      {displayedMembers.map((member, index) => (
        <UserAvatar key={index} name={member.name} size={size} fontSize={fontSize} />
      ))}
      {hiddenCount > 0 && (
        <Tooltip title={hiddenMembers.map(member => member.name).join(', ')}>
          <Avatar
            style={{
              backgroundColor: '#fde3cf',
              color: '#f56a00',
              width: `${size}px`,
              height: `${size}px`,
              fontSize: `${fontSize}px`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {`+${hiddenCount}`}
          </Avatar>
        </Tooltip>
      )}
    </div>
  );
};

export default CustomAvatarGroup;
export { UserAvatar };
