import React, { useState } from 'react';
import { Table, Tag, Button, Pagination } from 'antd';


const AccountBillingInvoices = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => <span>{text}</span>,
      className: 'ap-date-column',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => <span>${text}</span>,
      className: 'ap-amount-column',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'Paid' ? 'green' : 'blue'}>
          {status}
        </Tag>
      ),
      className: 'ap-status-column',
    },
    {
      title: 'Download',
      key: 'download',
      render: () => (
        <Button type="link" icon={<i className="fi fi-rr-download"></i>}>
          Invoice
        </Button>
      ),
      className: 'ap-download-column',
    },
  ];

  const data = [
    {
      key: '1',
      date: 'Jun 15, 2024',
      amount: '65.00',
      status: 'Pending',
    },
    {
      key: '2',
      date: 'Jun 20, 2024',
      amount: '65.00',
      status: 'Paid',
    },
    {
      key: '3',
      date: 'Jul 9, 2024',
      amount: '65.00',
      status: 'Paid',
    },
    {
      key: '4',
      date: 'Aug 17, 2024',
      amount: '65.00',
      status: 'Pending',
    },
    {
      key: '5',
      date: 'Jun 20, 2024',
      amount: '65.00',
      status: 'Paid',
    },
  ];

  const currentRows = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  return (
    <div className="ap-billing-invoices-container">
      <h2>Payment History</h2>
      <Table
        columns={columns}
        dataSource={currentRows}
        pagination={false}
        rowClassName="ap-invoices-row"
      />
      <Pagination
        current={currentPage}
        pageSize={rowsPerPage}
        total={data.length}
        onChange={setCurrentPage}
        className="main-pagination"
      />
    </div>
  );
};

export default AccountBillingInvoices;
