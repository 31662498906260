import React, { useEffect, useState } from 'react';
import PaymentModal from './PaymentComponents/PaymentModal';
import { appSubDir } from '../../config';
import { activateStaterSubscription, fetchCompanySubscription } from '../../services/subscription.service';
import { Spin } from 'antd';
import { dateTimeFormat, isPastDateTime } from '../../services/common.functions';


const AccountPlan = () => {
  const [loading, setLoading] = useState(false)
  const [subscription, setSubscription] = useState(null)
  const [plan, setPlan] = useState(null)
  // Page Data Loader
  function loadData() {
    setLoading(true);
    fetchCompanySubscription().then((res) => {
      setSubscription(res.data.subscription || null);
      setPlan(res.data.plan);
    }).finally(() => {
      setLoading(false);
    });
  }
  // Fetch Data Trigger from the backend
  useEffect(() => {
    const timer = setTimeout(() => {
      loadData()
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, []);
  function activateStarterPackageAction() {
    setLoading(true);
    activateStaterSubscription().then((res) => {
      setSubscription(res.data);
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <div className="ap-plan-container">
      <div className="ap-plan-title-container">
        <h2>Choose Your Plan</h2>
        <div className="ap-plan-text">At All-In-One Solutions, we are currently in the Beta phase and are pleased to offer our new clients exclusive, low-cost packages. While our software is still being refined, you can take advantage of premium features at a significantly reduced rate. If you have any questions or need assistance, please don't hesitate to reach out to us.</div>
      </div>
      <Spin spinning={loading}>
        <div className="ap-plan-cards">
          <div className="ap-plan-card ap-plan-card-free">
            <div className="ap-card-header">
              <div className="ap-plan-graphic">
                <img src={appSubDir + "assets/images/Starter.webp"} alt="Artboard-2-copy-4-2x-8" border="0" />
              </div>

              <div className="ap-plan-name">Starter</div>
              <p className="ap-price">Free Forever!</p>
              {<button
                disabled={subscription && subscription.package}
                onClick={activateStarterPackageAction} className="ap-select-button">{(() => {
                  if (subscription && subscription.package === "Starter") {
                    return "Already Active"
                  }
                  if (subscription && subscription.package) {
                    return "Not Available"
                  }
                  return "Start Free"
                })()}</button>}
            </div>
            <div className="ap-card-body">
              <ul className="ap-features-list">
                <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Project Management</li>
                <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Client Management </li>
                <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Tasks Data Visualization</li>
                <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Team Tasks Monitoring</li>
                <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Approvals</li>
                <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Advanced Searching</li>
                <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> {plan ? plan.starter_team : 2} Team Members</li>
                <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> {plan ? plan.starter_client : 10} Active Clients</li>
                <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> {plan ? plan.starter_project : 20} Active Projects</li>
              </ul>
            </div>
          </div>
          <div className="ap-plan-card ap-plan-card-business">
            <div className="ap-card-header">
              <div className="ap-plan-graphic">
                <img src={appSubDir + "assets/images/Business.webp"} alt="Artboard-2-copy-4-2x-8" border="0" />

              </div>
              <div className="ap-plan-name">Business</div>
              <p className="ap-price">{(() => {
                if (subscription && subscription.package === "Business" && subscription.expires_at && !isPastDateTime(subscription.expires_at)) {
                  return `$${subscription.price}/${subscription.interval}`
                } else if (plan && plan.price) {
                  return `$${plan.price}/Month`
                }
                return "Loading...."
              })()}</p>
              <PaymentModal {...{ subscription, setSubscription, plan }} />
            </div>
            <div className="ap-card-body">
              <ul className="ap-features-list">
                <li className="ap-everything-plus"> Everything in Starter, plus:</li>
                <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> + {plan?.per_team} Team Member </li>
                <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> + {plan?.per_client} Active Clients </li>
                <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> + {plan?.per_project} Active Projects </li>
                {(subscription && subscription.expires_at && !isPastDateTime(subscription.expires_at)) && <li>
                  <div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i> </div>
                  Expires at : {dateTimeFormat.long(subscription.expires_at)}

                </li>}
                <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Kanban View <span className="lite-text"> (Coming Soon)</span> </li>
                <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Gantt View <span className="lite-text"> (Coming Soon)</span> </li>
                <li><div className="ap-plan-checkmark"><i className="fi fi-rr-check-circle"></i></div> Calendar View <span className="lite-text"> (Coming Soon)</span> </li>
              </ul>
            </div>
          </div>
        </div>
      </Spin>

      <div className="ap-plan-addons-container">
        <h2>Addons</h2>

        <div className="ap-addon-card">
          <div className="ap-addon-icon-container">
            <div className="ap-addon-icon-circle">
              <i class="fi fi-rr-box-open"></i>
            </div>
          </div>
          <div className="ap-addon-content">
            <h3>White-Label</h3>
            <p>Our software, your brand’s name! White-labeled and hosted on your domain, it's the perfect way to serve your clients.</p>
          </div>
          <div className="ap-addon-cta-container">
            <div className="ap-addon-cta">
              <p className="ap-addon-price">$100/month</p>
              <button className="ap-addon-button">Request White-Label</button>
            </div>
          </div>
        </div>

        <div className="ap-addon-card">
          <div className="ap-addon-icon-container">
            <div className="ap-addon-icon-circle">
              <i class="fi fi-rr-user-robot"></i>
            </div>
          </div>
          <div className="ap-addon-content">
            <h3>Managed Automations</h3>
            <p>Sit back and relax while we manage your automations! From setup to maintenance, we do it all. Prices vary by task. Get in touch to get started!</p>
          </div>
          <div className="ap-addon-cta-container">
            <div className="ap-addon-cta">
              <button className="ap-addon-button">Request Automation</button>
            </div>
          </div>
        </div>
      </div>








    </div>
  );
};

export default AccountPlan;
