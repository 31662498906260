import axios from "axios";
import { apiBaseUrl } from "../config";

export function fetchChartData(days=30) {
    return axios.get(apiBaseUrl + `dashboard/chart/?days=${encodeURIComponent(days)}`, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function fetchOverviewData() {
    return axios.get(apiBaseUrl + "dashboard/overview", { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}

export const teamDashboardService = {
    fetchChartDate: (days = 30) => {
        return axios.get(apiBaseUrl + `team/dashboard/?days=${encodeURIComponent(days)}`, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
    },
    fetchOverviewData: () => {
        return axios.get(apiBaseUrl + "team/dashboard/overview", { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
    }
}