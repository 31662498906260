import React from 'react';
import FilterSection from './FilterSection';
import './WorkPages.less';
import PGGroupedTable from './PGGroupedTable';
import TableSingle from './TableSingle';
import SectionAdd from './SectionAdd';
import { useProjectContext } from '../../Context/ProjectContext';
import SkeletonTable from '../../Components/SkeletonTable';

const ProjectManagementTable = () => {
  const { taskGroups, isTableLoading } = useProjectContext();
  if (isTableLoading) {
    return <SkeletonTable rows={8} columns={4} />
  }
  return (
    <div className="pmt-project-container">
      <FilterSection />
      {taskGroups.length > 0 ? <PGGroupedTable /> : <TableSingle />}
      <SectionAdd />
    </div>
  );
};

export default ProjectManagementTable;
