import React, { useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { useTeamMembersContext } from '../../Context/TeamMembersProvider';
import TeamMemberFormModal from '../../Modals/TeamMemberFormModal';
import { formatMember } from '../../services/member.service';

const TableRowsOptions = () => {
  const { rowsPerPage, setRowsPerPage } = useTeamMembersContext();
  const optionValues = [4, 8, 12, 20, 60, 100]
  const menu = (
    <Menu onClick={(e) => setRowsPerPage(Number(e.key))}>
      {optionValues.map((item) => (<Menu.Item key={item}>{item / 4}</Menu.Item>))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button className="ct-rows-button">
        {rowsPerPage / 4} <i className="fi fi-rr-angle-small-down"></i>
      </Button>
    </Dropdown>
  );
};
const columns = [
  {
    title: 'Tasks Assigned',
    key: 'tt'
  },
  {
    title: 'In Progress',
    key: 'tip'
  },
  {
    title: 'Completed',
    key: 'c'
  },
  {
    title: 'Overdue',
    key: 'od'
  }
];

const orders = [
  {
    key: 'any',
    title: 'Any'
  },
  {
    key: 'asc',
    title: 'A - Z'
  },
  {
    key: 'desc',
    title: 'Z - A'
  }
];

const SortingMenuItem = ({ column }) => {
  const { sortByColumns, setSortByColumns } = useTeamMembersContext()
  const handleMenuClick = (e) => {
    if (e.key === 'any') {
      const index = sortByColumns.findIndex((item) => (item.key === column.key))
      sortByColumns.splice(index, 1)
      setSortByColumns([...sortByColumns])
    } else {
      const sortByColumn = sortByColumns.find((item) => (item.key === column.key))
      if (sortByColumn) {
        sortByColumn.order = e.key
      } else {
        sortByColumns.push({
          ...column, order: e.key
        })
      }
      setSortByColumns([...sortByColumns])
    }
  };
  return (
    <Menu onClick={handleMenuClick}>
      {orders.map((item) => <Menu.Item key={item.key}>{item.title}</Menu.Item>)}
    </Menu>
  );
}
const SortingMenu = ({ column }) => {
  const { sortByColumns } = useTeamMembersContext()
  const sortByColumn = sortByColumns.find((item) => (item.key === column.key))
  const order = sortByColumn ? orders.find((item) => (item.key === sortByColumn.order)) : null
  return <Dropdown
    dropdownRender={() => <SortingMenuItem column={column} />}
    trigger={['click']}
  >
    <Button>
      {column.title + (order ? " : " + order.title : "")} <i className="fi fi-rr-angle-small-down"></i>
    </Button>
  </Dropdown>
};

const TeamOverviewSort = () => {
  const { teamMembers, setTeamMembers } = useTeamMembersContext()
  const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  function onSubmit(newMember) {
    // console.log('New Team member details:', newMember);
    formatMember(newMember)
    teamMembers.splice(0, 0, newMember)
    setTeamMembers([...teamMembers])
  }
  return (
    <div className="pt-sort-container">
      <div className="pt-sort-options-row">
        <div className="pt-sort-buttons">
          <span className="pt-sort-text">Sort: </span>
          {columns.map((column, index) => (
            <SortingMenu key={index} column={column} />
          ))}

          <div className="pt-add-project-row">
            <div className="pt-rows-text">Rows: </div>
            <TableRowsOptions />
          </div>
        </div>
        <div className="pt-rows-options">
          <Button className="nf-add-team-member" onClick={showModal}>
            <i className="nf-add-team-member-icon fi fi-rr-user-add"></i>
            Add Team Member
          </Button>
        </div>
      </div>
      <TeamMemberFormModal isVisible={isModalVisible} toggleModal={handleCancel} onSubmit={onSubmit} />
    </div>
  );
};

export default TeamOverviewSort;
