import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import AccountProfile from './AccountProfile';
import AccountBilling from './AccountBilling';
import AccountPlan from './AccountPlan';
import AccountSupport from './AccountSupport';
import './AccountPages.less';
import { useSelector } from 'react-redux';
import { hasRole } from '../../services/auth.service';
import SuperAdmins from './SuperAdmins';

const AccountPage = () => {
  const user = useSelector((state) => state.auth.user);
  const isTeam = hasRole(user, 'team')
  const isSuperAdmin = hasRole(user, 'super-admin')
  const isCLient = hasRole(user, 'client')
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tabParam = searchParams.get('tab') || 'profile';
  const [current, setCurrent] = useState(tabParam);

  useEffect(() => {
    setCurrent(tabParam);
  }, [tabParam]);

  const handleClick = (e) => {
    const newTab = e.key;
    setCurrent(newTab);
    navigate(`/account?tab=${newTab}`);
  };

  const renderComponent = () => {
    if (isTeam) {
      return <AccountProfile />
    }
    if (isCLient) {
      switch (current) {
        case 'billing':
          return <AccountBilling />;
        case 'plan':
          return <AccountPlan />;
        case 'support':
          return <AccountSupport />;
        default:
          return <AccountProfile />;
      }
    }
    if (isSuperAdmin) {
      switch (current) {
        case 'admins':
          return <SuperAdmins />;
        default:
          return <AccountProfile />;
      }
    }

  };

  return (
    <div>
      <p className="main-page-title">
        <span className="main-page-title-icon">
          <i className="fi fi-rr-settings"></i>
        </span>
        Account
      </p>

      {!isTeam && <Menu className="ap-menu" onClick={handleClick} selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="profile">Profile</Menu.Item>
        {isSuperAdmin && <Menu.Item key="admins">Admins</Menu.Item>}
        {isCLient && <Menu.Item key="billing">Billing</Menu.Item>}
        {isCLient && <Menu.Item key="plan">Plan</Menu.Item>}
        {isCLient && <Menu.Item key="support">Support</Menu.Item>}
      </Menu>}
      <div>
        {renderComponent()}
      </div>
    </div>
  );
};

export default AccountPage;
