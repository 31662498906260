// HoursTrackerTableSort.js


import React from 'react';
import { useClientHoursContext } from '../../Context/ClientHoursContext';
import TableSorter from '../../Components/TableSorter';
import { useSelector } from 'react-redux';
import { hasRole } from '../../services/auth.service';


const options = [
  {
    value: 10,
    label: '10'
  },
  {
    value: 20,
    label: '20'
  },
  {
    value: 30,
    label: '30'
  },
  {
    value: 50,
    label: '50'
  },
  {
    value: 100,
    label: '100'
  }
];
const HoursTrackerTableSort = () => {
  const user = useSelector((state) => state.auth.user);
  const columns = [
    {
      title: 'Date',
      key: 'date'
    }
  ];
  if (!hasRole(user, 'client')) {
    columns.splice(0, 0,
      {
        title: 'Confirmed',
        key: 'confirmed'
      })
  }
  const {
    sortByColumns, setSortByColumns,
    rowsPerPage, setRowsPerPage
  } = useClientHoursContext();

  return (
    <TableSorter {...{
      columns,
      sortByColumns,
      setSortByColumns,
      options,
      rowsPerPage,
      setRowsPerPage
    }} />
  );
};

export default HoursTrackerTableSort;
