import React, { useEffect, useState } from 'react';
import { Menu, Badge, Avatar, Dropdown, Button, notification } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import '../custom.less'; // Ensure custom.less is imported to apply styles
import './Searchbar.less';
import SearchbarInput from './SearchbarInput'; // Import the SearchbarInput component
import NotificationsDropdown from '../Pages/NotificationsPage/NotificationsDropdown'; // Import the NotificationsDropdown component
import { useSelector, useDispatch } from 'react-redux';
import { setToken, setUserData } from '../stores/auth.slice';
import { appSubDir } from '../config';
import { getAvatarUrl, getRole, hasRole, logout } from '../services/auth.service';
import { fetchTopNotifications } from '../services/notification.service';
import TeamMemberFormModal from '../Modals/TeamMemberFormModal';

const NotificationHrad = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notificationData, setNotificationData] = useState({ items: [], count: 0 });
  function loadData() {
    setLoading(true)
    fetchTopNotifications().then((res) => {
      setNotificationData(res.data);
    }).finally(() => {
      setLoading(false)
    })
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      loadData()
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [])
  return <Dropdown
    dropdownRender={() => (<NotificationsDropdown {...{ notificationData, loading, setNotificationData, setOpen }} />)}
    trigger={['click']}
    open={open}
    onOpenChange={setOpen}
  >
    <Badge count={notificationData.count} offset={[-2, 3]} className="hide-on-small-screens">
      <Avatar size="large" className="avatar-bell">
        <i className="fi fi-rr-bell" style={{ fontSize: '20px', color: 'inherit' }}></i>
      </Avatar>
    </Badge>
  </Dropdown>
}

const Searchbar = ({ handleToggleSidebar }) => {
  const [visible, setVisible] = useState({ profile: false, notifications: false });
  const [isTeamMemberFormModalVisible, setIsTeamMemberFormModalVisible] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const isSuperAdmin = hasRole(user, 'super-admin')
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  if (!user) {
    return null
  }
  const initials = user.f_name.charAt(0).toUpperCase() + user.l_name.charAt(0).toUpperCase()
  const handleVisibleChange = (name, isVisible) => {
    setVisible((prevVisible) => ({ ...prevVisible, [name]: isVisible }));
  };
  function handleLogout() {
    window.localStorage.removeItem("token")
    dispatch(setUserData(null))
    dispatch(setToken(null))
    navigate(appSubDir)
    notification.success({
      message: 'Success',
      description: 'You Logged out successfully.',
    })
    logout(token)
  }


  const showTeamMemberFormModal = () => {
    setIsTeamMemberFormModalVisible(true);
    setVisible((prevVisible) => ({ ...prevVisible, profile: false })); // Close the profile dropdown
  };

  const handleHelpClick = () => {
    navigate('/account?tab=support');
    setVisible((prevVisible) => ({ ...prevVisible, profile: false })); // Close the profile dropdown
  };

  const profileMenu = (
    <Menu className="profile-menu">
      <div className="profile-avatar">
        {user.avatar ?
          <img src={getAvatarUrl(user.avatar)} alt={user?.f_name + " " + user?.l_name} width={48} />
          : <Avatar size={48} className="user-profile-avatar">
            {initials}
          </Avatar>}
        <div className="profile-info">
          <div className="profile-name">{user.f_name} {user.l_name}</div>
          <div className="profile-company">{getRole(user)}</div>
        </div>
      </div>
      <Menu.Divider />
      <Menu.Item key="profile" icon={<i className="fi fi-rr-user"></i>}>
        <Link to="/account?tab=profile">
          <span>Profile</span>
        </Link>
      </Menu.Item>
      {isSuperAdmin && <>
        <Menu.Item key="settings" icon={<i className="fi fi-rr-settings"></i>}>
          <Link to="/account?tab=settings">
            <span>Settings</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="invite" icon={<i className="fi fi-rr-user-add"></i>} onClick={showTeamMemberFormModal}>
          <span>Invite Team Member</span>
        </Menu.Item>
      </>}
      <Menu.Item key="help" icon={<i className="fi fi-rr-life-ring"></i>} onClick={handleHelpClick}>
        <span>Support</span>
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleLogout} icon={<i className="fi fi-rr-exit"></i>}>
        <span>Log Out</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="searchbar-container">
      <div className="logo-container">
        <Button type="text" onClick={handleToggleSidebar} className="hamburger-icon">
          <MenuOutlined />
        </Button>

        <Link to="/" className="logo-link">
          <div className="logo-image">
            <img src={appSubDir + "assets/images/aio-logo-60.webp"} alt="aio Logo" className="logo-img" />
          </div>
        </Link>
      </div>

      <SearchbarInput /> {/* Use the new SearchbarInput component here */}

      <div className="avatar-container">
        <NotificationHrad />
        <Dropdown
          overlay={profileMenu}
          trigger={['click']}
          visible={visible.profile}
          onVisibleChange={(isVisible) => handleVisibleChange('profile', isVisible)}
        >
          <div className="dropdown-trigger">
            {user.avatar ?
              <img src={getAvatarUrl(user.avatar)} alt={user?.f_name + " " + user?.l_name} width={48} />
              : <Avatar size="large" className="avatar-profile-initials">
                {initials}
              </Avatar>}
            {visible.profile ? (
              <i className="fi fi-rr-angle-small-up"></i>
            ) : (
              <i className="fi fi-rr-angle-small-down"></i>
            )}
          </div>
        </Dropdown>
      </div>
      {isSuperAdmin && <TeamMemberFormModal
        isVisible={isTeamMemberFormModalVisible}
        toggleModal={() => setIsTeamMemberFormModalVisible(false)}
      />}
    </div>
  );
};

export default Searchbar;
