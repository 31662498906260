import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import '../Components/UserAvatars.less';
import ClientForm from '../Components/ClientForm';

const ClientAddModal = ({ onSuccess }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }
  return <>
    <Button style={{ marginBottom: 10 }} key="submit" type="primary" onClick={toggleModal}>
      Add Client
    </Button>
    <Modal
      title={
        <div className="modal-title-container">
          <div className="modal-icon-container">
            <i className="fi fi-rr-team-check modal-icon"></i>
          </div>
          Add New Client
        </div>
      }
      open={isModalOpen}
      onCancel={toggleModal}
      footer={false}
    >
      <ClientForm onSuccess={() => {
        toggleModal()
        onSuccess()
      }} />
    </Modal>
  </>
}
export default ClientAddModal;
