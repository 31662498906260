import React from 'react';
import { Table } from 'antd';
import '../WorkPages.less'; // Ensure you create the corresponding CSS file
import '../../DashboardPage/Dashboard.less';
import { useProjectContext } from '../../../Context/ProjectContext';

const ProjectOverviewTasksBar = () => {
  const { project } = useProjectContext(); // State for selected project
  const totalTasks = project.total_tasks
  const notStartedPercentage = (project.tasks_not_started / totalTasks) * 100;
  const inProgressPercentage = (project.tasks_in_progress / totalTasks) * 100;
  const completedPercentage = (project.tasks_completed / totalTasks) * 100;

  const data = [
    {
      key: '1',
      status: 'Not Started',
      tasks: project.tasks_not_started,
      percentage: `(${notStartedPercentage.toFixed(0)}%)`,
      circleClass: 'db-not-started-circle'
    },
    {
      key: '2',
      status: 'In Progress',
      tasks: project.tasks_in_progress,
      percentage: `(${inProgressPercentage.toFixed(0)}%)`,
      circleClass: 'db-in-progress-circle'
    },
    {
      key: '3',
      status: 'Completed',
      tasks: project.tasks_completed,
      percentage: `(${completedPercentage.toFixed(0)}%)`,
      circleClass: 'db-completed-circle'
    },
  ];

  const columns = [
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      className: 'status-column',
      render: (text, record) => (
        <span><span className={`db-project-circle ${record.circleClass}`}></span> {text}</span>
      )
    },
    {
      title: '',
      dataIndex: 'tasks',
      key: 'tasks',
      className: 'tasks-column',
    },
    {
      title: '',
      dataIndex: 'percentage',
      key: 'percentage',
      className: 'percentage-column',
    },
  ];

  return (
    <div className="db-progress-column-container">
      <div className="db-tasks-bar-container">
        <div>
          <div className="db-bar-info">
            <span className="db-bar-title">{totalTasks} tasks</span>
          </div>
          <div className="db-progress-bar-container">
            <div className="db-progress-bar db-progress-bar-not-started" style={{ width: `${notStartedPercentage}%` }}></div>
            <div className="db-progress-bar db-progress-bar-in-progress" style={{ width: `${inProgressPercentage}%` }}></div>
            <div className="db-progress-bar db-progress-bar-completed" style={{ width: `${completedPercentage}%` }}></div>
          </div>
        </div>
        <Table className="team-tasks-summary-table" dataSource={data} columns={columns} pagination={false} showHeader={false} />
      </div>
    </div>
  );
};

export default ProjectOverviewTasksBar;
