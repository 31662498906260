import React, { useState, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine, Label } from 'recharts';
import HoursTrackerMenu from './HoursTrackerMenu';
import HoursTrackerStats from './HoursTrackerStats';
import { formatXAxis, getTickValues } from './HoursTrackerXAxis';
import './HoursTracker.less';
import { useParams } from 'react-router-dom';
import { convertDateFormat, fetchClientHoursDatewise } from '../../services/client.service';
import { useSelector } from 'react-redux';
import { getRoleName } from '../../services/auth.service';

const HoursTrackerChart = () => {
  const { id } = useParams()
  const user = useSelector((state) => state.auth.user);
  const [selectedRange, setSelectedRange] = useState('7');
  const [data, setData] = useState([]);
  const [selectedValues, setSelectedValues] = useState(['timeSpent', 'timeLeft']);

  // Page Data Loader
  function loadData() {
    fetchClientHoursDatewise(selectedRange, id, getRoleName(user)).then((res) => {
      const newData = res.data.map((element) => ({
        d: convertDateFormat(element.t_date),
        tP: element.hours_purchased ? element.hours_purchased : 0,
        tS: element.hours_spent ? element.hours_spent : 0,
        tL: element.closing_hours ? element.closing_hours : 0,
      }));
      setData(newData);
    })
  }
  // Fetch clients from the backend
  useEffect(() => {
    const timer = setTimeout(() => {
      loadData()
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [selectedRange]);


  const handleRangeChange = (newRange) => {
    setSelectedRange(newRange);
    // setTimeout(() => {
    //   loadData()
    // }, 10)
  };

  const handleFilterChange = (value) => {
    if (value === 'all') {
      setSelectedValues(['timeSpent', 'timeLeft']);
    } else {
      const newSelectedValues = selectedValues.includes(value)
        ? selectedValues.filter((val) => val !== value)
        : [...selectedValues, value];
      setSelectedValues(newSelectedValues);
    }
  };
  const ticks = getTickValues(data, selectedRange);

  // Calculate the maximum and minimum values for Y-axis
  const minY = Math.min(...data.map(d => (Math.min(d.tS, d.tL))))
  const maxY = Math.max(...data.map(d => (Math.max(d.tS, d.tL))))

  // Generate ticks based on minY and maxY
  const yTicks = [];
  for (let i = minY; i <= maxY; i += 10) {
    yTicks.push(i);
  }

  return (
    <div className="ht-chart-container">
      <HoursTrackerMenu
        selectedRange={selectedRange}
        handleRangeChange={handleRangeChange}
        selectedValues={selectedValues}
        handleFilterChange={handleFilterChange}
      />

      <div className="ht-chart">
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="d" tickFormatter={formatXAxis} ticks={ticks}>
              <Label value="Time" offset={-5} position="insideBottom" />
            </XAxis>
            <YAxis domain={[minY, maxY]} ticks={yTicks}>
              <Label value="Hours Usage" angle={-90} position="insideLeft" />
            </YAxis>
            <Tooltip />
            {data.some(d => d.tL < 0) && <ReferenceLine y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />}
            {selectedValues.includes('timeLeft') && (
              <Area
                type="linear"
                dataKey="tL"
                stroke="#82ca9d"
                fill="url(#colorTimeLeft)"
                strokeWidth={2}
                dot={false}
              />
            )}
            {selectedValues.includes('timeSpent') && (
              <Area
                type="linear"
                dataKey="tS"
                stroke="#8884d8"
                fill="url(#colorTimeSpent)"
                strokeWidth={2}
                dot={false}
              />
            )}
            <defs>
              <linearGradient id="colorTimeLeft" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#22C55E" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#22C55E" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorTimeSpent" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0.7} />
              </linearGradient>
            </defs>
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <HoursTrackerStats selectedRange={selectedRange} />
    </div>

  );
};

export default HoursTrackerChart;
