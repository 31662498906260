import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../custom.less';

const TextEditor2 = ({ value, onChange }) => {
  const [editorHtml, setEditorHtml] = useState('');

  useEffect(() => {
    if (value) {
      setEditorHtml(value);
    }
  }, [value]);

  const handleChange = (html) => {
    setEditorHtml(html);
    if (onChange) {
      onChange(html);
    }
  };

  return (
    <ReactQuill
      value={editorHtml}
      onChange={handleChange}
      modules={TextEditor2.modules}
      formats={TextEditor2.formats}
      placeholder="Add Task Description"
    />
  );
};

TextEditor2.modules = {
  toolbar: [
    [{ 'size': [] }],
    ['bold', 'italic', 'underline'],
    [{'list': 'ordered'}, {'list': 'bullet'}],
    ['link', 'image']
  ],
};

TextEditor2.formats = [
  'size',
  'bold', 'italic', 'underline',
  'list', 'bullet',
  'link', 'image'
];

export default TextEditor2;
