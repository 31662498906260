import React, { useState, useRef } from 'react';
import { Button, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './WorkPages.less';
import { useProjectContext } from '../../Context/ProjectContext';

const SectionAdd = () => {
  const { groupByColumns, add } = useProjectContext();
  const [inputVisible, setInputVisible] = useState(false)
  const ref = useRef(null)
  function addSectionAction() {
    const sectionTitle = ref.current?.input.value
    if (sectionTitle) {
      add.section(sectionTitle)
    }
    setInputVisible(false)

  }
  function onKeyDown(e) {
    if (e.key === 'Enter') {
      addSectionAction()
    }
  }
  return (
    <div className='add-section'>
      {(groupByColumns.includes('section')) && <>
        {inputVisible ? <Input ref={ref} placeholder='Section Title' onBlur={addSectionAction} onKeyDown={onKeyDown} /> :
          <Button type={"link"} icon={<PlusOutlined />} onClick={() => {
            setTimeout(() => {
              ref.current?.focus()
            }, 5)
            setInputVisible(true)
          }}>
            Add Section
          </Button>}
      </>}
    </div>
  );
};

export default SectionAdd;
