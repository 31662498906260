import React from 'react';
import './custom.less';  // Import custom Less file

import AuthGuard from './Components/AuthGuard';
import { Provider } from 'react-redux';
import { store } from './stores/auth.store';






const App = () => {
  return (
    <Provider store={store}>
      <AuthGuard />
    </Provider>
  );
};

export default App;
