import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Typography, message } from 'antd';
import { Link } from 'react-router-dom';
import './AuthenticationPages.less';
import { appSubDir } from '../../config';
import { useDispatch } from 'react-redux';
import { login } from '../../services/auth.service';
import { setToken } from '../../stores/auth.slice';

const { Text } = Typography;

const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()


  const handleSubmit = (values) => {
    setLoading(true);
    login(values.email, values.password).then((res) => {
        const {token} = res.data
        message.success('Login was successful.');
        window.localStorage.setItem('token', token)
        dispatch(setToken(token))
    }).catch((error) => {
        let errText = error.response.data.error;
        if (!errText) {
            errText = error.message;
        }
        message.error(errText)
    }).finally(() => {
        setLoading(false);
    })
  };

  return (
    <div className="authentication-register-container">
      <Row className="authentication-register-row">
        <Col className="authentication-form-column" xs={24} md={12}>
          <div className="authentication-logo-container">
            <img src={appSubDir+"assets/images/aio-logo-new.webp"} alt="AIO Solutions" className="authentication-logo" />
          </div>

          <div className="authentication-form-form">
            <div className="authentication-title">Sign In</div>
            <Form layout="vertical" onFinish={handleSubmit} className="authentication-form">
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: 'Please enter your email' }]}
              >
                <Input type="email" placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                label={
                  <div className="authentication-password-label">
                    <span>Password</span>
                    <Link to="/forgot-password" className="authentication-forgot-password-link">
                      Forgot Password?
                    </Link>
                  </div>
                }
                rules={[{ required: true, message: 'Please enter your password' }]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item>
                <Button loading={loading} htmlType="submit" className="authentication-submit-button">
                  Sign In
                </Button>
              </Form.Item>
              <Text className="authentication-login-link">
                Don't have an account? <Link to="/register">Sign Up</Link>
              </Text>
            </Form>
          </div>
        </Col>
        <Col className="authentication-image-column" xs={0} md={12}>
          <div className="authentication-image-container">
            <img src={appSubDir+"assets/images/Team-work.webp"} alt="Login" className="authentication-image" />
          </div>
          <div className="authentication-register-right-text">
            Managing your business just got easier.
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LoginPage;
