// File Path : src\Pages\WorkPages\TableColumns\TaskNameColumn.js

import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { useProjectContext } from '../../../Context/ProjectContext';
export const EditableTaskNameCell = ({
  value: initialValue,
  row,
  column,
  taskIndex,
  subtaskIndex
}) => {
  const [value, setValue] = useState(initialValue);
  const { update, add } = useProjectContext();
  const { index } = row
  const isSubtask = !!row.original.subtaskId

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    if (isSubtask) {
      update.subTask(row.original, column.id, value)
    } else {
      update.task(row.original, column.id, value)
    }
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter' && !!value) {
      if (isSubtask) {
        const { taskIndex, subtaskIndex } = row.original
        add.subTask(taskIndex, subtaskIndex);
      } else {
        const { groupIndex, taskIndex } = row.original
        add.task(groupIndex, taskIndex);
      }
    }
  };

  return <Input
    value={value}
    className={isSubtask ? `input-${taskIndex}-${subtaskIndex}` : `input-${index}`}
    onChange={onChange}
    onBlur={onBlur}
    onKeyPress={onKeyPress} />;
};

const TaskNameColumn = {
  Header: 'Task Name',
  accessor: 'taskName',
  width: 300,
  Cell: EditableTaskNameCell,
  className: 'pmt-task-column',
};

export default TaskNameColumn;
