// File Path : src\services\project-task.service.js

import axios from "axios";
import { apiBaseUrl } from "../config";
export function getTimeSpentStr(inputString) {
    // Parse the string input to a number
    let number = parseFloat(inputString);

    // Separate the integer part and the decimal part
    let hours = Math.floor(number);
    let minutes = Math.round((number - hours) * 60);

    // Format hours and minutes to be two digits
    let formattedHours = hours.toString().padStart(2, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
}
export function getNumberFromTimeStr(timeString) {
    // Split the time string into hours and minutes
    timeString = timeString.replace("h ", ":").replace("m", "")
    let [hours = 0, minutes = 0] = timeString.split(':').map((item) => parseInt(item) || 0);

    // Convert hours and minutes into a single number with fractional hours
    let number = hours + (minutes / 60);

    return number.toFixed(2);
}
export function fetchTasks(projectId) {
    return axios.get(apiBaseUrl + `projects/${projectId}/tasks`, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
        .then((res) => {
            const tasks = res.data
            tasks.forEach((item, index) => {
                item.taskId = item.task_id
                item.position = index
                item.taskName = item.task
                item.status = item.status
                item.dueDate = item.due_date
                item.assignee = item.assignee_id
                item.timeSpent = getTimeSpentStr(item.hours_tracked)
                item.subtasks.forEach((subTask, si) => {
                    subTask.subtaskId = subTask.sub_task_id
                    subTask.position = si
                    subTask.taskName = subTask.task
                    subTask.status = subTask.status
                    subTask.dueDate = subTask.due_date
                    subTask.assignee = subTask.assignee_id
                    subTask.timeSpent = getTimeSpentStr(subTask.hours_tracked)
                })
            })
            return tasks
        })
}
export function updateTask(
    projectId,
    taskId,
    sub_task_id,
    task,
    assignee_id,
    due_date,
    hours_tracked,
    status,
    section
) {
    return axios.put(apiBaseUrl + `projects/${projectId}/tasks/${taskId}`, {
        sub_task_id,
        task,
        assignee_id,
        due_date,
        hours_tracked: getNumberFromTimeStr(hours_tracked),
        status,
        section
    }, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function removeMember(projectId, user_id) {
    return axios.delete(apiBaseUrl + `projects/${projectId}/members/${user_id}`, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function addMember(projectId, user_id) {
    return axios.post(apiBaseUrl + `projects/${projectId}/member`, { user_id }, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function addTask(projectId,
    task_id,
    sub_task_id,
    task,
    assignee_id,
    due_date,
    hours_tracked,
    status,
    section) {
    return axios.post(apiBaseUrl + `projects/${projectId}/tasks/`, {
        sub_task_id,
        task_id,
        task,
        assignee_id,
        due_date,
        hours_tracked: getNumberFromTimeStr(hours_tracked),
        status,
        section
    }, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function updateTaskInBulk(projectId, dataToPut) {
    return axios.put(apiBaseUrl + `projects/${projectId}/tasks-in-bulk/`, dataToPut, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}

export function createTask(values) {
    return axios.post(apiBaseUrl + 'projects', values, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}

export function projectAutocomplete(value) {
    return axios.get(apiBaseUrl + `projects/autocomplete/${value}`, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export class projectTaskDocumentService {
    constructor(projectId, taskId) {
        this.projectId = projectId
        this.taskId = taskId
        this.headers = { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } }
    }
    index() {
        return axios.get(apiBaseUrl + `projects/${this.projectId}/tasks/${this.taskId}/documents`, this.headers)
    }
    store(dataToPost) {
        return axios.post(apiBaseUrl + `projects/${this.projectId}/tasks/${this.taskId}/documents`, dataToPost, this.headers)
    }
    delete(documentId) {
        return axios.delete(apiBaseUrl + `projects/${this.projectId}/tasks/${this.taskId}/documents/${documentId}`, this.headers)
    }
}
