// HoursTrackerStats.js

import React from 'react';
import './HoursTracker.less';

const HoursTrackerStats = ({items, selectedRange }) => {
  if(!items){
    return null
  }
  const totalTimeSpent = items.reduce((acc, item) => acc + item.tS, 0);
  const averageTimeSpent = totalTimeSpent / 22; // Assuming 22 workdays in a month


  return (
    <div className="ht-stats-container">


      <div className="ht-stats-column">
        <div className="ht-stats-title">Hrs in last <span className="ht-stats-days-value">{selectedRange}</span> Days:</div>
        <div className="ht-stats-value">{totalTimeSpent.toFixed(2)}</div>
      </div>
      <div className="ht-stats-column">
        <div className="ht-stats-title">Avg hrs/Workday:</div>
        <div className="ht-stats-value">{averageTimeSpent.toFixed(2)}</div>
      </div>
    </div>
  );
};

export default HoursTrackerStats;
