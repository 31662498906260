import React, { createContext, useState, useContext } from 'react';
const ProjectsContext = createContext();

export const ProjectsProvider = ({ children }) => {
  const [sortByColumns, setSortByColumns] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);


  return (
    <ProjectsContext.Provider
      value={{
        currentPage, setCurrentPage,
        selectedProject, setSelectedProject,
        projects, setProjects,
        loading, setLoading,
        totalRows, setTotalRows,
        sortByColumns, setSortByColumns,
        rowsPerPage, setRowsPerPage
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

export const useProjectsContext = () => {
  return useContext(ProjectsContext)
}
