// SubsctiptionstableColumns/SubsctiptionsTableSort.js

import React, { useState } from 'react';
import { Button } from 'antd';
import TableSorter from '../../../Components/TableSorter';
import { useSubsctiptionsContext } from '../../../Context/SubsctiptionsContext';
import { useSelector } from 'react-redux';
import { hasRole } from '../../../services/auth.service';
import FormModal from './FormModal';


const columns = [
  {
    title: 'Status',
    key: 'status'
  },
  {
    title: 'Date',
    key: 'date'
  }
];
const options = [
  {
    value: 10,
    label: '10'
  },
  {
    value: 20,
    label: '20'
  },
  {
    value: 30,
    label: '30'
  },
  {
    value: 50,
    label: '50'
  },
  {
    value: 100,
    label: '100'
  }
];
const TableHeadOptions = () => {
  const user = useSelector((state) => state.auth.user);
  const {
    sortByColumns, setSortByColumns,
    rowsPerPage, setRowsPerPage,
    automations, setSubsctiptions,
  } = useSubsctiptionsContext();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onSuccess = (newSubsctiption) => {
    newSubsctiption.steps = JSON.parse(newSubsctiption.steps)
    setSubsctiptions([newSubsctiption, ...automations]);
  };
  const buttons = <>
    <Button className="button-1" onClick={showModal}>{hasRole(user, 'super-admin') ? "New Subsctiption" : "Request Subsctiption"}</Button>
    <FormModal
      isVisible={isModalVisible}
      setIsVisible={setIsModalVisible}
      onSuccess={onSuccess}
    />
  </>

  return (
    <TableSorter {...{
      columns,
      sortByColumns,
      setSortByColumns,
      options,
      rowsPerPage,
      setRowsPerPage,
      buttons
    }} />
  );
};

export default TableHeadOptions;
