import React from 'react';
import { Popover } from 'antd';

const HoursAndCurrencyColumn = {
  title: 'Hours',
  key: 'hoursAndCurrency',
  className: 'ct-hours-and-currency',
  render: (text, record) => {
    const hoursLeft = record.hours_left
    const totalHours = record.hours_purchased;
    const usedPercentage = (record.hours_spent / record.hours_purchased) * 100;

    const isOverUsed = record.hours_spent > record.hours_purchased;
    const overUsedHours = record.hours_spent - record.hours_purchased;
    const overUsedPercentage = (overUsedHours / totalHours) * 100;
    const remainingPercentage = isOverUsed ? 0 : 100 - usedPercentage;

    const hoursLegendContent = (
      <div className="db-legend">
        <div className="db-legend-row">
          <div className="db-legend-item">
            <span className="db-project-circle db-used-circle"></span> <p>Used</p>
          </div>
          {isOverUsed ? (
            <div className="db-legend-item">
              <span className="db-project-circle db-overused-circle"></span> <p>Overused</p>
            </div>
          ) : (
            <div className="db-legend-item">
              <span className="db-project-circle db-remaining-circle"></span> <p>Remaining</p>
            </div>
          )}
        </div>
        <div className="db-legend-row">
          <div className="db-legend-item">
            {record.hours_spent}
            <span className="db-project-percentage"> ({usedPercentage.toFixed(0)}%) </span>
          </div>
          {isOverUsed ? (
            <div className="db-legend-item">
              {overUsedHours}
              <span className="db-project-percentage"> ({overUsedPercentage.toFixed(0)}%) </span>
            </div>
          ) : (
            <div className="db-legend-item">
              {hoursLeft}
              <span className="db-project-percentage"> ({remainingPercentage.toFixed(0)}%) </span>
            </div>
          )}
        </div>
      </div>
    );

    return (
      <Popover content={hoursLegendContent} placement="bottom" overlayClassName="custom-popover">
        <div className="db-hours-bar-container">
          <div className="db-bar-info">
            <span className="db-bar-title">{totalHours} hrs</span>
          </div>
          <div className="db-progress-bar-container">
            <div
              className="db-progress-bar db-progress-bar-used"
              style={{ width: `${Math.min(usedPercentage, 100)}%` }}
            ></div>
            {isOverUsed && (
              <div
                className="db-progress-bar db-progress-bar-overused"
                style={{ width: `${overUsedPercentage}%` }}
              ></div>
            )}
            {!isOverUsed && (
              <div
                className="db-progress-bar db-progress-bar-remaining"
                style={{ width: `${remainingPercentage}%` }}
              ></div>
            )}
          </div>
        </div>
      </Popover>
    );
  },
};

export default HoursAndCurrencyColumn;
