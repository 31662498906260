import React from 'react';
import { Popover, Table } from 'antd';

const ProgressColumn = ({ project }) => {
  const remainingHours = project.hours_allotted - project.hours_tracked;
  const totalHours = project.hours_allotted;
  const usedPercentage = (project.hours_tracked / totalHours) * 100;

  const isOverUsed = project.hours_tracked > project.hours_allotted;
  const overUsedHours = project.hours_tracked - project.hours_allotted;
  const overUsedPercentage = (overUsedHours / totalHours) * 100;
  const remainingPercentage = isOverUsed ? 0 : 100 - usedPercentage;

  const totalTasks = project.total_tasks;
  if (totalTasks < 1) {
    return <>No Task Allocated</>
  }
  const notStartedPercentage = (project.tasks_not_started / totalTasks) * 100;
  const inProgressPercentage = (project.tasks_in_progress / totalTasks) * 100;
  const completedPercentage = (project.tasks_completed / totalTasks) * 100;

  const taskData = [
    {
      key: '1',
      status: 'Not Started',
      tasks: project.tasks_not_started,
      percentage: `(${notStartedPercentage.toFixed(0)}%)`,
      circleClass: 'db-not-started-circle'
    },
    {
      key: '2',
      status: 'In Progress',
      tasks: project.tasks_in_progress,
      percentage: `(${inProgressPercentage.toFixed(0)}%)`,
      circleClass: 'db-in-progress-circle'
    },
    {
      key: '3',
      status: 'Completed',
      tasks: project.tasks_completed,
      percentage: `(${completedPercentage.toFixed(0)}%)`,
      circleClass: 'db-completed-circle'
    },
  ];

  const taskColumns = [
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      className: 'status-column',
      render: (text, record) => (
        <span><span className={`db-project-circle ${record.circleClass}`}></span> {text}</span>
      )
    },
    {
      title: '',
      dataIndex: 'tasks',
      key: 'tasks',
      className: 'tasks-column',
    },
    {
      title: '',
      dataIndex: 'percentage',
      key: 'percentage',
      className: 'percentage-column',
    },
  ];

  const taskTableContent = (
    <Table className="team-tasks-summary-table" dataSource={taskData} columns={taskColumns} pagination={false} showHeader={false} />
  );

  const hoursLegendContent = (
    <div className="db-legend">
      <div className="db-legend-row">
        <div className="db-legend-item">
          <span className="db-project-circle db-used-circle"></span> <p>Used</p>
        </div>
        {isOverUsed ? (
          <div className="db-legend-item">
            <span className="db-project-circle db-overused-circle"></span> <p>Overused</p>
          </div>
        ) : (
          <div className="db-legend-item">
            <span className="db-project-circle db-remaining-circle"></span> <p>Remaining</p>
          </div>
        )}
      </div>
      <div className="db-legend-row">
        <div className="db-legend-item">
          {project.hours_tracked}
          <span className="db-project-percentage"> ({usedPercentage.toFixed(0)}%) </span>
        </div>
        {isOverUsed ? (
          <div className="db-legend-item">
            {overUsedHours}
            <span className="db-project-percentage"> ({overUsedPercentage.toFixed(0)}%) </span>
          </div>
        ) : (
          <div className="db-legend-item">
            {remainingHours}
            <span className="db-project-percentage"> ({remainingPercentage.toFixed(0)}%) </span>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="db-progress-column-container">
      <div className="db-hours-bar-container">
        <Popover
          content={hoursLegendContent}
          placement="bottom"
          overlayClassName="custom-popover"
        >
          <div>
            <div className="db-bar-info">
              <span className="db-bar-title">{totalHours} hrs</span>
            </div>
            <div className="db-progress-bar-container">
              <div className="db-progress-bar db-progress-bar-used" style={{ width: `${Math.min(usedPercentage, 100)}%` }}></div>
              {isOverUsed && (
                <div className="db-progress-bar db-progress-bar-overused" style={{ width: `${overUsedPercentage}%` }}></div>
              )}
              {!isOverUsed && (
                <div className="db-progress-bar db-progress-bar-remaining" style={{ width: `${remainingPercentage}%` }}></div>
              )}
            </div>
          </div>
        </Popover>
      </div>

      <div className="db-tasks-bar-container">
        <Popover
          content={taskTableContent}
          placement="bottom"
          overlayClassName="custom-popover"
        >
          <div>
            <div className="db-bar-info">
              <span className="db-bar-title">{totalTasks} tasks</span>
            </div>
            <div className="db-progress-bar-container">
              <div className="db-progress-bar db-progress-bar-not-started" style={{ width: `${notStartedPercentage}%` }}></div>
              <div className="db-progress-bar db-progress-bar-in-progress" style={{ width: `${inProgressPercentage}%` }}></div>
              <div className="db-progress-bar db-progress-bar-completed" style={{ width: `${completedPercentage}%` }}></div>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default ProgressColumn;
