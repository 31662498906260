import React, { createContext, useState, useContext } from 'react';
import { getMembers } from '../services/member.service';

const ClientsContext = createContext();

export const ClientsProvider = ({ children }) => {
  const [sortByColumns, setSortByColumns] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [teamMembers, setTeamMembers] = useState(getMembers());

  return (
    <ClientsContext.Provider
      value={{
        sortByColumns, setSortByColumns,
        rowsPerPage, setRowsPerPage,
        teamMembers, setTeamMembers
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};

export const useClientsContext = () => {
  return useContext(ClientsContext)
}
