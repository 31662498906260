import React from 'react';
import DashboardChart from './DashboardChart';
import './Dashboard.less';
import DashboardWelcome from './DashboardWelcome';
import DailyOverview from './DailyOverview';
import DashboardProjects from './DashboardProjects';
import DashboardTeamMembers from './DashboardTeamMembers';
import { hasRole } from '../../services/auth.service';
import ClientMainPage from '../ClientsPages/ClientMainPage';
import { useSelector } from 'react-redux';
import TeamDashboard from './TeamPanel/TeamDashboard';

const Dashboard = () => {
  const user = useSelector((state) => state.auth.user);
  const isClient = hasRole(user, 'client')
  if(isClient){
    return <ClientMainPage/>
  }
  const isTeam = hasRole(user, 'team')
  if(isTeam){
    return <TeamDashboard/>
  }

  return (
    <div className="db-main-container">
      <div className="db-title-container">
      <p className="main-page-title">
        <span className="main-page-title-icon">
          <i class="fi fi-rr-objects-column"></i>

        </span>
        Dashboard
      </p>
      <DashboardWelcome />
      </div>

      <div className="db-main-banner-container">
      <div className="db-chart-container">
      <DashboardChart />
      </div>

      <div className="db-daily-overview">
      <DailyOverview />
      </div>
      </div>

      <div className="db-tasks-widget">
        <DashboardProjects />
      </div>

      <div className="db-tasks-widget">
        <DashboardTeamMembers />
      </div>


    </div>

  );
};

export default Dashboard;
