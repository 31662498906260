import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Modal, Checkbox, Input, Button, Form, Typography, message } from 'antd';
import StripeProvider from '../../../Components/StripeProvider';
import { buySubscription, createCheckOutSession, saveCardAction } from '../../../services/payment.service';

const { Text } = Typography;

function CheckoutForm({ subscription, setSubscription, plan }) {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [saveCard, setSaveCard] = useState(false);
    const [cardTitle, setCardTitle] = useState('');
    const businessTaken = subscription && subscription.package === "Business"

    const onClose = (e) => {
        setIsOpen(false);
    };
    const handleSaveCardChange = (e) => {
        setSaveCard(e.target.checked);
    };

    const handleCardTitleChange = (e) => {
        setCardTitle(e.target.value);
    };
    const reLoad = () => {
        // setTimeout(() => {
        //     window.location.reload()
        // }, 3000)
    };

    const handleSubmit = async () => {

        if (!stripe || !elements) {
            message.error("Please, wail");
            return; // Stripe.js has not yet loaded.
        }
        if (saveCard && !cardTitle) {
            message.error('Card Title Is Required');
            return; // Stripe.js has not yet loaded.
        }

        setLoading(true);
        try {

            const clientSecret = await createCheckOutSession({
                time: 1,
                interval: "Month",
                team: 1,
                client: 1,
                project: 1
            }).then(res => (res.data.client_secret))
            const cardElement = elements.getElement(CardElement);
            await handlePaymentConfirmation(clientSecret, cardElement)
            await handleSaveCard(cardElement)
        } catch (error) {
            console.error('[error]', error);
        } finally {

            setLoading(false);
            onClose(); // Close the modal after processing
            reLoad()
        };
    }
    const handleSaveCard = async (cardElement) => {

        if (saveCard && cardTitle) {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (error) {
                console.log('[error]', error);
                setLoading(false);
            } else {
                console.log('[PaymentMethod]', paymentMethod);
                await saveCardAction(cardTitle, paymentMethod.id);
                message.success("The Card Was Saved")
            }
        }
    };
    const handlePaymentConfirmation = async (clientSecret, cardElement) => {
        // confirm Payment

        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
            },
        });
        console.log("Payment Result", result);
        const { error, paymentIntent } = result;
        if (error) {
            message.error(error.message);
        } else if (paymentIntent.status === 'succeeded') {
            try {
                const res = await buySubscription(paymentIntent.id)
                setSubscription(res.data.subscription)
                message.success("The Payment Was Successful")
            } catch (err) {
                console.error("buySubscription error", err)
                message.error('Subscription Activation failed');
            }
        } else {
            message.error('Payment failed');
        }
    };


    return (
        <>
            <button className="ap-select-button" onClick={() => setIsOpen(true)}>{businessTaken ? "Extend Time" : "Get Started"}</button>
            {isOpen&&<Modal
                title="Payment Details"
                open={isOpen}
                onOk={handleSubmit}
                onCancel={onClose}
                footer={[
                    <Button key="back" onClick={onClose}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" loading={isLoading} onClick={handleSubmit}>
                        Pay ${plan.price}
                    </Button>,
                ]}
            >
                <Form layout="vertical">
                    <Form.Item label="Card Information">
                        <CardElement />
                    </Form.Item>
                    <Form.Item>
                        <Checkbox checked={saveCard} onChange={handleSaveCardChange}>
                            Save this card for future transactions
                        </Checkbox>
                    </Form.Item>
                    {saveCard && (
                        <Form.Item label="Card Holder Name">
                            <Input placeholder="My Debit Card" value={cardTitle} onChange={handleCardTitleChange} />
                        </Form.Item>
                    )}
                    <Text type="secondary">Aio Solution doesn't store any card information. Card details are managed by Stripe.</Text>
                </Form>
            </Modal>}
        </>
    );
}
const PaymentModal = (props) => {
    return (
        <StripeProvider>
            <CheckoutForm {...props} />
        </StripeProvider>
    )
}

export default PaymentModal;
