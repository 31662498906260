import React from 'react';
import SingleAvatar2 from './SingleAvatar2';
import { getAvatarUrl } from '../services/auth.service';
import { Tooltip } from 'antd';

const SingleUserAvatar = ({ user, label }) => {
  const title = (label || "") + user?.f_name + " " + user?.l_name
  return (
    <>
      {user && user.avatar ? (
        <Tooltip title={title}>
          <img src={getAvatarUrl(user.avatar)} alt={user?.f_name + " " + user?.l_name} />
        </Tooltip>
      ) : (
        <SingleAvatar2 name={title} size="150" fontSize="64" />
      )}
    </>
  );
};

export default SingleUserAvatar;
