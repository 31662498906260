import teamMembers from './TeamSampleData';
import clientsData from './SampleClients';

const projectsData = [
  {
    name: 'Apple Social Media Marketing',
    client: clientsData[0],
    accountManager: clientsData[0].accountManager,
    teamMembers: [teamMembers[0], teamMembers[1], teamMembers[2], teamMembers[4], teamMembers[7], teamMembers[8]],
    notes: 'Initial phase completed.',
    projectStatus: 'In Progress',
    tasksOverview: { total: 50, notStarted: 10, inProgress: 30, completed: 10 },
    startDate: '2024-01-10',
    endDate: '2024-06-15',
    estimatedHours: 500,
    usedHours: 300,
  },
  {
    name: 'Coca Cola Website Redesign',
    client: clientsData[1],
    accountManager: clientsData[1].accountManager,
    teamMembers: [teamMembers[3], teamMembers[4], teamMembers[5]],
    notes: '',
    projectStatus: 'Not Started',
    tasksOverview: { total: 40, notStarted: 30, inProgress: 5, completed: 5 },
    startDate: '2024-02-05',
    endDate: '2024-07-20',
    estimatedHours: 400,
    usedHours: 0,
  },
  {
    name: 'Microsoft Cloud Migration',
    client: clientsData[2],
    accountManager: clientsData[2].accountManager,
    teamMembers: [teamMembers[6], teamMembers[7], teamMembers[8], teamMembers[2], teamMembers[8]],
    notes: 'Pending approval from client.',
    projectStatus: 'On Hold',
    tasksOverview: { total: 60, notStarted: 20, inProgress: 10, completed: 30 },
    startDate: '2024-03-01',
    endDate: '2024-08-30',
    estimatedHours: 600,
    usedHours: 700,
  },
  {
    name: 'Tesla App Development',
    client: clientsData[3],
    accountManager: clientsData[3].accountManager,
    teamMembers: [teamMembers[1], teamMembers[3], teamMembers[6]],
    notes: '',
    projectStatus: 'Completed',
    tasksOverview: { total: 70, notStarted: 0, inProgress: 0, completed: 70 },
    startDate: '2024-01-15',
    endDate: '2024-05-10',
    estimatedHours: 700,
    usedHours: 700,
  },
  {
    name: 'Amazon Logistics Optimization',
    client: clientsData[4],
    accountManager: clientsData[4].accountManager,
    teamMembers: [teamMembers[2], teamMembers[4]],
    notes: '',
    projectStatus: 'In Progress',
    tasksOverview: { total: 80, notStarted: 20, inProgress: 50, completed: 10 },
    startDate: '2024-04-10',
    endDate: '2024-11-20',
    estimatedHours: 800,
    usedHours: 450,
  },
  {
    name: 'Facebook Ad Campaign',
    client: clientsData[5],
    accountManager: clientsData[5].accountManager,
    teamMembers: [teamMembers[0], teamMembers[5], teamMembers[8]],
    notes: 'Client meeting scheduled for next week.',
    projectStatus: 'In Progress',
    tasksOverview: { total: 90, notStarted: 10, inProgress: 60, completed: 20 },
    startDate: '2024-02-25',
    endDate: '2024-09-15',
    estimatedHours: 900,
    usedHours: 550,
  },
  {
    name: 'Google Analytics Integration',
    client: clientsData[6],
    accountManager: clientsData[6].accountManager,
    teamMembers: [teamMembers[1], teamMembers[6], teamMembers[3]],
    notes: '',
    projectStatus: 'On Hold',
    tasksOverview: { total: 100, notStarted: 50, inProgress: 30, completed: 20 },
    startDate: '2024-05-01',
    endDate: '2024-12-01',
    estimatedHours: 1000,
    usedHours: 300,
  },
  {
    name: 'Netflix User Experience Improvement',
    client: clientsData[7],
    accountManager: clientsData[7].accountManager,
    teamMembers: [teamMembers[2], teamMembers[4], teamMembers[5]],
    notes: 'Development phase ongoing.',
    projectStatus: 'In Progress',
    tasksOverview: { total: 110, notStarted: 20, inProgress: 70, completed: 20 },
    startDate: '2024-03-15',
    endDate: '2024-10-30',
    estimatedHours: 1100,
    usedHours: 650,
  },
  {
    name: 'Spotify Playlist Optimization',
    client: clientsData[8],
    accountManager: clientsData[8].accountManager,
    teamMembers: [teamMembers[3], teamMembers[6], teamMembers[7], teamMembers[4], teamMembers[5]],
    notes: '',
    projectStatus: 'Completed',
    tasksOverview: { total: 120, notStarted: 0, inProgress: 0, completed: 120 },
    startDate: '2024-01-20',
    endDate: '2024-04-25',
    estimatedHours: 1200,
    usedHours: 1200,
  },
  {
    name: 'Airbnb Booking System Revamp',
    client: clientsData[9],
    accountManager: clientsData[9].accountManager,
    teamMembers: [teamMembers[1], teamMembers[5], teamMembers[8]],
    notes: '',
    projectStatus: 'Not Started',
    tasksOverview: { total: 130, notStarted: 80, inProgress: 30, completed: 20 },
    startDate: '2024-02-10',
    endDate: '2024-08-25',
    estimatedHours: 1300,
    usedHours: 100,
  },
];

export default projectsData;
