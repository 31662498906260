import axios from "axios";
import { apiBaseUrl } from "../config";
import { getApiPrefix } from "./auth.service";

export function fetchTasks(page = 0, rowsPerPage = 20, sortByColumns = [], role = 'super-admin') {
    const query = new URLSearchParams("")
    if (page) {
        query.set('page', page.toString());
    }
    if (rowsPerPage) {
        query.set('per_page', rowsPerPage.toString());
    }
    if (sortByColumns && sortByColumns.length > 0) {
        sortByColumns.forEach(element => {
            if (element.key && element.order)
                query.set(element.key, element.order);
        });
    }
    let url = [apiBaseUrl + getApiPrefix(role) + "tasks", query.toString()].join("/?")
    return axios.get(url, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}

export function fetchTask(taskId) {
    return axios.get(apiBaseUrl + `tasks/${taskId}`, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function updateTask(taskId, values) {
    return axios.put(apiBaseUrl + `tasks/${taskId}`, values, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function removeMember(taskId, user_id) {
    return axios.delete(apiBaseUrl + `tasks/${taskId}/members/${user_id}`, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function addMember(taskId, user_id) {
    return axios.post(apiBaseUrl + `tasks/${taskId}/member`, { user_id }, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function addTask(name, client_id) {
    return axios.post(apiBaseUrl + `tasks`, { name, client_id }, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}

export function createTask(values) {
    return axios.post(apiBaseUrl + 'tasks', values, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}

export function taskAutocomplete(value) {
    return axios.get(apiBaseUrl + `tasks/autocomplete/${value}`, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
