import React, { createContext, useState, useContext } from 'react';
const SubsctiptionsContext = createContext();

export const SubsctiptionsProvider = ({ children }) => {
  const [sortByColumns, setSortByColumns] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSubsctiption, setSelectedSubsctiption] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);


  return (
    <SubsctiptionsContext.Provider
      value={{
        currentPage, setCurrentPage,
        selectedSubsctiption, setSelectedSubsctiption,
        items, setItems,
        loading, setLoading,
        totalRows, setTotalRows,
        sortByColumns, setSortByColumns,
        rowsPerPage, setRowsPerPage
      }}
    >
      {children}
    </SubsctiptionsContext.Provider>
  );
};

export const useSubsctiptionsContext = () => {
  return useContext(SubsctiptionsContext)
}
