// DailyOverview.js

import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { teamDashboardService } from '../../../services/dashboard.service';
import { hoursToString } from '../../../services/common.functions';


const DailyOverview = () => {
  const [loading, setLoading] = useState(true)
  const [overview, setOverview] = useState([])
  const data = [
    { icon: 'fi fi-rr-list-check', color: 'db-green', value: overview?.tasks, label: 'Tasks:' },
    { icon: 'fi fi-rs-note', color: 'db-green', value: overview?.tasks_in_progress, label: 'Tasks In Pregress:' },
    { icon: 'fi fi-rr-users', color: 'db-green', value: overview?.clients, label: 'Clients:' },
    { icon: 'fi fi-rr-check-circle', color: 'db-gray', value: overview?.projects, label: 'Projects:' },
    { icon: 'fi fi-rr-alarm-clock', color: 'db-purple', value: overview.hoursAllocated ? hoursToString(overview.hoursAllocated):"", label: 'Hours Allocated:' },
    { icon: 'fi fi-rr-time-check', color: 'db-green', value: overview.hoursWorked ? hoursToString(overview.hoursWorked):"", label: 'Hours Worked:' },
  ];
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(true)
      teamDashboardService.fetchOverviewData().then((response) => {
        setOverview(response.data);
      }).catch(error => {
        console.error("Fetch Dashboad Data Failed", error);
      }).finally(() => {
        setLoading(false)
      })
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, []);

  return (
    <div className="db-overview-container">
      <h2 className="db-overview-title">Overview</h2>
      <div className="db-overview-list">
        {data.map((item, index) => (
          <div className="db-overview-item" key={index}>
            <div className={`db-icon-container ${item.color}`}>
              <i className={item.icon}></i>
            </div>
            <div className="db-overview-details">
              <p className="db-overview-label">{item.label}</p>
              <h3 className="db-overview-value">{loading ? <Skeleton paragraph={false} /> : item.value}</h3>

            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DailyOverview;
