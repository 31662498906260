import React from 'react';
import { useProjectContext } from '../../Context/ProjectContext';
import './WorkPages.less';
import TableSingle from './TableSingle';

const PGGroupedTable = () => {
  const { taskGroups } = useProjectContext();
  return <>
    {taskGroups.map((item, index) => (<TableSingle key={index} groupIndex={index} />))}
  </>
};

export default PGGroupedTable;
