import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../custom.less';

const TextEditor = ({ value, onChange }) => {
  const [editorHtml, setEditorHtml] = useState('');

  useEffect(() => {
    if (value) {
      setEditorHtml(value);
    }
  }, [value]);

  const handleChange = (html) => {
    setEditorHtml(html);
    if (onChange) {
      onChange(html);
    }
  };

  return (
    <ReactQuill
      value={editorHtml}
      onChange={handleChange}
      modules={TextEditor.modules}
      formats={TextEditor.formats}
      placeholder="Write something..."
    />
  );
};

TextEditor.modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image'],
    ['clean']
  ],
};

TextEditor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image'
];

export default TextEditor;
