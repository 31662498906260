// File Path : src\services\search.service.js

import axios from "axios";
import { apiBaseUrl } from "../config";

export function superAdminSearch(niddle) {
    const query = new URLSearchParams("")
    query.set('query', niddle.toString());
    return axios.get([apiBaseUrl + "search", query.toString()].join("/?"), { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function companySearch(niddle) {
    const query = new URLSearchParams("")
    query.set('query', niddle.toString());
    return axios.get([apiBaseUrl + "company/search", query.toString()].join("/?"), { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}