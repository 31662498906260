import React from 'react';
import { List } from 'antd';

const TaskAttachments = ({ attachments }) => {
  return (
    <div className="task-attachments">
      <List
        dataSource={attachments}
        renderItem={item => (
          <List.Item>
            <a href={URL.createObjectURL(item.originFileObj)} download={item.name}>
              {item.name}
            </a>
          </List.Item>
        )}
      />
    </div>
  );
};

export default TaskAttachments;
