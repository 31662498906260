import React from 'react';
import { Typography } from 'antd';
import { getBannerUrl, getLogoUrl } from '../../../services/company.service';
import { appSubDir } from '../../../config';

const { Title } = Typography;

const ClientMainBanner = ({ company }) => {
  return (
    <div className="ct-banner-container">
      <div className="ct-banner-background">
        <img src={company.banner ? getBannerUrl(company.banner) : `${appSubDir}assets/images/profile-banner.webp`} alt="Banner" className="ct-banner-image" />
      </div>
      <div className="ct-banner-overlay"></div>
      <div className="ct-banner-content">
        <div className="ct-logo-container">
          <img src={company.logo ? getLogoUrl(company.logo) : (appSubDir + "assets/images/150.webp")} alt="CMG Logo" className="ct-logo-image" />
        </div>
        <div className="ct-banner-title">
          <Title level={2} className="ct-title">{company.company_name}</Title>
        </div>
      </div>
    </div>
  );
};

export default ClientMainBanner;
