import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, message, Space, Row, Col } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import DefaultModal from './DefaultModal';
import { createAutomation, requestAutomation, updateAutomation } from '../services/automation.service';
import { useSelector } from 'react-redux';
import { hasRole } from '../services/auth.service';
import CompanyPicker from '../Components/CompanyPicker';

const { TextArea } = Input;
const { Option } = Select;

const AutomationFormModal = ({ isVisible, setIsVisible, onSuccess, initialValues }) => {
  const [form] = Form.useForm();
  const user = useSelector((state) => state.auth.user);
  const isSuperAdmin = hasRole(user, 'super-admin');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const handleSubmit = (values) => {
    setLoading(true);
    const api = initialValues
      ? (data) => updateAutomation(initialValues.id, data)
      : (data) => (isSuperAdmin ? createAutomation(data) : requestAutomation(data));

    api(values)
      .then((res) => {
        message.success(
          initialValues
            ? 'Your Automation has been updated!'
            : isSuperAdmin
              ? 'Your Automation has been created!'
              : 'Your automation request has been sent!'
        );
        form.resetFields();
        handleCancel();
        onSuccess && onSuccess(res.data);
      })
      .catch((error) => {
        if (!error.response) {
          message.error('Network error or server is unreachable');
          return;
        }
        if (error.response.status === 422) {
          const validationErrors = error.response.data.errors;
          const errorFields = Object.keys(validationErrors).map((key) => ({
            name: key,
            errors: validationErrors[key],
          }));
          form.setFields(errorFields);
        } else {
          message.error(error.response.data.message || 'An unexpected error occurred');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setIsVisible(false);
  };

  const footerContent = [
    <Button className="modal-cancel-button" key="back" onClick={handleCancel}>
      Cancel
    </Button>,
    <Button key="submit" loading={loading} className="modal-save-button" type="primary" onClick={() => form.submit()}>
      {initialValues ? 'Update' : isSuperAdmin ? 'Create' : 'Send Request'}
    </Button>,
  ];

  return (
    <DefaultModal
      isVisible={isVisible}
      toggleModal={handleCancel}
      title={initialValues ? 'Edit Automation' : isSuperAdmin ? 'Create New Automation' : 'Request New Automation'}
      icon="code-pull-request"
      footerContent={footerContent}
      className="request-automation-modal"
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit} className="main-modal-form request-automation-form">
        {isSuperAdmin && <CompanyPicker form={form} initialOption={initialValues ? initialValues.client : undefined} rules={[{ required: true, message: 'Please enter a company name' }]} />}
        <Form.Item name="title" label="Automation Title" rules={[{ required: true, message: 'Please enter the automation title' }]}>
          <Input placeholder="Enter automation title" />
        </Form.Item>
        <Row gutter={16}>
          <Col span={isSuperAdmin ? 12 : 24}>
            <Form.Item name="description" label="Automation Description" rules={[{ required: true, message: 'Please enter the automation description' }]}>
              <TextArea placeholder="Enter automation description" rows={4} />
            </Form.Item>
          </Col>
          {isSuperAdmin && <Col span={12}>
            <Form.Item name="notes" label="Additional Notes">
              <TextArea placeholder="Enter any additional notes" rows={4} />
            </Form.Item>
          </Col>}
        </Row>
        <Row gutter={16}>
          <Col span={isSuperAdmin ? 12 : 24}>
            <Form.Item name="priority_level" label="Priority Level" rules={[{ required: true, message: 'Please select the priority level' }]}>
              <Select placeholder="Select priority level">
                <Option value="low">Low</Option>
                <Option value="medium">Medium</Option>
                <Option value="high">High</Option>
              </Select>
            </Form.Item>
          </Col>
          {isSuperAdmin && (
            <Col span={12}>
              <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Please select status' }]}>
                <Select placeholder="Select Status">
                  {['Active', 'Inactive', 'Requested', 'Cancelled'].map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        {isSuperAdmin && <Form.List
          name="steps"
          rules={[
            {
              validator: async (_, steps) => {
                if (!steps || steps.length < 1) {
                  return Promise.reject(new Error('At least one step is required'));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  key={field.key}
                  label={index === 0 ? 'Steps' : ''}
                  required={false}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[{ required: true, message: 'Please input step' }]}
                    noStyle
                  >
                    <Input placeholder="Input step" style={{ width: '60%' }} />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <Button
                      type="danger"
                      onClick={() => remove(field.name)}
                      icon={<MinusCircleOutlined />}
                    >
                      Remove
                    </Button>
                  ) : null}
                  {fields.length === 1 ? (
                    <Button type="dashed" onClick={() => add()}>
                      Add Step
                    </Button>
                  ) : null}
                </Form.Item>
              ))}
              {fields.length !== 1 && (
                <Form.Item>
                  <Button type="dashed" onClick={() => add()}>
                    Add Step
                  </Button>
                </Form.Item>
              )}
            </>
          )}
        </Form.List>}
      </Form>
    </DefaultModal>
  );
};

export default AutomationFormModal;
