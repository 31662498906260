// DashboardWelcome.js
import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

const DashboardWelcome = () => {
  const user = useSelector((state) => state.auth.user);
  const todayDate = moment().format('dddd, MMMM D');

  return (
    <div className="db-welcome-container">
      <p className="db-date"><i className="fi fi-rr-calendar"></i> {todayDate}</p>
      <p className="db-greeting">Hi {user?.f_name}, welcome back!</p>
    </div>
  );
};

export default DashboardWelcome;
