import React, { useRef } from 'react';
import SingleAvatar2 from '../../Components/SingleAvatar2';
import { useSelector } from 'react-redux';
import EditPersonalDetailsModal from '../../Modals/EditPersonalDetailsModal';
import { getAvatarUrl, uploadAvatar } from '../../services/auth.service';
import { useDispatch } from 'react-redux';
import { setAvatar } from '../../stores/auth.slice';
import { message } from 'antd';
import EditPasswordModal from '../../Modals/EditPasswordModal';

const PersonalDetails = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch()
  const isUploading = useRef(false)
  const fileRef = useRef(null)

  if (!user) {
    return null
  }
  const role = user.roles ? user.roles[0] : {}

  const onFIleSelect = (event) => {
    console.log("New File selected")
    if (isUploading.current) {
      return
    }
    const avatar = event.target.files[0];
    if (avatar) {
      const formData = new FormData()
      formData.append('avatar', avatar)
      isUploading.current = true
      uploadAvatar(formData).then((res) => {
        dispatch(setAvatar(res.data))
      }).catch(error => {
        if (!error.response) {
          message.error('Network error or server is unreachable');
          return;
        }
        if (error.response.status === 422) {
          // Validation errors handling as before
          const validationErrors = error.response.data.errors.avatar;
          validationErrors.forEach(element => {
            message.error(element)
          })
        } else {
          // Non-validation errors
          message.error(error.response.data.message || 'An unexpected error occurred');
        }
      }).finally(() => {
        isUploading.current = false
      })
    }
  };
  return <>

    <div className="ap-combined-details-title-row">
      <div className="ap-combined-details-title">Personal Details</div>
      <div className='buttons'>
        <EditPersonalDetailsModal />
        <EditPasswordModal />
      </div>
    </div>

    <div className="ap-personal-details-container">
      <div className="ap-personal-details">
        <div className="ap-company-content">
          <div className="ap-company-logo">
            <div className="ap-company-logo-image">
              {user && user.avatar ? (
                <img src={getAvatarUrl(user.avatar)} alt={user?.f_name + " " + user?.l_name} />
              ) : (
                <SingleAvatar2 name={user?.f_name + " " + user?.l_name} size="150" fontSize="64" />
              )}
            </div>
            <input ref={fileRef} type="file" onChange={onFIleSelect} style={{ display: 'none' }} />
            <label onClick={() => fileRef.current?.click()} className="ap-upload-logo-button">
              <i className="fi fi-rr-upload"></i> Upload Picture
            </label>
          </div>
          <div className="ap-company-info">
            <div className="ap-info-column">
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-user"></i>
                </div>
                <span>Full Name: {user?.f_name} {user?.l_name}</span>
              </div>
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-briefcase"></i>
                </div>
                <span>Role: {role?.display_name}</span>
              </div>
            </div>
            <div className="ap-info-column">
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-phone-call"></i>
                </div>
                <span>Phone Number: {user?.phone}</span>
              </div>
              <div className="ap-info-item">
                <div className="ap-profile-icon-container">
                  <i className="fi fi-rr-envelope"></i>
                </div>
                <span>Email Address: {user?.email}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
};

export default PersonalDetails;
