import React from 'react';
import { Link } from 'react-router-dom';
import { appSubDir } from '../../../config';

const ClientInfoColumn = {
  title: 'Client',
  key: 'clientInfo',
  responsive: ['lg'],
  className: 'ct-client-info',
  render: (text, record) => (
    <div className="ct-client-info-cell">
      <div className="ct-company-name"><Link to={appSubDir + "clients/" + record.id + "/profile"}><i class="fi fi-rr-city"></i>{record.company_name}</Link></div>
      <div className="ct-client-name"><i class="fi fi-rr-user"></i>{record.contact.f_name} {record.contact.l_name}</div>
    </div>
  ),
};

export default ClientInfoColumn;
