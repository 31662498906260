// ManageTeamModal.js

import React, { useState } from 'react';
import { Tabs, List, Button, Input, Form } from 'antd';
import SingleAvatar2 from '../Components/SingleAvatar2';
import DefaultModal from './DefaultModal';
import '../Components/UserAvatars.less';
import './Modals.less';

const { TabPane } = Tabs;

const ManageTeamModal = ({ isVisible, selectedProject, toggleModal, teamMembers, clientsData }) => {
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [form] = Form.useForm();

  const projectTeamMembers = selectedProject ? selectedProject.members.map(id => teamMembers.find(member => member.id === id)) : [];
  const projectClients = selectedProject ? selectedProject.clients.map(id => clientsData.find(client => client.id === id)) : [];

  const handleAddUser = () => {
    setAddUserVisible(!addUserVisible);
  };

  const handleFormSubmit = (values) => {
    // console.log('Form values:', values);
    // Logic to send invite
    form.resetFields();
    setAddUserVisible(false);
  };

  const teamTabContent = (
    <>
      <div className="add-user-bar">
        <div className="add-user-search-bar">
          <Input.Search placeholder="Find members" style={{ width: '100%' }} />
        </div>
        <Button type="link" onClick={handleAddUser}>
          <i className="fi fi-rr-user-add" style={{ marginRight: '5px' }}></i>
          Add people
          {addUserVisible ? (
            <i className="fi fi-rr-angle-small-up"></i>
          ) : (
            <i className="fi fi-rr-angle-small-down"></i>
          )}
        </Button>
      </div>
      {addUserVisible && (
        <Form form={form} layout="vertical" onFinish={handleFormSubmit} className="invite-form">
          <div className="form-row">
            <Form.Item name="name" rules={[{ required: true, message: 'Please enter the name' }]} className="form-item">
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item name="email" rules={[{ required: true, message: 'Please enter the email' }]} className="form-item">
              <Input placeholder="Email" />
            </Form.Item>
          </div>
          <Form.Item className="form-item-submit">
            <Button type="primary" htmlType="submit">Send Invite</Button>
          </Form.Item>
        </Form>
      )}
      <List
        itemLayout="horizontal"
        dataSource={projectTeamMembers}
        renderItem={member => (
          <List.Item actions={[<Button className="remove-button" type="link" danger key="remove">Remove</Button>]}>
            <List.Item.Meta
              avatar={<SingleAvatar2 name={member.name} />}
              title={member.name}
              description={member.jobTitle}
            />
          </List.Item>
        )}
      />
    </>
  );

  const clientsTabContent = (
    <>
      <div className="add-user-bar">
        <Button type="link" onClick={handleAddUser} style={{ marginLeft: '10px' }}>
          <i className="fi fi-rr-user-add" style={{ marginRight: '5px' }}></i>
          Add people
          {addUserVisible ? (
            <i className="fi fi-rr-angle-small-up" style={{ marginLeft: '5px' }}></i>
          ) : (
            <i className="fi fi-rr-angle-small-down" style={{ marginLeft: '5px' }}></i>
          )}
        </Button>
      </div>
      {addUserVisible && (
        <Form form={form} layout="vertical" onFinish={handleFormSubmit} className="invite-form">
          <div className="form-row">
            <Form.Item name="name" rules={[{ required: true, message: 'Please enter the name' }]} className="form-item">
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item name="email" rules={[{ required: true, message: 'Please enter the email' }]} className="form-item">
              <Input placeholder="Email" />
            </Form.Item>
          </div>
          <Form.Item className="form-item-submit">
            <Button type="primary" htmlType="submit">Send Invite</Button>
          </Form.Item>
        </Form>
      )}
      <List
        itemLayout="horizontal"
        dataSource={projectClients}
        renderItem={client => (
          <List.Item actions={[<Button className="remove-button" type="link" danger key="remove">Remove</Button>]}>
            <List.Item.Meta
              avatar={<SingleAvatar2 name={client.clientName} />}
              title={client.clientName}
              description={client.clientEmail}
            />
          </List.Item>
        )}
      />
    </>
  );

  const footerContent = [
    <Button className="modal-cancel-button" key="back" onClick={toggleModal}>
      Cancel
    </Button>,
    <Button key="submit" className="modal-save-button" onClick={toggleModal}>
      Save
    </Button>,
  ];

  return (
    <DefaultModal
      isVisible={isVisible}
      toggleModal={toggleModal}
      title={`Manage Team: ${selectedProject ? selectedProject.name : ''}`}
      icon="team-check"
      footerContent={footerContent}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Team" key="1">
          {teamTabContent}
        </TabPane>
        <TabPane tab="Clients" key="2">
          {clientsTabContent}
        </TabPane>
      </Tabs>
    </DefaultModal>
  );
};

export default ManageTeamModal;
