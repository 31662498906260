import React from 'react';
import { Button } from 'antd';
import './UserAvatars.less';
import { useSelector } from 'react-redux';
import { canEditProject } from '../services/project.service';

const AddMemberButton = ({ project, handleAddMember }) => {
  const user = useSelector((state) => state.auth.user);
  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '34px',
    height: '34px',
    padding: 0,
  };

  return (
    <Button

      onClick={() => handleAddMember(project)}
      className="add-user-button"
      style={buttonStyle}
    >
      <i class={canEditProject(project, user) ? "fi fi-rr-plus" : "fi fi-rr-eye"}></i>
    </Button>
  );
};

export default AddMemberButton;
