// Subsctiptions.js

import React, { useEffect } from 'react';
import { Pagination, Table } from 'antd';
import { SubsctiptionsProvider, useSubsctiptionsContext } from '../../Context/SubsctiptionsContext';
import { fetchList } from '../../services/subscription.service';
import SkeletonTable from '../../Components/SkeletonTable';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { hasRole } from '../../services/auth.service';
import TableHeadOptions from './Components/TableHeadOptions';
import { dateFormat } from '../../services/common.functions';
import StatusColumn from './TableColumns/StatusColumn';

const columns = [
  { title: '#', dataIndex: 'id' },
  { title: 'Client', render: (text, record) => (record.client.company_name) },
  { title: 'Package', dataIndex: 'package' },
  { title: 'Expires At', dataIndex: 'expires_at', render: (text) => (text ? dateFormat.long(text) : "Never") },
  { title: 'Max Team', dataIndex: 'team_mc' },
  { title: 'Max Client', dataIndex: 'client_mc' },
  { title: 'Max Project', dataIndex: 'project_mc' },
  StatusColumn,
];
const Loading = <div style={{ padding: '24px 0' }}>
  <div style={{ overflowX: 'auto' }}>
    <SkeletonTable rows={columns.length} columns={5} />
  </div>
</div>

const MainComponent = () => {
  const user = useSelector((state) => state.auth.user);
  const isCLient = hasRole(user, 'client')
  const { client_id } = useParams()
  const {
    currentPage, setCurrentPage,
    items, setItems,
    loading, setLoading,
    totalRows, setTotalRows,
    sortByColumns,
    rowsPerPage
  } = useSubsctiptionsContext();


  // Page Data Loader
  function loadData() {
    setLoading(true);

    fetchList(currentPage, rowsPerPage, sortByColumns).then((res) => {
      setItems(res.data.data);
      setTotalRows(res.data.total);
    }).finally(() => {
      setLoading(false);
    });
  }
  // Fetch clients from the backend
  useEffect(() => {
    const timer = setTimeout(() => {
      loadData()
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [client_id, currentPage, rowsPerPage, sortByColumns]);


  return (
    <div className="at-automations-container">
      <p className="main-page-title">
        <span className="main-page-title-icon">
          <i className="fi fi-rr-user-robot"></i>
        </span>
        All Subsctiptions
      </p>
      {loading ? Loading : <>
        <TableHeadOptions />
        <div className="main-table-main-container">
          <div className="main-table-container">

            <Table
              columns={columns}
              dataSource={items}
              pagination={false}
              rowClassName={(record, index) =>
                index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
              }
            />
          </div>
          <Pagination
            current={currentPage}
            pageSize={rowsPerPage}
            total={totalRows}
            onChange={setCurrentPage}
            className="at-pagination"
          />
        </div>
      </>}
    </div>
  );
};

const Subsctiptions = () => {
  return (
    <SubsctiptionsProvider>
      <MainComponent />
    </SubsctiptionsProvider>
  )
}
export default Subsctiptions;
