import React from 'react';
import { Descriptions, Input, DatePicker, Select } from 'antd';
import SingleAvatar2 from '../../Components/SingleAvatar2';
import dayjs from 'dayjs';

const { Option } = Select;

const ModalSubtasks = ({ task, onTaskUpdate, project }) => {
  function handleSubtaskChange(subtaskIndex, key, value) {
    onTaskUpdate(key, value, subtaskIndex);
  };

  return (
    <div className="tdm-modal-subtasks">
      <Descriptions bordered column={1} title="Subtasks" className="tdm-descriptions">
        {task.subtasks.map((subtask, subTaskIndex) => (
          <Descriptions.Item key={subTaskIndex} className="tdm-descriptions-item">
            <Input
              value={subtask.taskName}
              onChange={(e) => handleSubtaskChange(subTaskIndex, 'taskName', e.target.value)}
              style={{ width: '68%' }}
              className="border-1 tdm-subtask-input "
            />
            <DatePicker
              value={subtask.dueDate ? dayjs(subtask.dueDate) : null}
              onChange={(date) => handleSubtaskChange(subTaskIndex, 'dueDate', date)}
              format="YYYY-MM-DD"
              getPopupContainer={(trigger) => trigger.parentNode}
              className=" border-1 tdm-due-date-picker"
              style={{ width: '20%' }}
            />
            <Select
              value={subtask.assignee}
              onChange={(value) => handleSubtaskChange(subTaskIndex, 'assignee', value)}
              style={{ width: '10%' }}
              className="border-1 tdm-assignee-select"
              dropdownClassName="tdm-assignee-dropdown"
            >
              {project.members.map(member => (
                <Option key={member.user_id} value={member.user_id}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SingleAvatar2 name={member.user.f_name + " " + member.user.l_name} />
                    <span style={{ marginLeft: '8px' }}>{member.user.f_name + " " + member.user.l_name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Descriptions.Item>
        ))}
      </Descriptions>
    </div>
  )
};

export default ModalSubtasks;
