import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { useTasksContext } from '../../Context/TasksContext';

const columns = [
  {
    title: 'Status',
    key: 'status'
  },
  {
    title: 'Date Created',
    key: 'created_date'
  },
  {
    title: 'Due Date',
    key: 'due_date'
  }
];

const orders = [
  {
    key: 'any',
    title: 'Any'
  },
  {
    key: 'asc',
    title: 'A - Z'
  },
  {
    key: 'desc',
    title: 'Z - A'
  }
];

const TableRowsOptions = () => {
  const { rowsPerPage, setRowsPerPage } = useTasksContext();
  const menu = () => (
    <Menu onClick={(e) => setRowsPerPage(Number(e.key))}>
      <Menu.Item key="10">10</Menu.Item>
      <Menu.Item key="20">20</Menu.Item>
      <Menu.Item key="30">30</Menu.Item>
      <Menu.Item key="50">50</Menu.Item>
      <Menu.Item key="100">100</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown dropdownRender={menu} trigger={['click']}>
      <Button className="ct-rows-button">
        {rowsPerPage} <i className="fi fi-rr-angle-small-down"></i>
      </Button>
    </Dropdown>
  );
};

const SortingMenuItem = ({ column }) => {
  const { sortByColumns, setSortByColumns } = useTasksContext()
  const handleMenuClick = (e) => {
    if (e.key === 'any') {
      const index = sortByColumns.findIndex((item) => (item.key === column.key))
      sortByColumns.splice(index, 1)
      setSortByColumns([...sortByColumns])
    } else {
      const sortByColumn = sortByColumns.find((item) => (item.key === column.key))
      if (sortByColumn) {
        sortByColumn.order = e.key
      } else {
        sortByColumns.push({
          ...column, order: e.key
        })
      }
      setSortByColumns([...sortByColumns])
    }
  };
  return (
    <Menu onClick={handleMenuClick}>
      {orders.map((item) => <Menu.Item key={item.key}>{item.title}</Menu.Item>)}
    </Menu>
  );
}
const SortingMenu = ({ column }) => {
  const { sortByColumns } = useTasksContext()
  const sortByColumn = sortByColumns.find((item) => (item.key === column.key))
  const order = sortByColumn ? orders.find((item) => (item.key === sortByColumn.order)) : null
  return <Dropdown
    dropdownRender={() => <SortingMenuItem column={column} />}
    trigger={['click']}
  >
    <Button>
      {column.title + (order ? " : " + order.title : "")} <i className="fi fi-rr-angle-small-down"></i>
    </Button>
  </Dropdown>
};

const TasksTableSort = ({ onAddTaskSuccess }) => {

  return (
    <div className="pt-sort-container">
      <div className="pt-sort-options-row">
        <div className="pt-sort-buttons">
          <span className="pt-sort-text">Sort: </span>
          {columns.map((column, index) => (
            <SortingMenu key={index} column={column} />
          ))}
          <span className="pt-sort-text">Sort: </span>
          <TableRowsOptions />

        </div>
        {/* <div className="pt-rows-options">
          <TaskUpsertModal onSuccess={onAddTaskSuccess} />
        </div> */}
      </div>
    </div>
  );
};

export default TasksTableSort;
