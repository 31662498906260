import React, { useRef, useState } from 'react';
import { useProjectContext } from '../../Context/ProjectContext';
import { Input } from 'antd';
import { beforeSetTasks, sortTaskAction } from '../../Utils/taskActions';
import './PGGroupTitleField.less';
import { updateTaskInBulk } from '../../services/project-task.service';

const PGGroupTitleField = ({ groupIndex }) => {
  const { taskGroups, tasks, setTasks, sortByColumns, project } = useProjectContext();
  const group = taskGroups[groupIndex]
  const currentValue = group.attributes['section']
  const [value, setValue] = useState(currentValue)
  const [showInput, setShowInput] = useState(false)
  const ref = useRef(null)
  if (groupIndex === undefined) return null
  function updateSectionTitle() {
    const taskIds = group.tasks.map(item => item.taskId)
    tasks.forEach(item => {
      if (taskIds.includes(item.taskId)) {
        item.section = value
      }
    })
    if (currentValue !== value) {
      updateTaskInBulk(project.id, { task_ids: taskIds, section: value })
    }
    sortTaskAction(tasks, sortByColumns)
    beforeSetTasks(tasks)
    setTasks([...tasks])
  }
  function onBlur() {
    setShowInput(false)
    updateSectionTitle()
  }
  function onKeyDown(e) {
    if (e.key === 'Enter') {
      updateSectionTitle()
    }
  }
  return <div className='section-title'>
    {showInput ? <Input ref={ref} value={value} onChange={(e) => {
      setValue(e.target.value);
    }} onKeyDown={onKeyDown} onBlur={onBlur} /> :
      <div onClick={() => {
        setShowInput(true)
        setTimeout(() => {
          ref.current.focus()
        }, 1)
      }}>{currentValue ? currentValue : "Untitled Section"}</div>
    }
  </div>
};

export default PGGroupTitleField;
