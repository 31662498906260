import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import TableRowsOptions from '../TableRowsOptions'; // Import the component to handle rows per page
import ProjectUpsertModal from '../ProjectUpsertModal'; // Import a modal component for adding new projects
import { useProjectsContext } from '../../../Context/ProjectsContext'; // Import a context hook for projects

const columns = [
  {
    title: 'Status',
    key: 'status'
  },
  {
    title: 'Task Overview',
    key: 'tasks'
  },
  {
    title: 'Date',
    key: 'date'
  }
];

const orders = [
  {
    key: 'any',
    title: 'Any'
  },
  {
    key: 'asc',
    title: 'A - Z'
  },
  {
    key: 'desc',
    title: 'Z - A'
  }
];

const SortingMenuItem = ({ column }) => {
  const { sortByColumns, setSortByColumns } = useProjectsContext()
  const handleMenuClick = (e) => {
    if (e.key === 'any') {
      const index = sortByColumns.findIndex((item) => (item.key === column.key))
      sortByColumns.splice(index, 1)
      setSortByColumns([...sortByColumns])
    } else {
      const sortByColumn = sortByColumns.find((item) => (item.key === column.key))
      if (sortByColumn) {
        sortByColumn.order = e.key
      } else {
        sortByColumns.push({
          ...column, order: e.key
        })
      }
      setSortByColumns([...sortByColumns])
    }
  };
  return (
    <Menu onClick={handleMenuClick}>
      {orders.map((item) => <Menu.Item key={item.key}>{item.title}</Menu.Item>)}
    </Menu>
  );
}
const SortingMenu = ({ column }) => {
  const { sortByColumns } = useProjectsContext()
  const sortByColumn = sortByColumns.find((item) => (item.key === column.key))
  const order = sortByColumn ? orders.find((item) => (item.key === sortByColumn.order)) : null
  return <Dropdown
    dropdownRender={() => <SortingMenuItem column={column} />}
    trigger={['click']}
  >
    <Button>
      {column.title + (order ? " : " + order.title : "")} <i className="fi fi-rr-angle-small-down"></i>
    </Button>
  </Dropdown>
};

const ProjectsTableSort = () => {

  return (
    <div className="pt-sort-container">
      <div className="pt-sort-options-row">
        <div className="pt-sort-buttons">
          <span className="pt-sort-text">Sort: </span>
          {columns.map((column, index) => (
            <SortingMenu key={index} column={column} />
          ))}

          <div className="pt-add-project-row">
            <div className="pt-rows-text">Rows: </div>
            <TableRowsOptions />
          </div>
        </div>
        <div className="pt-rows-options">
          <ProjectUpsertModal />
        </div>
      </div>
    </div>
  );
};

export default ProjectsTableSort;
