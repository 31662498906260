import React from 'react';
import { Select } from 'antd';
import ClientStatusTags from '../../../Components/ClientStatusTags';
import { updateClient } from '../../../services/client.service';


const { Option } = Select;

const statuses = ['Inactive', 'Free Trial', 'Active', 'Priority'];

const ClientStatusColumn = {
  title: 'Status',
  dataIndex: 'clientStatus',
  key: 'clientStatus',
  className: 'ct-client-status-column',
  render: (text, record) => {
    const handleStatusChange = (status) => {
      // Implement the logic to update the status in your data source
      // console.log(`Status changed to: ${value}`);
      updateClient(record.id, { status })
        .catch((err) => {
          console.log("update clinet notes Failed", err)
        })
    };

    return (
      <Select
        defaultValue={record.status || 'Active'}
        onChange={handleStatusChange}
        className="ct-status-select"
        dropdownClassName="ct-status-select-dropdown"
      >
        {statuses.map((status) => (
          <Option key={status} value={status}>
            <ClientStatusTags status={status} className="ct-status-tag ct-status-tag-dropdown" />
          </Option>
        ))}
      </Select>
    );
  },
};

export default ClientStatusColumn;
