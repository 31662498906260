import React from 'react';
import { Layout, Button, Row, Col, Dropdown, Badge } from 'antd';
import { SortAscendingOutlined, GroupOutlined } from '@ant-design/icons';
import './WorkPages.less';
import FilterSortByOverlay from './FilterSortByOverlay';
import { useProjectContext } from '../../Context/ProjectContext';
import FilterGroupByOverlay from './FilterGroupByOverlay';

const { Header } = Layout;
const FilterSection = () => {
  const { sortByColumns, groupByColumns } = useProjectContext();
  return (
    <Header className="pmt-header">
      <Row justify="start" align="middle">
        <Col>
          <Dropdown dropdownRender={() => <FilterSortByOverlay />} trigger={['click']}>
            <Badge count={sortByColumns.length} overflowCount={99}>
              <Button type="link" icon={<SortAscendingOutlined />} className="pmt-header-button">
                Sort
              </Button>
            </Badge>
          </Dropdown>
        </Col>
        <Col>
          <Dropdown dropdownRender={() => <FilterGroupByOverlay />} trigger={['click']}>
            <Badge count={groupByColumns.length} overflowCount={99}>
              <Button type="link" icon={<GroupOutlined />} className="pmt-header-button">
                Group
              </Button>
            </Badge>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};

export default FilterSection;
