import React, { useState, useEffect } from 'react';
import { Form, Input, Button, message, Row, Col } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import DefaultModal from './DefaultModal';
import './Modals.less';
import { inviteMember, updateMember } from '../services/member.service';
import { generateRandomPassword } from '../services/common.functions';

const TeamMemberFormModal = ({ isVisible, toggleModal, onSubmit, memberData }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const isEditMode = !!memberData;

  useEffect(() => {
    if (memberData) {
      const { f_name, l_name, email } = memberData.user
      form.setFieldsValue({ ...memberData, f_name, l_name, email });
    }
  }, [memberData, form]);
  const handleGeneratePassword = () => {
    const randomPassword = generateRandomPassword(); // Generate a random password
    form.setFieldsValue({ password: randomPassword, password_confirm: randomPassword });
  };

  const handleSubmit = (values) => {
    setLoading(true);

    const apiCall = isEditMode ? (data) => updateMember(memberData.id, data) : inviteMember;

    apiCall(values)
      .then((res) => {
        onSubmit && onSubmit(res.data);
        message.success(isEditMode ? 'Member details updated successfully!' : 'Your invite has been sent!');
        form.resetFields();
        toggleModal();
      })
      .catch((error) => {
        if (!error.response) {
          message.error('Network error or server is unreachable');
          return;
        }
        if (error.response.status === 422) {
          const validationErrors = error.response.data.errors;
          const errorFields = Object.keys(validationErrors).map((key) => ({
            name: key,
            errors: validationErrors[key],
          }));
          form.setFields(errorFields);
        } else {
          message.error(error.response.data.message || 'An unexpected error occurred');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const footerContent = [
    <Button className="modal-cancel-button" key="back" onClick={toggleModal} loading={loading}>
      Cancel
    </Button>,
    <Button key="submit" className="modal-save-button" type="primary" onClick={() => form.submit()} loading={loading}>
      {isEditMode ? 'Update Member' : 'Send Invite'}
    </Button>,
  ];

  return (
    <DefaultModal
      isVisible={isVisible}
      toggleModal={toggleModal}
      title={isEditMode ? 'Edit Team Member' : 'Add Team Member'}
      icon="user-add"
      footerContent={footerContent}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit} className="main-modal-form add-team-member-form">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="f_name"
              label="First Name"
              rules={[{ required: true, message: 'Enter First Name' }]}
            >
              <Input placeholder="Enter first name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="l_name"
              label="Last Name"
              rules={[{ required: true, message: 'Enter Last Name' }]}
            >
              <Input placeholder="Enter last name" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="job_title"
          label="Job Title"
          rules={[{ required: true, message: 'Enter job title' }]}
        >
          <Input placeholder="Enter job title" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, type: 'email', message: 'Enter a valid email address' }]}
        >
          <Input placeholder="Enter email" />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: !isEditMode, message: 'Enter password' },
                { min: 8, message: 'Password must be at least 8 characters' },
              ]}
            >
              <Input.Password
                placeholder={isEditMode ? 'Enter new password (optional)' : 'Enter password'}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="password_confirm"
              label="Confirm Password"
              dependencies={['password']}
              rules={[
                { required: !isEditMode, message: 'Confirm your password' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Passwords do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={isEditMode ? 'Confirm new password (optional)' : 'Confirm password'}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
          </Col>
        </Row>
        {!isEditMode && (
          <Button type="link" onClick={handleGeneratePassword}>
            Generate Random Password
          </Button>
        )}
      </Form>
    </DefaultModal>
  );
};

export default TeamMemberFormModal;
