import React from 'react';

const colors = [
  '#fff7ed', // Orange
  '#fffbeb', // Amber
  '#fefce8', // Yellow
  '#f7fee7', // Lime
  '#f0fdf4', // Green
  '#ecfdf5', // Emerald
  '#f0fdfa', // Teal
  '#ecfeff', // Cyan
  '#f0f9ff', // Sky
  '#eff6ff', // Blue
  '#eef2ff', // Indigo
  '#f5f3ff', // Violet
  '#faf5ff', // Purple
  '#fdf4ff', // Fuchsia
  '#fdf2f8', // Pink
  '#fff1f2', // Rose
];






// Hash function to generate a hash from a string
const hashString = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

// Function to select color based on hash
const getColorFromHash = (hash) => {
  const index = Math.abs(hash) % colors.length;
  return colors[index];
};

const DashboardProjectColors = ({ projectName }) => {
  const hash = hashString(projectName);
  const backgroundColor = getColorFromHash(hash);

  const style = {
    backgroundColor,
    padding: '20px',
    borderRadius: '8px',
    color: '#333', // Adjust text color if needed
    textAlign: 'center',
  };

  return (
    <div style={style}>
      <p>{projectName}</p>
    </div>
  );
};

export default DashboardProjectColors;
