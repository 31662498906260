import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import RangeSelector from '../RangeSelector';
import ChartFilter from './ChartFilter';
import { formatXAxis, getTickValues } from '../XAxisFormatter';
import { teamDashboardService } from '../../../services/dashboard.service';

const DashboardChart = () => {
  const [selectedRange, setSelectedRange] = useState(7);
  const [data, setData] = useState([]);
  const [selectedValues, setSelectedValues] = useState(['tasks', 'activeClients', 'projects', 'hoursAllocated', 'hoursWorked']);
  const ticks = getTickValues(data, selectedRange)

  useEffect(() => {
    const timer = setTimeout(() => {
      teamDashboardService.fetchChartDate(selectedRange).then((response) => {
        setData(response.data);
      }).catch(error => {
        console.error("Fetch Dashboad Data Failed", error);
      })
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [selectedRange]);
  
  const lineLavelFormatter = (value, name) => {
    let title;
    switch (name) {
      case 'tasks':
        title = 'Tasks';
        break;
      case 'clients':
        title = 'Clients';
        break;
      case 'projects':
        title = 'Projects';
        break;
      case 'hoursAllocated':
        title = 'Hours Allocated';
        break;
      case 'hoursWorked':
        title = 'Hours Worked';
        break;
      default:
        title = name;
    }
    return [`${value}`, title];
  };

  return (
    <div>
      <div className="db-chart-topbar">

        <div className="db-chart-topbar-title">
          Performance Chart

        </div>

        <RangeSelector
          selectedRange={selectedRange}
          setSelectedRange={setSelectedRange}
        />
      </div>
      <div className="db-chart">
        <ResponsiveContainer width="100%" height={360}>

          <LineChart data={data}>
            <XAxis dataKey="d" tickFormatter={formatXAxis} ticks={ticks} />
            <YAxis />
            <CartesianGrid stroke="#ddd" strokeOpacity={0.5} strokeDasharray="7 3" />
            <Tooltip formatter={lineLavelFormatter} />

            {selectedValues.includes('tasks') && (
              <Line
                type="monotone"
                label="Tasks"
                dataKey="tasks"
                stroke="#F3B743"
                strokeWidth={1.25}
                dot={false}
              />
            )}
            {selectedValues.includes('activeClients') && (
              <Line
                type="monotone"
                label="Clients"
                dataKey="clients"
                stroke="#4DA957"
                strokeWidth={1.25}
                dot={false}
              />
            )}
            {selectedValues.includes('projects') && (
              <Line
                type="monotone"
                dataKey="projects"
                stroke="#363187"
                strokeWidth={1.25}
                dot={false}
              />
            )}
            {selectedValues.includes('hoursAllocated') && (
              <Line
                type="monotone"
                dataKey="hoursAllocated"
                stroke="#b32d2d"
                strokeWidth={1.25}
                dot={false}
              />
            )}
            {selectedValues.includes('hoursWorked') && (
              <Line
                type="monotone"
                dataKey="hoursWorked"
                stroke="#42ABDF"
                strokeWidth={1.25}
                dot={false}
              />
            )}
          </LineChart>

        </ResponsiveContainer>

        <div className="db-chart-filters-container">
          <ChartFilter selectedValues={selectedValues} setSelectedValues={setSelectedValues} />
        </div>
      </div>
    </div>
  );
};

export default DashboardChart;
