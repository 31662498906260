import React, { useState, useEffect } from 'react';

import { fetchClients } from '../../services/client.service';
import SkeletonTable from '../../Components/SkeletonTable';
import { Pagination } from 'antd';
import './ClientsPages.less';
import ClientInfoColumn from './ClientsTableColumns/ClientInfoColumn';
import ContactInfoColumn from './ClientsTableColumns/ContactInfoColumn';
import HoursAndCurrencyColumn from './ClientsTableColumns/HoursAndCurrencyColumn';
import ClientStatusColumn from './ClientsTableColumns/ClientStatusColumn';
import AccountManagerColumn from './ClientsTableColumns/AccountManagerColumn';
import NotesColumn from './ClientsTableColumns/NotesColumn';
import ClientsTableSort from './ClientsTableColumns/ClientsTableSort';
import { fetchMembers } from '../../services/member.service';
import { ClientsProvider, useClientsContext } from '../../Context/ClientContext';
import RowOptions from './ClientsTableColumns/RowOptions';
import { Navigate } from 'react-router-dom';
import { appSubDir } from '../../config';
import { useSelector } from 'react-redux';
import { hasRole } from '../../services/auth.service';

const Loading = <div style={{ padding: '24px 0' }}>
  <div style={{ overflowX: 'auto' }}>
    <SkeletonTable rows={5} columns={5} />
  </div>
</div>
const MainComponent = () => {
  const user = useSelector((state) => state.auth.user);
  const isClient = hasRole(user, 'client')
  const { rowsPerPage, setRowsPerPage, sortByColumns, setTeamMembers } = useClientsContext();
  const [isMobile, setIsMobile] = useState(false);
  const [clients, setClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  function loadData() {
    setLoading(true);
    fetchClients(currentPage, rowsPerPage, sortByColumns).then((res) => {
      setClients(res.data.data);
      setTotalRows(res.data.total);
    }).finally(() => {
      setLoading(false);
    });
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      fetchMembers().then(setTeamMembers).catch((err) => {
        console.log("Fetching Team Members Failed", err)
      })
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [])
  // Fetch clients from the backend
  useEffect(() => {
    if (isClient) {
      return () => { }

    }
    const timer = setTimeout(() => {
      loadData()
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [currentPage, rowsPerPage, sortByColumns]);

  const handleChangePage = (pagination) => {
    setCurrentPage(pagination.current);
    setRowsPerPage(pagination.pageSize);
  };

  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);
  if (isClient) {
    return <Navigate to={appSubDir + "not-found"} />
  }





  const columns = [
    { Header: 'Client', accessor: 'clientInfo', render: ClientInfoColumn.render },
    { Header: 'Info', accessor: 'contactInfo', render: ContactInfoColumn.render },
    { Header: 'Hours', accessor: 'hoursAndCurrency', render: HoursAndCurrencyColumn.render },
    { Header: 'Status', accessor: 'clientStatus', render: ClientStatusColumn.render },
    { Header: 'Manager', accessor: 'accountManager', render: AccountManagerColumn.render },
    { Header: 'Notes', accessor: 'notes', render: NotesColumn.render },
  ];

  const onRowUpdate = (newRow) => {
    const index = clients.findIndex((item) => (item.id === newRow.id))
    if (index > -1) {
      clients[index] = newRow
      setClients([...clients])
    }
  }
  const TableRow = ({ row }) => (
    <tr className="ct-table-row">
      {columns.map((column, colIndex) => (
        <td key={column.accessor} className="ct-cell">
          {column.render(null, row, onRowUpdate)}
          {colIndex === columns.length - 1 && <div className="row-options-container"><RowOptions row={row} /></div>} {/* Add RowOptions at the end of the last column */}
        </td>
      ))}
    </tr>
  );

  return (
    <div className="ct-clients-container">
      <p className="main-page-title">
        <span className="main-page-title-icon">
          <i className="fi fi-rr-building"></i>
        </span>
        All Clients
      </p>
      {loading ? Loading : <>
        <ClientsTableSort onAddClientSuccess={loadData} /> {/* Pass props here */}
        <div className="main-table-main-container">
          <div className="main-table-container">
            <table className="main-table">
              <thead className="main-table-head">
                <tr>
                  {columns.map((column) => (
                    <th key={column.accessor} className="main-header-cell">
                      {column.Header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {clients.map((row) => (
                  <TableRow key={row.id} row={row} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          current={currentPage}
          pageSize={rowsPerPage}
          total={totalRows}
          onChange={setCurrentPage}
          className="ct-pagination"
        />
      </>}
    </div>
  );
};
const Clients = () => {
  return (
    <ClientsProvider>
      <MainComponent />
    </ClientsProvider>
  )
}

export default Clients;
