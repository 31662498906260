// src/utils/clientActions.js

export const addClient = (clientsData, newClient) => {
  return [...clientsData, newClient];
};

export const removeClient = (clientsData, clientId) => {
  return clientsData.filter(client => client.id !== clientId);
};

export const updateClient = (clientsData, clientId, updatedClient) => {
  return clientsData.map(client =>
    client.id === clientId ? { ...client, ...updatedClient } : client
  );
};
