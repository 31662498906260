// NotificationSettingsModal.js

import React from 'react';
import { Form, Switch, Button } from 'antd';
import DefaultModal from './DefaultModal';
import './Modals.less';

const NotificationSettingsModal = ({ isVisible, toggleModal }) => {
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    console.log('Notification settings:', values);
    // Logic to save notification settings
    toggleModal();
  };

  const footerContent = [
    <Button className="modal-cancel-button" key="back" onClick={toggleModal}>
      Cancel
    </Button>,
    <Button key="submit" className="modal-save-button" type="primary" onClick={() => form.submit()}>
      Save
    </Button>,
  ];

  return (
    <DefaultModal
      isVisible={isVisible}
      toggleModal={toggleModal}
      title="Notification Settings"
      icon="envelope-dot"
      footerContent={footerContent}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit} className="main-modal-form notification-settings-form">
        <p>Manage your email notification settings. You can choose which types of notifications you want to receive via email.</p>

        <Form.Item name="taskCreated" valuePropName="checked" initialValue={false}>
          <div className="notification-option">
            <Switch />
            <div className="notification-text">
              <div className="notification-title">Task Created</div>
              <div className="notification-description">Receive notifications when a new task is created.</div>
            </div>
          </div>
        </Form.Item>

        <Form.Item name="taskCompleted" valuePropName="checked" initialValue={false}>
          <div className="notification-option">
            <Switch />
            <div className="notification-text">
              <div className="notification-title">Task Completed</div>
              <div className="notification-description">Get notified when a task is completed successfully.</div>
            </div>
          </div>
        </Form.Item>

        <Form.Item name="projectCreated" valuePropName="checked" initialValue={false}>
          <div className="notification-option">
            <Switch />
            <div className="notification-text">
              <div className="notification-title">Project Created</div>
              <div className="notification-description">Receive updates when a new project is initiated.</div>
            </div>
          </div>
        </Form.Item>

        <Form.Item name="projectCompleted" valuePropName="checked" initialValue={false}>
          <div className="notification-option">
            <Switch />
            <div className="notification-text">
              <div className="notification-title">Project Completed</div>
              <div className="notification-description">Be informed when a project reaches completion.</div>
            </div>
          </div>
        </Form.Item>

        <Form.Item name="paymentMade" valuePropName="checked" initialValue={false}>
          <div className="notification-option">
            <Switch />
            <div className="notification-text">
              <div className="notification-title">Payment Made</div>
              <div className="notification-description">Get alerts when a payment is made.</div>
            </div>
          </div>
        </Form.Item>

        <Form.Item name="hoursLow" valuePropName="checked" initialValue={false}>
          <div className="notification-option">
            <Switch />
            <div className="notification-text">
              <div className="notification-title">Hours Low</div>
              <div className="notification-description">Receive notifications when available hours are running low.</div>
            </div>
          </div>
        </Form.Item>

        <Form.Item name="hoursFinished" valuePropName="checked" initialValue={false}>
          <div className="notification-option">
            <Switch />
            <div className="notification-text">
              <div className="notification-title">Hours Finished</div>
              <div className="notification-description">Be alerted when the allocated hours are finished.</div>
            </div>
          </div>
        </Form.Item>
      </Form>
    </DefaultModal>
  );
};

export default NotificationSettingsModal;
