import moment from "moment/moment";

export function secondsToHHMM(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    // Ensure two-digit format
    const hoursString = hours.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');

    return `${hoursString}:${minutesString}`;
}
export function hoursToString(decimalHours) {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);

    // Pad the minutes with leading zeros if necessary
    const paddedMinutes = minutes > 0 ? ":" + (minutes.toString().padStart(2, '0')) : "";

    // Pad the hours with leading zeros if necessary
    const paddedHours = hours.toString().padStart(2, '0')

    return `${paddedHours}${paddedMinutes}`;
}

export function generateRandomPassword(length = 12) {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
    let password = "";
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
    }
    return password;
}

export function parseFloatFromStr(str) {
    const floatData = parseFloat(str)
    return floatData > 0 ? floatData.toFixed(2) : 0
}
export const dateFormat = {
    short: (dateStr) => {
        return moment(dateStr).format('MMM DD')
    },
    long: (dateStr) => {
        return moment(dateStr).format('DD MMM YY')
    }
}
export const dateTimeFormat = {
    short: (timeStr) => {
        return moment(timeStr).format('MMM DD, hh:mm A')
    },
    long: (timeStr) => {
        return moment(timeStr).format('DD MMM YYYY, hh:mm A')
    }
}
export function isPastDate(inputDate) {
    // Create a moment object for the input date
    const dateToCheck = moment(inputDate);

    // Compare using moment's 'isBefore' function to check if the date is before today
    return dateToCheck.isBefore(moment(), 'day');
}
export function isPastDateTime(inputDateTime) {
    // Create a moment object for the input date and time
    const dateTimeToCheck = moment(inputDateTime);

    // Compare using moment's 'isBefore' function to check if the datetime is before the current datetime
    return dateTimeToCheck.isBefore(moment());
}

export const enhanceElementAppearance = (selector, options = {}) => {
    const { focusInput = false, glow = true, startAfter = 10, stopAfter = 2000 } = options;

    setTimeout(() => {
        const element = document.querySelector(selector);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            if (glow) {
                element.classList.add('glow-effect');
                setTimeout(() => {
                    element.classList.remove('glow-effect');
                }, stopAfter);
            }
            if (focusInput) {
                element.querySelector('input')?.focus();
            }
        }
    }, startAfter);
};