import React, { useEffect } from 'react';
import { Table, Dropdown, Menu, Button, Pagination } from 'antd';
import '../../custom.less'; // Ensure custom.less is imported to apply styles
import getStatusTag from '../../Components/StatusTags'; // Import the new getStatusTag function
import './WorkPages.less';
import { TasksProvider, useTasksContext } from '../../Context/TasksContext';
import { fetchTasks } from '../../services/task.service';
import SkeletonTable from '../../Components/SkeletonTable';
import TasksTableSort from './TasksTableSort';
import { Link } from 'react-router-dom';
import { appSubDir } from '../../config';
import AllottedAndTrackedColumn from './TasksTableColumns/AllottedAndTrackedColumn';
import TimelineColumn from './TasksTableColumns/TimelineColumn';
import { useSelector } from 'react-redux';
import { getRoleName, hasRole } from '../../services/auth.service';
import AssigneeColumn from './TasksTableColumns/AssigneeColumn';
import RowOptionColumn from './TasksTableColumns/RowOptionColumn';
import TasksColumn from './TasksTableColumns/TasksColumn';


const Loading = <div style={{ padding: '24px 0' }}>
  <div style={{ overflowX: 'auto' }}>
    <SkeletonTable rows={5} columns={5} />
  </div>
</div>
const MainComponent = () => {
  const user = useSelector((state) => state.auth.user);
  const isCLient = hasRole(user, 'client')
  const {
    currentPage, setCurrentPage,
    tasks, setTasks,
    loading, setLoading,
    totalRows, setTotalRows,
    sortByColumns,
    rowsPerPage, setRowsPerPage
  } = useTasksContext()


  // Page Data Loader
  function loadData() {
    setLoading(true);
    fetchTasks(currentPage, rowsPerPage, sortByColumns, getRoleName(user)).then((res) => {
      setTasks(res.data.data);
      setTotalRows(res.data.total);
    }).finally(() => {
      setLoading(false);
    });
  }
  // Fetch clients from the backend
  useEffect(() => {
    const timer = setTimeout(() => {
      loadData()
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, [currentPage, rowsPerPage, sortByColumns]);

  return (
    <div className="ct-projects-container">
      <p className="ct-page-title">
        <span className="ct-page-title-icon">
          <i className="fi fi-rr-note"></i>
        </span>
        All Tasks
      </p>

      {loading ? Loading : <>
        <TasksTableSort rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
        <div className="main-table-main-container">
            <Table
              columns={[
                TasksColumn,
                {
                  title: isCLient ? "Project" : 'Client',
                  render: (text, record) => (
                    <div className='client-col'>
                      {!isCLient && <Link to={appSubDir + `clients/${record.project.client.id}/profile`}>{record.project.client.company_name}</Link>}
                      {record.sub_client ? <div className="lite-text"><b>{isCLient ? "Client" : "Sub-Client"} : </b> {record.sub_client}</div> : null}
                      <div className="lite-text"> <b>Project : </b>
                        {isCLient ? record.project.name : <Link to={appSubDir + `projects/${record.project.id}/table`}>{record.project.name}</Link>}
                      </div>
                    </div>
                  ),
                  responsive: ['lg'],
                },
                AllottedAndTrackedColumn,
                AssigneeColumn,
                TimelineColumn,
                {
                  title: 'Status',
                  dataIndex: 'status',
                  key: 'status',
                  render: (status) => getStatusTag(status, 'no-border'),
                },
                RowOptionColumn
              ]}
              dataSource={tasks}
              pagination={false}
              rowClassName={(record, index) =>
                index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
              }
            />
        </div>
      </>}

      <Pagination
        current={currentPage}
        pageSize={rowsPerPage}
        total={totalRows}
        onChange={setCurrentPage}
        className="pt-pagination"
      />
    </div>
  );
};

const Tasks = () => {
  return (
    <TasksProvider>
      <MainComponent />
    </TasksProvider>
  )
}
export default Tasks;
