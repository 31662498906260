import axios from "axios";
import { apiBaseUrl } from "../config";

export function getMembers() {
    const localMembers = window.localStorage.getItem('members')
    if (localMembers) {
        return JSON.parse(localMembers)
    } else {
        return []
    }
}
export function formatMember(item) {
    if (item.user) {
        item.name = item.user.f_name + " " + item.user.l_name
    }
    item.jobTitle = item.job_title
    item.assignedTasks = item.total_tasks
    item.inProgressTasks = item.tasks_in_progress
    item.completedTasks = item.tasks_completed
    item.overdueTasks = item.tasks_overdue
}
export function fetchTeamMembers(page = 0, rowsPerPage = 20, sortByColumns = []) {
    const query = new URLSearchParams("")
    if (page) {
        query.set('page', page.toString());
    }
    if (rowsPerPage) {
        query.set('per_page', rowsPerPage.toString());
    }
    if (sortByColumns && sortByColumns.length > 0) {
        sortByColumns.forEach(element => {
            if (element.key && element.order)
                query.set(element.key, element.order);
        });
    }
    let url = [apiBaseUrl + "members", query.toString()].join("/?")
    return axios.get(url, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function fetchAdmins(page = 0, rowsPerPage = 20) {
    const query = new URLSearchParams("")
    if (page) {
        query.set('page', page.toString());
    }
    if (rowsPerPage) {
        query.set('per_page', rowsPerPage.toString());
    }
    let url = [apiBaseUrl + "admins", query.toString()].join("/?")
    return axios.get(url, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function fetchMembers() {
    const token = window.localStorage.getItem('token')
    if (!token) {
        console.error('fetchMembers error. token missing')
        return new Promise((resolve) => { resolve() })
    }
    return axios.get(apiBaseUrl + `members/list`, { headers: { "Authorization": "Bearer " + token } }).then(res => {
        const members = res.data.map((item) => {
            const name = item.f_name + " " + item.l_name
            const id = item.id
            return { id, name }
        })
        window.localStorage.setItem('members', JSON.stringify(members))
        return members
    })
}
export function memberAutocomplete(value) {
    return axios.get(apiBaseUrl + `members/autocomplete/${value}`, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function inviteMember(dataToPost) {
    const token = window.localStorage.getItem('token')
    if (!token) {
        console.error('inviteMember error. Token missing');
        return Promise.reject(new Error('Authentication token is missing'));
    }
    return axios.post(apiBaseUrl + `members`, dataToPost, { headers: { "Authorization": "Bearer " + token } })
}
export function updateMember(id,dataToPost) {
    const token = window.localStorage.getItem('token')
    if (!token) {
        console.error('inviteMember error. Token missing');
        return Promise.reject(new Error('Authentication token is missing'));
    }
    return axios.put(apiBaseUrl + `members/${id}`, dataToPost, { headers: { "Authorization": "Bearer " + token } })
}
export function addAdmin(dataToPost) {
    const token = window.localStorage.getItem('token')
    if (!token) {
        console.error('inviteMember error. Token missing');
        return Promise.reject(new Error('Authentication token is missing'));
    }
    return axios.post(apiBaseUrl + `admins`, dataToPost, { headers: { "Authorization": "Bearer " + token } })
}
export function updateAdmin(id,dataToPost) {
    const token = window.localStorage.getItem('token')
    if (!token) {
        console.error('inviteMember error. Token missing');
        return Promise.reject(new Error('Authentication token is missing'));
    }
    return axios.put(apiBaseUrl + `admins/${id}`, dataToPost, { headers: { "Authorization": "Bearer " + token } })
}
