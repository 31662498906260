import React, { createContext, useState, useContext } from 'react';
const AutomationsContext = createContext();

export const AutomationsProvider = ({ children }) => {
  const [sortByColumns, setSortByColumns] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAutomation, setSelectedAutomation] = useState(null);
  const [automations, setAutomations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);


  return (
    <AutomationsContext.Provider
      value={{
        currentPage, setCurrentPage,
        selectedAutomation, setSelectedAutomation,
        automations, setAutomations,
        loading, setLoading,
        totalRows, setTotalRows,
        sortByColumns, setSortByColumns,
        rowsPerPage, setRowsPerPage
      }}
    >
      {children}
    </AutomationsContext.Provider>
  );
};

export const useAutomationsContext = () => {
  return useContext(AutomationsContext)
}
