import axios from "axios";
import { apiBaseUrl, storageBaseUrl } from "../config";

export function getLogoUrl(fileName) {
    return storageBaseUrl + "logos/" + fileName + ".webp"
}
export function getBannerUrl(fileName) {
    return storageBaseUrl + "banners/" + fileName + ".webp"
}
export function fetchCompanyProfile() {
    return axios.get(apiBaseUrl + `company/profile`, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function uploadLogo(formData) {
    return axios.post(apiBaseUrl + `company/logo`, formData, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function uploadBanner(formData) {
    return axios.post(apiBaseUrl + `company/banner`, formData, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
export function updateCompanyProfile(values) {
    return axios.put(apiBaseUrl + `company/profile`, values, { headers: { "Authorization": "Bearer " + window.localStorage.getItem('token') } })
}
