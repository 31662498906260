import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RegisterPage from '../Pages/AuthenticationPages/RegisterPage';
import LoginPage from '../Pages/AuthenticationPages/LoginPage';
import ForgotPasswordPage from '../Pages/AuthenticationPages/ForgotPasswordPage';
import NotFoundPage from '../Pages/MiscPages/NotFoundPage';

const PublicRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default PublicRoutes;
