import React, { useEffect, useState } from 'react';
import { Carousel, Dropdown, Menu, Pagination } from 'antd';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import SingleAvatar2 from '../../Components/SingleAvatar2'; // Ensure the correct path to your avatar component
import teamMembers from '../../Data/TeamSampleData'; // Ensure the correct path to your data file
import './Dashboard.less'; // Import the CSS file
import { EllipsisOutlined } from '@ant-design/icons'; // Import the Ellipsis icon from Ant Design
import { fetchTeamMembers } from '../../services/member.service';

const TeamOverviewTable = ({ member }) => {
  return (
    <div className="to-table-container">
      <span className="to-table-tasks-title"> Tasks: </span>
      <div className="to-grid-container">
        <div className="to-grid-item not-started">
          <span className="to-task-title">NOT STARTED</span>
          <span className="to-task-count">{member.assignedTasks}</span>
        </div>
        <div className="to-grid-item in-progress">
          <span className="to-task-title">IN PROGRESS</span>
          <span className="to-task-count">{member.inProgressTasks}</span>
        </div>
        <div className="to-grid-item completed">
          <span className="to-task-title">COMPLETED</span>
          <span className="to-task-count">{member.completedTasks}</span>
        </div>
        <div className="to-grid-item overdue">
          <span className="to-task-title">OVERDUE</span>
          <span className="to-task-count">{member.overdueTasks}</span>
        </div>
      </div>
    </div>
  );
};

const DashboardTeamMembers = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(12); // Default is 3 rows, 12 members
  const [items, setItems] = useState([]); // Initialize the projects state
  const [loading, setLoading] = useState(true)
  const pageSize = rowsPerPage;

  function loadData() {
    setLoading(true)
    fetchTeamMembers(0, 12, [{ key: 'overdue', order: "desc" }]).then((response) => {
      const newItems = response.data.data.map((item) => ({
        id: item.id,
        name: `${item.user.f_name} ${item.user.l_name}`,
        jobTitle: item.job_title,
        assignedTasks: item.total_tasks,
        inProgressTasks: item.tasks_in_progress,
        completedTasks: item.tasks_completed,
        overdueTasks: item.tasks_overdue
      }))
      setItems(newItems);
    }).catch(error => {
      console.error("Fetch Dashboad Projects Failed", error);
    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      loadData()
    }, 1)
    return () => {
      clearTimeout(timer)
    }
  }, []);
  if (items.length < 1) {
    return null
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const currentMembers = items.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const chunkedMembers = [];
  for (let i = 0; i < currentMembers.length; i += 4) {
    chunkedMembers.push(currentMembers.slice(i, i + 4));
  }

  return (
    <div className="db-team-container">
      <Carousel dots arrows>
        {chunkedMembers.map((chunk, index) => (
          <div className="db-project-carousel-container" key={index}>
            <div className="db-projects-title-row">
              <div className="db-carousel-title"> Team Members </div>
              <div className="db-view-projects-button" onClick={() => navigate('/team-overview')}>
                All Members
                <div className="db-view-projects-button-icon-container">
                  <i className="fi fi-rr-angle-small-right"></i>
                </div>
              </div>
            </div>
            <div className="db-carousel-background">
              {chunk.map((member) => (
                <div key={member.id} className="db-project-carousel-item">
                  <div className="team-card">
                    <div className="team-card-header">
                      <div className="team-card-name-cell">
                        <SingleAvatar2 name={member.name} size={64} fontSize={24} />
                        <div className="team-card-info">
                          <span className="to-name">{member.name}</span>
                          <span className="to-job-title">{member.jobTitle}</span>
                        </div>
                      </div>
                      <div className="team-card-options-cell">
                        <EllipsisOutlined className="team-card-settings" />
                      </div>
                    </div>
                    <div className="team-card-data">
                      <TeamOverviewTable member={member} /> {/* Render the table for each member */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Carousel>


    </div>
  );
};

export default DashboardTeamMembers;
