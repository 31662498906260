import React, { createContext, useState, useContext } from 'react';

const CompanyProfileContext = createContext();

export const CompanyProfileProvider = ({ children }) => {
  const [company, setCompany] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  return (
    <CompanyProfileContext.Provider
      value={{
        company, setCompany,
        isModalOpen, setIsModalOpen,
        loading, setLoading
      }}
    >
      {children}
    </CompanyProfileContext.Provider>
  );
};

export const useCompanyProfileContext = () => {
  return useContext(CompanyProfileContext)
}
