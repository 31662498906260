import React from 'react';
import { Popover } from 'antd';
import './AllottedAndTrackedColumn.less'

const AllottedAndTrackedColumn = {
  title: 'Hours',
  className: 'ct-allotted-and-tracked',
  render: (text, record) => {
    const hoursAllotted = parseInt(record.hours_allotted);
    const hoursTracked = parseInt(record.hours_tracked);
    const trackedPercentage = (hoursTracked / hoursAllotted) * 100;
    const isOverTracked = hoursTracked > hoursAllotted;
    const overTrackedHours = hoursTracked - hoursAllotted;
    const overTrackedPercentage = (overTrackedHours / hoursAllotted) * 100;
    const remainingPercentage = isOverTracked ? 0 : 100 - trackedPercentage;

    const hoursLegendContent = (
      <div className="db-legend">
        <div className="db-legend-row">
          <div className="db-legend-item">
            <span className="db-project-circle db-used-circle"></span> <p>Tracked</p>
          </div>
          {isOverTracked ? (
            <div className="db-legend-item">
              <span className="db-project-circle db-overused-circle"></span> <p>Overtracked</p>
            </div>
          ) : (
            <div className="db-legend-item">
              <span className="db-project-circle db-remaining-circle"></span> <p>Remaining</p>
            </div>
          )}
        </div>
        <div className="db-legend-row">
          <div className="db-legend-item">
            {hoursTracked}
            <span className="db-project-percentage"> ({trackedPercentage.toFixed(0)}%) </span>
          </div>
          {isOverTracked ? (
            <div className="db-legend-item">
              {overTrackedHours}
              <span className="db-project-percentage"> ({overTrackedPercentage.toFixed(0)}%) </span>
            </div>
          ) : (
            <div className="db-legend-item">
              {hoursAllotted - hoursTracked}
              <span className="db-project-percentage"> ({remainingPercentage.toFixed(0)}%) </span>
            </div>
          )}
        </div>
      </div>
    );

    return (
      <Popover content={hoursLegendContent} placement="bottom" overlayClassName="custom-popover">
        <div className="db-hours-bar-container tasks-progressbar">
          <div className="db-bar-info">
            <span className="db-bar-title">{hoursAllotted} hrs</span>
          </div>
          <div className="db-progress-bar-container">
            <div
              className="db-progress-bar db-progress-bar-used"
              style={{ width: `${Math.min(trackedPercentage, 100)}%` }}
            ></div>
            {isOverTracked && (
              <div
                className="db-progress-bar db-progress-bar-overtracked"
                style={{ width: `${overTrackedPercentage}%` }}
              ></div>
            )}
            {!isOverTracked && (
              <div
                className="db-progress-bar db-progress-bar-remaining"
                style={{ width: `${remainingPercentage}%` }}
              ></div>
            )}
          </div>
        </div>
      </Popover>
    );
  },
};

export default AllottedAndTrackedColumn;
